import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Radio, Select, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
// import { useLocation } from 'react-router-dom';
import ImageVideoUploader from './ImageVideoUploader';
// let index = 0;



const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    // wrapperCol: {
    //   xs: {
    //     span: 24,
    //   },
    //   sm: {
    //     span: 16,
    //   },
    // },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};


function Variant({ open, setOpen, details, edit, onDataFromChild, setChange, productID }) {


    const [form] = Form.useForm();
    // const { state } = useLocation();
    // console.log(edit,'edit');
    // console.log(details,'details edit');


    const initialPrice = [{
        currency: null,
        retailPrice: null,
        sellingPrice: null,
        specialPrice: null,
        // Add more selects as needed
    }]
    const initialAttribute = [{
        name: null,
        value: null,
        description: null,
        // Add more selects as needed
    }]
    const initialFeaturesDetails = [{
        key: null,
        value: null,
        // Add more selects as needed
    }]
    const initialFeatures = [{
        tittle: null,
        details: initialFeaturesDetails,
        highlights: [""],
        // Add more selects as needed
    }]
    // const initialStock = {
    //     addedStock: null,
    //     avaliableStock: 0,
    //     totalStock: 0,
    //     // Add more selects as needed
    // }

    const countryCurrency = [
        {
            country: "United Arab Emirates",
            currency: "AED"
        },
        {
            country: "United States",
            currency: "USD"
        },
        {
            country: "INDIA",
            currency: "INR"
        }
    ]

    const [selectPriceValues, setSelectPriceValues] = useState([])
    const [attributeValues, setAttributeValues] = useState([])
    const [featuresValues, setFeaturesValues] = useState([])
    // const [quantityRule, setQuantityRule] = useState()
    // const [color, setColor] = useState()
    // const [stocks, setStocks] = useState()
    // const [addStocks, setAddStocks] = useState()

    // const [dialogOpen, setDialogOpen] = useState(false);
    const [images, setImages] = useState([]);
    const [frontImages, setFrontImages] = useState([]);
    const [backImages, setBackImages] = useState([]);
    const [loader, setLoader] = useState(false)

    // console.log(images, "imagesss create variants");
    // console.log(details, 'details');

    useEffect(() => {
        // console.log('in use effect b');
        form.setFieldsValue(details);
        setSelectPriceValues(details ? details?.pricing.prices : initialPrice)
        setAttributeValues(details ? details?.attributes : initialAttribute)
        setFeaturesValues(details ? details?.features : initialFeatures)
        setImages(details ? details?.images : [])
        setFrontImages(details ? [details?.customImages?.frontSide] : [])
        setBackImages(details ? [details?.customImages?.backSide] : [])
        // setStocks(state ? state?.stocks : initialStock)
    }, [details, open])

    useEffect(() => {
        if (!open) {

            handleCancelUploadModal()
        }
    }, [open])

    const handleCancelUploadModal = () => {
        setOpen(false);
        // fileInputRef.current.value = null
        form.resetFields()
        setSelectPriceValues(initialPrice)
        setAttributeValues(initialAttribute)
        setFeaturesValues(initialFeatures)
        setImages([])
        setFrontImages([])
        setBackImages([])
        setLoader(false)
        if (setChange) {
            
            setChange(Date.now())
        }

    };

    /* --------------------------------- prices --------------------------------- */
    const handleChangePrices = async (name, index, value) => {
        // console.log(index, 'index');
        setSelectPriceValues(prevValues => {
            let updatedValues = [...prevValues];

            // console.log(updatedValues, '777777');

            // Reset certain values if materialcolor is being changed
            if (name === "currency") {
                // updatedValues = updatedValues.map((item,index) => ({
                //     materialFavoriteColor: null,
                //     color: null,
                //     textColor: null,
                // }));
                updatedValues[index] = {
                    ...updatedValues[index],
                    retailPrice: null,
                    sellingPrice: null,
                    specialPrice: null,
                };

            }

            // Update the specified property in the array at the given index
            updatedValues[index] = {
                ...updatedValues[index],
                [name]: value,
            };

            return updatedValues;
        })
    };

    // console.log(selectButtonValues, '88888');
    // console.log(selectMaterialValues, '88888');

    // console.log(filterMaterialColor,'pppp');
    // console.log(filterMaterial, ';;;;;;;');


    let addFormFields = () => {
        setSelectPriceValues([...selectPriceValues, {
            currency: null,
            retailPrice: null,
            sellingPrice: null,
            specialPrice: null,
        }]);
    };

    let removeFormFields = (i) => {
        let newFormValues = [...selectPriceValues];
        newFormValues.splice(i, 1);
        setSelectPriceValues(newFormValues);
    };

    /* ------------------------------- Attributes ------------------------------- */
    const handleChangeAttribute = async (name, index, value) => {
        // console.log(index, 'index');
        setAttributeValues(prevValues => {
            let updatedValues = [...prevValues];

            // console.log(updatedValues, '777777');

            // Reset certain values if materialcolor is being changed
            if (name === "currency") {
                // updatedValues = updatedValues.map((item,index) => ({
                //     materialFavoriteColor: null,
                //     color: null,
                //     textColor: null,
                // }));
                updatedValues[index] = {
                    ...updatedValues[index],
                    retailPrice: null,
                    sellingPrice: null,
                    specialPrice: null,
                };

            }

            // Update the specified property in the array at the given index
            updatedValues[index] = {
                ...updatedValues[index],
                [name]: value,
            };

            return updatedValues;
        })
    };

    let addAttributeFields = () => {
        setAttributeValues([...attributeValues, {
            name: null,
            value: null,
            description: null,
        }]);
    };

    let removeAttributeFields = (i) => {
        let newFormValues = [...attributeValues];
        newFormValues.splice(i, 1);
        setAttributeValues(newFormValues);
    };

    /* ------------------------------- Features ------------------------------- */
    const handleChangeFeatures = async (name, index, childIndex, value) => {
        // console.log(index, 'index');
        setFeaturesValues(prevValues => {
            let updatedValues = [...prevValues];

            // console.log(updatedValues, '777777');
            if (name === 'key' || name === 'value') {
                updatedValues[index].details[childIndex][name] = value;
            } else if (name === 'highlights') {
                updatedValues[index].highlights[childIndex] = value;
            } else {

                updatedValues[index] = {
                    ...updatedValues[index],
                    [name]: value,
                };
            }

            return updatedValues;
        })
    };

    let addFeaturesFields = () => {
        setFeaturesValues([...featuresValues, {
            tittle: null,
            details: [{ key: "", value: "" }],
            highlights: [""],
        }]);
    };

    let removeFeatureFields = (i) => {
        let newFormValues = [...featuresValues];
        newFormValues.splice(i, 1);
        setFeaturesValues(newFormValues);
    };


    const addFeaturesDetailsFields = (featureIndex) => {
        setFeaturesValues(prevValues => {
            const updatedValues = [...prevValues];
            updatedValues[featureIndex].details.push({ key: "", value: "" });
            return updatedValues;
        });
    };

    const removeFeaturesDetailsFields = (featureIndex, attributeIndex) => {
        setFeaturesValues(prevValues => {
            const updatedValues = [...prevValues];
            updatedValues[featureIndex].details.splice(attributeIndex, 1);
            return updatedValues;
        });
    };

    const addFeaturesHighlightFields = (featureIndex) => {
        setFeaturesValues(prevValues => {
            const updatedValues = [...prevValues];
            updatedValues[featureIndex].highlights.push("");
            return updatedValues;
        });
    };

    const removeFeaturesHighlightFields = (featureIndex, highlightIndex) => {
        setFeaturesValues(prevValues => {
            const updatedValues = [...prevValues];
            updatedValues[featureIndex].highlights.splice(highlightIndex, 1);
            return updatedValues;
        });
    };

    // const handleChangeQuantity_rule = (e) => {
    //     const { name, value } = e.target
    //     setQuantityRule({ ...quantityRule, [name]: value })
    // }
    // const handleChangeColor = (e) => {
    //     const { name, value } = e.target
    //     setColor({ ...color, [name]: value })
    // }
    // const handleChangeStock = () => {

    //     const details = {
    //         stock: addStocks,
    //         date: Date.now()
    //     }
    //     // console.log(value);
    //     const data = {
    //         addedStock: details,
    //         avaliableStock: stocks?.avaliableStock + Number(addStocks),
    //         totalStock: stocks?.totalStock + Number(addStocks)
    //     }
    //     setStocks({ ...data })
    // }

    const onFinish = async (values) => {
        // console.log(values, 'eeeeee');
        values.pricing.prices = selectPriceValues
        values.attributes = attributeValues
        values.features = featuresValues
        values.images = images
        values.frontImage = frontImages
        values.backImage = backImages
        // console.log(values, 'final');
        setLoader(true)
        onDataFromChild(values)

        // form.resetFields()
        // setSelectPriceValues(initialPrice)
        // setAttributeValues(initialAttribute)
        // setFeaturesValues(initialFeatures)
        // setImages([])
    }

    const totalStock = details?.stocks?.totalStock
    const avaliableStock = details?.stocks?.avaliableStock

    // console.log(frontImages,'frontImages');
    // console.log(backImages,'backImages');
    return (
        <div>
            <Modal
                open={open}
                onCancel={handleCancelUploadModal}
                closable={false}
                maskClosable={false}
                destroyOnClose={false}
                footer={false}
                width={700}
            >
                <div>
                    <Form
                        {...formItemLayout}
                        form={form}
                        name="register"
                        onFinish={onFinish}

                        style={{
                            // maxWidth: 600,
                            width: 600
                        }}
                        scrollToFirstError
                    >
                        <div className='grid grid-cols-2 gap-2 '>
                            {/*  ---------------------------------- productName ----------------------------------  */}
                            <div>
                                <label htmlFor="" className='text-xl font-semibold'>Variant Name </label>
                                <Form.Item
                                    name="name"
                                    // label="Name "
                                    className='mb-2'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Variant Name!',
                                            whitespace: true,
                                        },
                                    ]}
                                >

                                    <Input placeholder='Variant Name' className='py-2' />
                                </Form.Item>
                            </div>
                            {/*  ----------------------------------Variant Subtittle ----------------------------------  */}
                            <div>
                                <label htmlFor="" className='text-xl font-semibold'>Variant Subtittle </label>
                                <Form.Item
                                    name="subtittle"
                                    // label="Name "
                                    className='mb-2'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Variant Subtittle!',
                                            whitespace: true,
                                        },
                                    ]}
                                >

                                    <Input placeholder='Variant Subtittle' className='py-2' />
                                </Form.Item>
                            </div>
                            {/*  ----------------------------------Variant availability ----------------------------------  */}
                            <div>
                                <label htmlFor="" className='text-xl font-semibold'>Variant Availability </label>
                                <Form.Item
                                    name="availability"
                                    // label="Name "
                                    className='mb-2'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select Variant availability!',
                                            // whitespace: true,
                                        },
                                    ]}
                                >

                                    <Radio.Group>
                                        <Radio value={true}> Available </Radio>
                                        <Radio value={false}> Not Available </Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </div>

                        </div>
                        {/*  ----------------------------------Pricing ----------------------------------  */}
                        <>
                            <h1 className='text-xl font-bold my-3'>Pricing</h1>
                            <div className='grid grid-cols-2 gap-2 '>
                                {/*  ----------------------------------price tittle ----------------------------------  */}
                                <div>
                                    <label htmlFor="" className='text-xl font-semibold'>Price Tittle </label>
                                    <Form.Item
                                        name={['pricing', 'tittle']}
                                        // label="Name "
                                        className='mb-2'
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: 'Please input your Price Tittle!',
                                    //         whitespace: true,
                                    //     },
                                    // ]}
                                    >

                                        <Input placeholder='Price Tittle' className='py-2' />
                                    </Form.Item>
                                </div>
                                {/*  ----------------------------------price description ----------------------------------  */}
                                <div>
                                    <label htmlFor="" className='text-xl font-semibold'>Price Description </label>
                                    <Form.Item
                                        name={['pricing', 'description']}
                                        // label="Name "
                                        className='mb-2'
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: 'Please input your Price Description!',
                                    //         whitespace: true,
                                    //     },
                                    // ]}
                                    >

                                        <Input placeholder='Price Description' className='py-2' />
                                    </Form.Item>
                                </div>
                            </div>
                            {/*  ----------------------------------Prices ----------------------------------  */}
                            <>
                                {/* <h1 className='font-semibold text-xl my-4'>Prices</h1> */}
                                {selectPriceValues?.map((element, index) => (
                                    <div key={index}>
                                        <div className='flex justify-between gap-3 mr-2'>
                                            <div className='w-full'>
                                                <label htmlFor="" className='text-xl font-semibold'> Currency </label>
                                                <Select
                                                    // defaultValue="lucy"
                                                    placeholder='Select the  color'
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    size='large'
                                                    value={element?.currency}
                                                    onChange={(value) => handleChangePrices('currency', index, value)}
                                                // disabled={filterButtonMaterialFavColor.length === 0 ? true : false}
                                                // disabled={countryCurrency.some((feature) => feature.currency === element.currency)}
                                                // options={countryCurrency?.map((item) => ({
                                                //     label: `${item.country} (${item.currency}) `,
                                                //     value: item.currency,
                                                // }))}
                                                // />

                                                >

                                                    {countryCurrency?.map((item) => (
                                                        <Select.Option
                                                            key={item.country}
                                                            value={item.currency}
                                                            disabled={selectPriceValues.some((feature) => feature.currency === item.currency)}
                                                        >
                                                            {`${item.country} (${item.currency}) `}
                                                        </Select.Option>
                                                    ))}
                                                </Select>

                                            </div>
                                            <div>
                                                {index ? (
                                                    <button
                                                        type="button"
                                                        className="mt-8"
                                                        onClick={() => removeFormFields(index)}
                                                    >
                                                        <Tooltip placement="top" title="Remove">
                                                            <MinusCircleOutlined
                                                                style={{ fontSize: "20px", color: "red" }}
                                                            />
                                                        </Tooltip>
                                                    </button>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        className="mt-8"
                                                        onClick={() => addFormFields()}
                                                    >
                                                        <Tooltip placement="top" title="Add More">
                                                            <PlusCircleOutlined
                                                                style={{ fontSize: "20px", color: "green" }}
                                                            />
                                                        </Tooltip>
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                        <div className='grid grid-cols-3 gap-2 mt-2 mb-5'>

                                            <div className='w-full'>
                                                <label htmlFor="" className='text-xl font-semibold'>Retail Price</label>

                                                <Input className='py-2' type="number" name='retailPrice' value={element?.retailPrice} onChange={(e) => handleChangePrices('retailPrice', index, e.target.value)} />
                                            </div>
                                            <div className='w-full'>
                                                <label htmlFor="" className='text-xl font-semibold'>Selling Price</label>

                                                <Input className='py-2' type="number" name='sellingPrice' value={element?.sellingPrice} onChange={(e) => handleChangePrices('sellingPrice', index, e.target.value)} />
                                            </div>
                                            <div className='w-full'>
                                                <label htmlFor="" className='text-xl font-semibold'>Special Price</label>

                                                <Input className='py-2' type="number" name='specialPrice' value={element?.specialPrice} onChange={(e) => handleChangePrices('specialPrice', index, e.target.value)} />
                                            </div>

                                        </div>

                                    </div>
                                ))}
                            </>
                        </>

                        {/*  ----------------------------------Attributes ----------------------------------  */}
                        <>
                            <h1 className='font-bold text-xl my-4'>Attributes</h1>
                            {attributeValues?.map((element, index) => (
                                <div key={index}>
                                    <div className='flex justify-between gap-3 mr-2'>
                                        <div className='grid grid-cols-3 gap-2 mt-2 mb-5'>

                                            <div className='w-full'>
                                                <label htmlFor="" className='text-xl font-semibold'>Name</label>

                                                <Input className='py-2' name='attributeName' value={element?.name} onChange={(e) => handleChangeAttribute('name', index, e.target.value)} />
                                            </div>
                                            <div className='w-full'>
                                                <label htmlFor="" className='text-xl font-semibold'>Value</label>

                                                <Input className='py-2' name='value' value={element?.value} onChange={(e) => handleChangeAttribute('value', index, e.target.value)} />
                                            </div>
                                            <div className='w-full'>
                                                <label htmlFor="" className='text-xl font-semibold'>Description</label>

                                                <Input className='py-2' name='description' value={element?.description} onChange={(e) => handleChangeAttribute('description', index, e.target.value)} />
                                            </div>

                                        </div>
                                        <div>
                                            {index ? (
                                                <button
                                                    type="button"
                                                    className="mt-8"
                                                    onClick={() => removeAttributeFields(index)}
                                                >
                                                    <Tooltip placement="top" title="Remove">
                                                        <MinusCircleOutlined
                                                            style={{ fontSize: "20px", color: "red" }}
                                                        />
                                                    </Tooltip>
                                                </button>
                                            ) : (
                                                <button
                                                    type="button"
                                                    className="mt-8"
                                                    onClick={() => addAttributeFields()}
                                                >
                                                    <Tooltip placement="top" title="Add More">
                                                        <PlusCircleOutlined
                                                            style={{ fontSize: "20px", color: "green" }}
                                                        />
                                                    </Tooltip>
                                                </button>
                                            )}
                                        </div>
                                    </div>


                                </div>
                            ))}
                        </>
                        {/*  ----------------------------------Features ----------------------------------  */}
                        <>
                            <h1 className='font-bold text-xl my-4'>Features</h1>
                            {featuresValues?.map((element, index) => (
                                <div key={index} className='mb-2 '>
                                    <div className='flex justify-between gap-3 mr-2'>
                                        <div className='w-full'>
                                            <label htmlFor="" className='text-xl font-semibold'>Tittle</label>

                                            <Input className='py-2' name='tittle' value={element?.tittle} onChange={(e) => handleChangeFeatures('tittle', index, null, e.target.value)} />
                                        </div>

                                        <div>
                                            {index ? (
                                                <button
                                                    type="button"
                                                    className="mt-8"
                                                    onClick={() => removeFeatureFields(index)}
                                                >
                                                    <Tooltip placement="top" title="Remove">
                                                        <MinusCircleOutlined
                                                            style={{ fontSize: "20px", color: "red" }}
                                                        />
                                                    </Tooltip>
                                                </button>
                                            ) : (
                                                <button
                                                    type="button"
                                                    className="mt-8"
                                                    onClick={() => addFeaturesFields()}
                                                >
                                                    <Tooltip placement="top" title="Add More">
                                                        <PlusCircleOutlined
                                                            style={{ fontSize: "20px", color: "green" }}
                                                        />
                                                    </Tooltip>
                                                </button>
                                            )}
                                        </div>

                                    </div>
                                    <div className='flex gap-2 mt-2'>

                                        {/* <h1 className='text-lg font-semibold  my-3  '>Details</h1> */}
                                        <label htmlFor="" className='text-xl font-semibold my-3'>Details</label>
                                        <div className='w-full'>
                                            {element?.details?.map((obj, detailIndex) => (
                                                <div className='flex justify-between  mt-2  gap-3 mr-2'>

                                                    <div className='grid w-full grid-cols-2 gap-2 mb-5'>

                                                        {/* <div className='w-full'>
                                                                <label htmlFor="" className='text-xl font-semibold'>key</label> */}

                                                        <Input className='py-2' placeholder='key' name='key' value={obj?.key} onChange={(e) => handleChangeFeatures('key', index, detailIndex, e.target.value)} />
                                                        {/* </div>
                                                            <div className='w-full'>
                                                                <label htmlFor="" className='text-xl font-semibold'>Value</label> */}

                                                        <Input className='py-2' name='value' placeholder='value' value={obj?.value} onChange={(e) => handleChangeFeatures('value', index, detailIndex, e.target.value)} />
                                                        {/* </div> */}


                                                    </div>

                                                    <div>
                                                        {detailIndex ? (
                                                            <button
                                                                type="button"
                                                                // className="mt-8"
                                                                onClick={() => removeFeaturesDetailsFields(index, detailIndex)}
                                                            >
                                                                <Tooltip placement="top" title="Remove">
                                                                    <MinusCircleOutlined
                                                                        style={{ fontSize: "20px", color: "red" }}
                                                                    />
                                                                </Tooltip>
                                                            </button>
                                                        ) : (
                                                            <button
                                                                type="button"
                                                                // className="mt-8"
                                                                onClick={() => addFeaturesDetailsFields(index)}
                                                            >
                                                                <Tooltip placement="top" title="Add More">
                                                                    <PlusCircleOutlined
                                                                        style={{ fontSize: "20px", color: "green" }}
                                                                    />
                                                                </Tooltip>
                                                            </button>
                                                        )}
                                                    </div>

                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className='flex gap-2'>

                                        <label htmlFor="" className='text-xl font-semibold my-3' >Highlights</label>
                                        <div className='w-full'>

                                            {element?.highlights?.map((obj, highlightIndex) => (
                                                <>
                                                    <div className='flex w-full mt-2 justify-between items-center gap-3 mr-2'>

                                                        <div className='w-full'>
                                                            {/* <label htmlFor={`highlight-${index}-${highlightIndex}`} className='text-xl font-semibold'>highlight</label> */}

                                                            <Input className='py-2'
                                                                id={`highlight-${index}-${highlightIndex}`}
                                                                name={`highlight-${index}-${highlightIndex}`}
                                                                placeholder='highlights'
                                                                value={obj} onChange={(e) => handleChangeFeatures('highlights', index, highlightIndex, e.target.value)} />
                                                        </div>

                                                        <div>
                                                            {highlightIndex ? (
                                                                <button
                                                                    type="button"

                                                                    onClick={() => removeFeaturesHighlightFields(index, highlightIndex)}
                                                                >
                                                                    <Tooltip placement="top" title="Remove">
                                                                        <MinusCircleOutlined
                                                                            style={{ fontSize: "20px", color: "red" }}
                                                                        />
                                                                    </Tooltip>
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    type="button"

                                                                    onClick={() => addFeaturesHighlightFields(index)}
                                                                >
                                                                    <Tooltip placement="top" title="Add More">
                                                                        <PlusCircleOutlined
                                                                            style={{ fontSize: "20px", color: "green" }}
                                                                        />
                                                                    </Tooltip>
                                                                </button>
                                                            )}
                                                        </div>

                                                    </div>


                                                </>
                                            ))}
                                        </div>
                                    </div>
                                    <div className='border-b-black border-b-2 mt-4'></div>



                                </div>
                            ))}
                        </>
                        {/*  ----------------------------------Color ----------------------------------  */}
                        <>
                            <h1 className='font-bold text-xl my-4'>Color </h1>
                            <div className='grid grid-cols-4 gap-2 mt-2 mb-5'>
                                <div>
                                    <label htmlFor="" className='text-xl font-semibold'>Tittle </label>
                                    <Form.Item
                                        name={['color', 'tittle']}
                                        // label="Name "
                                        className='mb-2'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your Tittle!',
                                                whitespace: true,
                                            },
                                        ]}
                                    >

                                        <Input placeholder='Tittle' className='py-2' />
                                    </Form.Item>
                                </div>
                                <div>
                                    <label htmlFor="" className='text-xl font-semibold'>Name </label>
                                    <Form.Item
                                        name={['color', 'name']}
                                        // label="Name "
                                        className='mb-2'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your Name!',
                                                whitespace: true,
                                            },
                                        ]}
                                    >

                                        <Input placeholder='Name' className='py-2' />
                                    </Form.Item>
                                </div>
                                <div>
                                    <label htmlFor="" className='text-xl font-semibold'>Primary Color </label>
                                    <Form.Item
                                        name={['color', 'primaryColor']}
                                        // label="Name "
                                        className='mb-2'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your Primary Color!',
                                                whitespace: true,
                                            },
                                        ]}
                                    >

                                        <Input placeholder='Primary Color' className='py-2' />
                                    </Form.Item>
                                </div>
                                <div>
                                    <label htmlFor="" className='text-xl font-semibold'>Secondary Color </label>
                                    <Form.Item
                                        name={['color', 'secondaryColor']}
                                        // label="Name "
                                        className='mb-2'
                                        // rules={[
                                        //     {
                                        //         required: true,
                                        //         message: 'Please input your Secondary Color!',
                                        //         whitespace: true,
                                        //     },
                                        // ]}
                                    >

                                        <Input placeholder='Secondary Color' className='py-2' />
                                    </Form.Item>
                                </div>
                                {/* <div className='w-full'>
                                    <label htmlFor="" className='text-xl font-semibold'>Tittle</label>

                                    <Input className='py-2' name='tittle' placeholder='tittle' onChange={handleChangeColor} />
                                </div>
                                <div className='w-full'>
                                    <label htmlFor="" className='text-xl font-semibold'>Name</label>

                                    <Input className='py-2' name='name' placeholder='color name' onChange={handleChangeColor} />
                                </div>
                                <div className='w-full'>
                                    <label htmlFor="" className='text-xl font-semibold'> Primary Color</label>

                                    <Input className='py-2' name='primaryColor' placeholder='color code' onChange={handleChangeColor} />
                                </div>
                                <div className='w-full'>
                                    <label htmlFor="" className='text-xl font-semibold'> Secondary Color</label>

                                    <Input className='py-2' name='secondaryColor' placeholder='color code' onChange={handleChangeColor} />
                                </div> */}

                            </div>
                        </>
                        {/*  ----------------------------------Quantity rules ----------------------------------  */}
                        <>
                            <h1 className='font-bold text-xl my-4'>Quantity Rule</h1>
                            <div className='grid grid-cols-3 gap-2 mt-2 mb-5'>
                                <div>
                                    <label htmlFor="" className='text-xl font-semibold'>Minimum Quantity </label>
                                    <Form.Item
                                        name={['quantityRule', 'min']}
                                        // label="Name "
                                        className='mb-2'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your Minimum Quantity!',
                                                whitespace: true,
                                            },
                                        ]}
                                    >

                                        <Input type='number' placeholder='Minimum Quantity' className='py-2' />
                                    </Form.Item>
                                </div>
                                <div>
                                    <label htmlFor="" className='text-xl font-semibold'>Maximum Quantity </label>
                                    <Form.Item
                                        name={['quantityRule', 'max']}
                                        // label="Name "
                                        className='mb-2'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your Maximum Quantity!',
                                                whitespace: true,
                                            },
                                        ]}
                                    >

                                        <Input type="number" placeholder='Maximum Quantity' className='py-2' />
                                    </Form.Item>
                                </div>
                                <div>
                                    <label htmlFor="" className='text-xl font-semibold'>Quantity Increment </label>
                                    <Form.Item
                                        name={['quantityRule', 'increment']}
                                        // label="Name "
                                        className='mb-2'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your Quantity Increment!',
                                                whitespace: true,
                                            },
                                        ]}
                                    >

                                        <Input type="number" placeholder='Quantity Increment' className='py-2' />
                                    </Form.Item>
                                </div>
                                {/* <div className='w-full'>
                                    <label htmlFor="" className='text-xl font-semibold'>Minimum Quantity</label>

                                    <Input className='py-2' name='min' placeholder='Minimum Quantity' onChange={handleChangeQuantity_rule} />
                                </div>
                                <div className='w-full'>
                                    <label htmlFor="" className='text-xl font-semibold'>Maximum Quantity</label>

                                    <Input className='py-2' name='max' placeholder='Minimum Quantity' onChange={handleChangeQuantity_rule} />
                                </div>
                                <div className='w-full'>
                                    <label htmlFor="" className='text-xl font-semibold'> Quantity Increment</label>

                                    <Input className='py-2' name='increment' placeholder='Quantity Increment' onChange={handleChangeQuantity_rule} />
                                </div> */}

                            </div>
                        </>
                        {/*  ----------------------------------Stocks ----------------------------------  */}
                        <>
                            <h1 className='font-bold text-xl my-4'>Stocks</h1>
                            <div>

                                <p className='text-md font-semibold'>Total Stock : {totalStock ? totalStock : 0}</p>
                                <p className='text-md font-semibold' >Avaliable Stock : {avaliableStock ? avaliableStock : 0}</p>
                            </div>

                            <div className='grid grid-cols-2 gap-2 mt-2 mb-5'>
                                <div>
                                    <label htmlFor="" className='text-xl font-semibold'>No. of stocks to be added </label>
                                    <Form.Item
                                        name="stockAdded"
                                        // label="Name "
                                        className='mb-2'
                                        rules={[
                                            {
                                                required: edit ? false : true,
                                                message: 'Please input your No. of products!',
                                                whitespace: true,
                                            },
                                        ]}
                                    >

                                        <Input type="number" placeholder='No. of products' className='py-2' />
                                    </Form.Item>
                                </div>
                                <div>
                                    <label htmlFor="" className='text-xl font-semibold'>No. of stocks to be removed </label>
                                    <Form.Item
                                        name="stockRemoved"
                                        className='mb-2'
                                    >

                                        <Input disabled={edit ? false : true} type="number" placeholder='No. of products' className='py-2' />
                                    </Form.Item>
                                </div>
                            </div>
                            {/* <div className=''>
                                <label htmlFor="" className='text-xl  font-semibold'>No. of products</label>
                                <div className=' flex gap-2 items-center'>

                                    <Input className='py-2' name='stock' placeholder='No. of products' onChange={(e) => setAddStocks(e.target.value)} />
                                    <Button onClick={handleChangeStock}>Add Stock</Button>
                                </div>

                            </div> */}
                            {/* <div className='grid grid-cols-2 gap-2 mt-2 mb-5'>

                                    <div className='w-full'>
                                        <label htmlFor="" className='text-xl font-semibold'>AvaliableStock</label>

                                        <Input className='py-2' name='avaliableStock' placeholder='Avaliable Stock' disabled />
                                    </div>
                                    <div className='w-full'>
                                        <label htmlFor="" className='text-xl font-semibold'> Total Stock</label>

                                        <Input className='py-2' name='totalStock' placeholder='Total Stock' disabled />
                                    </div>

                                </div> */}
                        </>
                        <div>
                            <h1 className='font-bold text-xl my-4'>Images </h1>
                            <ImageVideoUploader images={images} setImages={setImages} productID={productID} identifier={"variantImage"}  />

                        </div>
                        {productID &&
                            <div>
                                <h1 className='font-bold text-xl my-4'>custom Images Images </h1>
                                <h1 className='font-semibold text-sm my-4'>Front Side Images </h1>
                                <ImageVideoUploader images={frontImages} setImages={setFrontImages} productID={productID} identifier={"frontside"} customImage={true} />

                                <h1 className='font-semibold text-sm my-4'>Back Side Images </h1>
                                <ImageVideoUploader images={backImages} setImages={setBackImages} productID={productID} identifier={"backside"} customImage={true} />

                            </div>
                        }

                        <div className="flex justify-end mt-4">
                            <div className="flex  items-center">

                                <Button onClick={handleCancelUploadModal}>Cancel</Button>
                                <Form.Item {...tailFormItemLayout} className='pt-5' >
                                    <Button className='bg-blue-800 w-20  ' loading={loader} type="primary" htmlType="submit">
                                        Save
                                    </Button>
                                </Form.Item>
                                {/* <Button
                                    // loading={loader}
                                    // onClick={onComplete}
                                    type="submit"
                                    className="bg-blue-500 hover:bg-blue-400 hover:text-white text-white px-4 py-1 rounded"
                                >
                                    Save
                                </Button> */}
                            </div>
                        </div>
                    </Form>
                </div>
            </Modal>
        </div>
    )
}

export default Variant