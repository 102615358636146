import React, { useEffect } from 'react'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Space, Table, Typography } from 'antd';
import { useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { viewAdmins } from '../../../api/AdminRequest';
// import { Modal } from 'antd';
import DrawerBox from '../../drawer/Drawer';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// import CardDrawer from '../cardsDrawer/CardDrawer';
import Moment from 'moment'

// const { confirm } = Modal;
const { Title } = Typography;

function ViewAdmins() {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [admins, setAdmins] = useState([])

    const [loader, setLoader] = useState(true)

    const [change, setChange] = useState('')
    const [viewId, setViewID] = useState('')
    const [open, setOpen] = useState(false);
    // const [openCard, setOpenCard] = useState(false);
    // const [cardViewId, setCardViewId] = useState('')
    const navigate = useNavigate()
    const location = useLocation()

    const showDrawer = async (ID) => {
        setViewID(ID)
        setOpen(true);

    };
    const onClose = () => {
        setOpen(false);
    };
    // const showCardDrawer = async (ID) => {
    //     // console.log(ID,'iiiiiiiiiiiiiiiiidddddddddddd');
    //     setCardViewId(ID)
    //     setOpenCard(true);

    // };
    // const onCloseCard = () => {
    //     setOpenCard(false);
    // };
    
    const showCards = async (ID) => {
        // console.log(ID,'iiiiiiiiiiiiiiiiidddddddddddd');
        // setCardViewId(ID)
        // setOpenCard(true);
        // navigate("/cards", { state: ID });
        navigate("/cards", { state: { details: ID, status: 'admin', from: location } })

    };
    



    useEffect(() => {
        const getAdmins = async () => {
            try {
                const { data } = await viewAdmins()
                // console.log(data, '12355');
                setAdmins(data.admins)
                setLoader(false)
            } catch (error) {
                // console.log(error.message);
            }
        }
        getAdmins()
    }, [change])


    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        className='bg-blue-800'
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    {/* <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button> */}
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            handleSearch(selectedKeys, confirm)
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    const columns = [
        {
            // title: 'ID',
            // dataIndex: '_id',
            // key: '_id',
            // width: '30%',
            // ...getColumnSearchProps('shopperId'),
            title: 'No.',
            dataIndex: '_id',
            key: 'index',
            // render: (text, record, index) => index + 1,
            render: (text, record, index) => (admins.indexOf(record) + 1),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name'),
        },

        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ...getColumnSearchProps('email'),
        },
        {
            title: 'Card Limit',
            dataIndex: 'status._id',
            key: '_id',
            render: (status, _id) => {
                // const feature = _id?.features;
                return (
                    <div>
                        {_id?.features?.map((item, index) => (
                            <div key={index}>
                                {item?.type === 'feedbackCard' ?
                                    <>
                                        <p className='font-semibold'>{item?.type} </p>
                                        <p>
                                            <span >  Service Manger Limit :</span>   {item?.serviceMangerLimit}
                                        </p>
                                        <p>
                                            <span >  Employee Limit :</span>  {item?.employeeLimit}
                                        </p>

                                    </>
                                    :
                                    <p>
                                        <span className='font-semibold'> {item?.type} :</span>   {item.limit}
                                    </p>

                                }

                            </div>
                        ))}
                    </div>

                )
            }
        },
        {
            title: 'Expiry Date',
            dataIndex: 'status._id',
            key: '_id',
            render: (status, _id) => {
                const ExpiryDate = new Date(Moment(_id.createdDate) + _id.expiryDate * 24 * 60 * 60 * 1000)

                return (
                    <p>{Moment(ExpiryDate).format("Do MMM YYYY")}</p>

                )
            }

        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            // ...getColumnSearchProps('status'),
            render: (status) => { return status === 'active' ? <p className=' text-center'>Active</p> : <p className='bg-red-500 px-1 text-white text-center rounded-xl'>Blocked</p> },
        },

        {
            title: 'View',
            dataIndex: 'status._id',
            key: '_id',
            render: (status, _id) => {
                return (
                    <Button className='w-24 bg-slate-300 hover:bg-slate-200' onClick={() => showDrawer(_id)} >View</Button>
                )
            }

        },
        {
            title: 'Cards',
            dataIndex: 'status._id',
            key: '_id',
            render: (status, _id) => <Button className='w-24 bg-slate-300 hover:bg-slate-200' onClick={() => showCards(_id)} >Cards</Button>

        },
        // {
        //     title: 'Cards',
        //     dataIndex: '_id',
        //     key: '_id',
        //     render: (_id) => <Button className='w-24 bg-slate-300 hover:bg-slate-200' onClick={() => showCardDrawer(_id)} >Cards</Button>

        // },

    ];
    return (
        <>
            <Row gutter={16}>
                <Col span={12}>
                    <Title level={2}>Admins </Title>
                </Col>
                <Col span={12} className='text-right'>
                    <Link className='add-button rounded-lg ml-auto' to="/create-admin"><PlusOutlined />Add Admin</Link>
                </Col>
            </Row>

            <div className='flex justify-center'>
                <div className='w-full mt-5'>
                    <Table columns={columns} loading={loader} dataSource={admins} pagination={admins.length > 10 ? true : false} />
                    <DrawerBox viewId={viewId} setChange={setChange} open={open} onClose={onClose} />
                    {/* <CardDrawer cardViewId={cardViewId} openCard={openCard} onCloseCard={onCloseCard} /> */}

                </div>
            </div>
        </>
    )
}

export default ViewAdmins