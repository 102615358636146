import { Button, Col, Form, Input, Row, Select, Typography, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import React, { useEffect, useState } from 'react'
// import { useLocation } from 'react-router-dom';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createThemes, editThemes } from '../../api/AdminRequest';
import HandleImageUpload from '../helper/ImageCompress';
const { Title } = Typography;

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    // wrapperCol: {
    //   xs: {
    //     span: 24,
    //   },
    //   sm: {
    //     span: 16,
    //   },
    // },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

function CreateTheme({ edit, setEdit, handleCancelModal, themeDetails, setChange }) {
    const [form] = Form.useForm();
    // const { state } = useLocation();

    // console.log(state, '************************');
    // console.log(edit, '++++++++++++++++');
    // console.log(themeDetails, '************************');

    // const [theme, setTheme] = useState({})
    const [fileList, setFileList] = useState([])
    const [themeType, setThemeType] = useState(null)
    const [error, setError] = useState({});

    useEffect(() => {
        // console.log('useEffect');

        if (themeDetails) {
            // console.log('oooo');
            const imageFile = [
                {
                    uid: "-1",
                    name: "image.png",
                    status: themeDetails?.image ? "done" : "",
                    url: themeDetails?.image,
                },
            ]

            const initialValues = {
                name: themeDetails?.name,
                value: themeDetails?.value
            }

            // setTheme(themeDetails)

            form.setFieldsValue(initialValues);
            setFileList(imageFile)
            setThemeType(themeDetails?.type)
        }
    }, [themeDetails, edit])


    // console.log(theme, 'llll');
    const onFinish = async (values) => {
        values.type = themeType
        // console.log('Form Values:', values);
        let id = themeDetails?._id

        const errors = validateForm(values);
        setError(errors)
        if (Object.keys(errors).length === 0) {
            try {

                const ThemeImgCompress = await HandleImageUpload(fileList)
                const datas = new FormData();
                datas.append("themeImage", ThemeImgCompress);
                for (const keys in values) {
                    datas.append(`${keys}`, `${values[keys]}`);
                }

                // datas.append('details', JSON.stringify(values))

                const { data } = edit ? await editThemes(id, datas) : await createThemes(datas)
                // console.log(data);
                if (data.success) {
                    toast.success('New Theme Created', {
                        position: "top-right",
                        autoClose: 2000,
                        theme: "dark",
                    });
                } else if (data.update) {
                    toast.success('Updated Successfully', {
                        position: "top-right",
                        autoClose: 2000,
                        theme: "dark",
                    });

                }
                setTimeout(() => {
                    setChange(Date.now())
                    setFileList([])
                    form.resetFields()
                    //    setFormData(initialValue)
                    setThemeType(null)
                    setEdit(false)
                    // setTheme('')
                    handleCancelModal()
                }, 3000)
            } catch (error) {
                // console.log(error);
                if (error?.response?.data?.message) {
                    toast(error?.response?.data?.message)
                } else {

                    toast(error.message)
                }
            }
        } else {
            const firstValue = Object.values(errors)[0];
            // console.log(firstValue, '23456hggfgfg');
            toast.error(`Please fill required fields, ${firstValue}`, {
                autoClose: 3000,
                theme: "dark",
            });
        }
    }

    const themeTypes = [
        {
            name: 'Business Crad Themes',
            value: 'businessCardThemes'
        }
    ]

    const handleChangeThemeType = (value) => {
        // console.log(`selected ${value}`);
        setThemeType(value)

    };

    const onChange = ({ fileList: newFileList }) => {
        if (newFileList[0]?.status) { newFileList[0].status = "done"; }
        setFileList(newFileList);


    };
    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const handleCancel = () => {
        setFileList([])
        // setFormData(initialValue)
        form.resetFields()
        setThemeType(null)
        setError('')
        setEdit(false)
        setChange(Date.now())
        // setTheme('')
        handleCancelModal()
    }
    // console.log(formData,'kkkkkkk');

    const validateForm = (data) => {
        const error = {};

        if (!data.type) {
            error.type = "Theme Type required";
        }
        if (!fileList[0]?.originFileObj && !fileList[0]?.url) {
            error.image = "Theme Image required";
        }



        return error;
    };


    return (
        <div>
            <Row gutter={16}>
                <Col span={12}>
                    <Title level={3}>{edit ? 'Edit Theme' : 'Create Theme'}</Title>
                </Col>
            </Row>
            <Form
                {...formItemLayout}
                form={form}
                name="register"
                onFinish={onFinish}
                // initialValues={{
                //     name: edit ? theme?.name : '',
                //     value: edit ? theme?.value : '',

                // }}
                // style={{
                //     // maxWidth: 600,
                //     width: 450
                // }}
                scrollToFirstError
            >
                <div >
                    <div>
                        <label htmlFor="" className='text-xl font-semibold'>Image</label>
                        <ImgCrop rotationSlider aspectSlider showReset>
                            <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture-card"
                                fileList={fileList}
                                onChange={onChange}
                                onPreview={onPreview}
                            >
                                {fileList.length < 1 && "+ Upload"}
                            </Upload>
                        </ImgCrop>
                        <p className='text-red-500'>{error.image}</p>
                    </div>
                    <div>
                        <label htmlFor="" className='text-xl font-semibold'>Name</label>
                        <Form.Item
                            name="name"
                            // label="E-mail"
                            className='mb-2'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Name!',
                                },
                            ]}
                        >

                            <Input placeholder='Theme Name' className='py-2' />
                        </Form.Item>
                    </div>
                    <div>
                        <label htmlFor="" className='text-xl font-semibold'>Value</label>
                        <Form.Item
                            name="value"
                            // label="E-mail"
                            className='mb-2'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Value',
                                },
                            ]}
                        >

                            <Input placeholder='Theme value Name' className='py-2' />
                        </Form.Item>
                    </div>
                    <div>
                        <label htmlFor="" className='text-xl font-semibold'>Type</label>
                        <Select
                            // defaultValue="lucy"
                            placeholder='Select the theme type '
                            value={themeType}
                            style={{
                                width: '100%',
                            }} f
                            // disabled={filterMaterialColor.length === 0 ? true : false}
                            size='large'
                            onChange={handleChangeThemeType}
                            options={themeTypes?.map((item) => ({
                                label: item.name,
                                value: item.value,
                            }))}
                        />
                        <p className='text-red-500'>{error.type}</p>
                    </div>

                </div>
                <Form.Item {...tailFormItemLayout} className='flex justify-center  mr-14'>
                    <div className='flex gap-3' >

                        <Button key="back" className='w-32 my-5' onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button className='bg-blue-800 w-32  my-5 ' type="primary" htmlType="submit">
                            {edit ? 'Save' : ' Register'}
                        </Button>
                    </div>
                </Form.Item>
            </Form>
            {/* <ToastContainer /> */}
        </div>
    )
}

export default CreateTheme