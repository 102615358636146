// import {
//     MenuFoldOutlined,
//     MenuUnfoldOutlined,
//     // UploadOutlined,
//     UserOutlined,
//     // VideoCameraOutlined,
//     FileOutlined, PieChartOutlined ,TeamOutlined
// } from '@ant-design/icons';
// import { Layout, Menu, theme } from 'antd';
// import React, { useState } from 'react';
// import './Sidebar.css'
// import { useNavigate } from 'react-router-dom';
// const { Header, Sider } = Layout;
// function getItem(label, key, icon, children) {
//     return {
//       key,
//       icon,
//       children,
//       label,
//     };
//   }

// function Sidebar() {
//     const [collapsed, setCollapsed] = useState(false);
//     const {
//         token: { colorBgContainer },
//     } = theme.useToken();
//     const navigate = useNavigate()

//     const items = [

//         getItem('Dashboard', '/dashboard', <PieChartOutlined />),
//         getItem('Admin', 'sub1', <UserOutlined />, [
//           getItem('Create Admin', '/create-admin'),
//           getItem('View Admins', '/view-admins'),
//         ]),
//         getItem('Users', '4', <TeamOutlined /> , [
//             getItem('Active Users', '/active-users'), getItem('Inactive Users', '/inactive-users')]),
//         // getItem('Team', 'sub2', <TeamOutlined />, [getItem('Team 1', '6'), getItem('Team 2', '8')]),
//         getItem('Cards', '/cards', <FileOutlined />),
//       ]
//     return (
//         <div className=''  >
//         {/* <Layout> */}
//             <Sider className='h-screen' trigger={null} collapsible collapsed={collapsed}>
//                 <div className='flex justify-between'>
//                     <div className="logo  w-28" />
//                     <Header
//                         style={{
//                             width:50,
//                             padding: 0,
//                             background: colorBgContainer,
//                         }}
//                     >
//                         {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
//                             className: 'trigger',
//                             onClick: () => setCollapsed(!collapsed),
//                         })}
//                     </Header>
//                 </div>
//                 {/* <Menu
//                     theme="dark"
//                     mode="inline"
//                     defaultSelectedKeys={[window.location.pathname]}
//                     onClick={({key})=>{
//                         if (key === "signout") {

//                         }else{
//                             navigate(key)
//                         }
//                     }}
//                     items={[
//                         {
//                             key: '/dashboard',
//                             icon: <UserOutlined />,
//                             label: 'Dashboard',
//                         },
//                         {
//                             // key: '/create-admin',
//                             icon: <VideoCameraOutlined />,
//                             label: 'Admin',
//                             children:[
//                                 {key: '/create-admin', label:'Create Admin'},
//                                 {key: '/view-admins', label:'View Admin'}
//                             ]
//                         },
//                         {
//                             key: '3',
//                             icon: <UploadOutlined />,
//                             label: 'user',
//                         },
//                     ]}
//                 /> */}
//                 <Menu theme="dark" defaultSelectedKeys={[window.location.pathname]} mode="inline" items={items}
//                 onClick={({key})=>{
//                     if (key === "signout") {

//                     }else{
//                         navigate(key)
//                     }
//                 }}
//                  />
//             </Sider>

//         {/* </Layout> */}
//         </div>
//     );
// }

// export default Sidebar



import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    // UploadOutlined,
    UserOutlined,
    // VideoCameraOutlined,
    // FileOutlined,
    PieChartOutlined, TeamOutlined,
    LogoutOutlined,
    ExclamationCircleFilled,
    // IdcardOutlined,
    // CreditCardOutlined ,
    DeleteOutlined,
    CreditCardOutlined,
    // IdcardOutlined
} from '@ant-design/icons';
// import { IoBusinessSharp } from "react-icons/io5";
// import { MdContactPhone, MdFeedback, MdReviews } from "react-icons/md";
// import { RxDashboard } from "react-icons/rx";
import { Layout, Menu } from 'antd';
import React, { useState } from 'react';
import './Sidebar.css'
import { useNavigate } from 'react-router-dom';

import { Modal } from 'antd';
import { logout } from '../../api/AdminRequest';
import { FaAffiliatetheme, FaShoppingCart } from 'react-icons/fa';
import { FaPeopleGroup } from "react-icons/fa6";
import { MdLanguage } from 'react-icons/md';
import logo from "../../assests/zeeqrLogo.png";
import { DiMaterializecss } from "react-icons/di";
import { PiFlagBannerFill } from 'react-icons/pi';

const { confirm } = Modal;
const { Header, Sider } = Layout;

function getItem(label, key, icon, children) {
    return {
        key,
        icon,
        children,
        label,
    };
}

function Sidebar() {
    const [collapsed, setCollapsed] = useState(false);

    const condition =
        window.location.pathname === "/contactCard/dashboard"
            ? "sub2"
            : window.location.pathname === "/contactCard/cards"
                ? "sub2"
                : window.location.pathname === "/contactCard/create-card"
                    ? "sub2"
                    : window.location.pathname === "/contactCard/update-card"
                        ? "sub2"
                        : window.location.pathname === "/businessCard/dashboard"
                            ? "sub1"
                            : window.location.pathname === "/admin-ViewCards"
                                ? "sub1"
                                : window.location.pathname === "/admin-viewUsers"
                                    ? "sub1"
                                    : window.location.pathname === "/admin-createCard"
                                        ? "sub1"
                                        : window.location.pathname === "/admin-editCard"
                                            ? "sub1"
                                            : window.location.pathname === "/feedback/dashboard"
                                                ? "sub3"
                                                : window.location.pathname === "/feedback-ViewService"
                                                    ? "sub3"
                                                    : window.location.pathname === "/feedback-editService"
                                                        ? "sub3"
                                                        : window.location.pathname === "/feedback-createService"
                                                            ? "sub3"
                                                            : window.location.pathname === "/feedback/trash-managers"
                                                                ? "sub3"
                                                                : "";
    const [current] = useState(condition);

    const navigate = useNavigate()

    const onClick = (e) => {
        // console.log('click ', e);

        if (e.key === "/signout") {
            // const showConfirm = () => {
            confirm({
                title: "Do you Want to Signout",
                icon: <ExclamationCircleFilled />,
                //   content: 'Some descriptions',
                okText: "Yes",
                okType: "danger",
                cancelText: "No",
                onOk() {
                    console.log("OK");
                    const signout = async () => {
                        try {
                            const refToken = await localStorage.getItem("admin-refToken")
                            // console.log(refToken, '12345');
                            const { data } = await logout(refToken)
                            console.log(data);
                            if (data.success) {
                                // dispatch(clearCardData());
                                localStorage.clear();
                                //   localStorage.removeItem('admin-refToken')
                                navigate("/");
                            }
                        } catch (error) {
                            console.log(error.response.data);
                            // dispatch(clearCardData());
                            localStorage.clear();
                            // localStorage.removeItem('admin-refToken')
                            navigate("/");
                        }
                    };
                    signout();
                },
                onCancel() {
                    console.log("Cancel");
                },
            });
            //   };
        } else {
            // setCurrent(e.key);
            navigate(e.key);
        }
    };

    // const items = [

    //     getItem('Dashboard', '/dashboard', <PieChartOutlined />),
    //     // getItem('Create Admin', '/create-admin', <IdcardOutlined />),
    //     getItem('Admins Profile', '/view-admins', <TeamOutlined />),
    //     getItem('Users Profile', '/active-users', <UserOutlined />),
    //     getItem('Orders', '/orders', <CreditCardOutlined />),
    //     getItem('Trash', '/trash', <DeleteOutlined />),
    //     // getItem('Profile', '/admin-profile', <UserOutlined />),
    //     // getItem('Cards', '/cards', <FileOutlined />),

    //     // getItem('Cards', 'sub1', <FileOutlined />, [
    //     //   getItem('Create Cards', '/admin-createCard'),
    //     //   getItem('View Cards', '/admin-ViewCards'),
    //     // ]),
    //     // getItem('Create Cards', '/admin-createCard', <UserOutlined />),
    //     // getItem('Users', '4', <TeamOutlined /> , [
    //     //     getItem('Active Users', '/admin-viewUsers'),
    //     //     //  getItem('Inactive Users', '/inactive-users')
    //     //     ]),
    //     // getItem('Team', 'sub2', <TeamOutlined />, [getItem('Team 1', '6'), getItem('Team 2', '8')]),
    //     getItem('SignOut', '/signout', <LogoutOutlined />),
    // ]

    const items = [
        // getItem("Main Dashboard", "/admin-dashboard", <RxDashboard />),
        getItem('Dashboard', '/dashboard', <PieChartOutlined />),
        // getItem(" Business Card", "sub1", <IoBusinessSharp />, [
        //     getItem(
        //         "Dashboard",
        //         "/businessCard/dashboard",
        //         <PieChartOutlined />
        //     ),
        //     getItem("Card Users", "/admin-ViewCards", <IdcardOutlined />),
        //     getItem("Users Profile", "/admin-viewUsers", <TeamOutlined />),
        // ]),
        // getItem(" Review Card", "#", <MdReviews />),
        // getItem(" Contact Card", "sub2", <MdContactPhone />, [
        //     getItem(
        //         "Dashboard",
        //         "/contactCard/dashboard",
        //         <PieChartOutlined />
        //     ),
        //     getItem(" Contact Card", "/contactCard/cards", <IdcardOutlined />),
        // ]),
        // getItem(" Feedback Card", "sub3", <MdFeedback />, [
        //     getItem("Dashboard", "/feedback/dashboard", <PieChartOutlined />),
        //     getItem("Managers", "/feedback-ViewService", <IdcardOutlined />),
        //     getItem(
        //         "Trash Managers",
        //         "/feedback/trash-managers",
        //         <IdcardOutlined />
        //     ),
        // ]),
        // getItem(" Feedback Card", "sub4", <MdFeedback />, [
        //     getItem(
        //         "Dashboard",
        //         "/feedbackForm/dashboard",
        //         <PieChartOutlined />
        //     ),
        // ]),
        getItem('Admins Profile', '/view-admins', <TeamOutlined />),
        getItem('Users Profile', '/active-users', <UserOutlined />),
        getItem('Orders', '/orders', <CreditCardOutlined />),
        getItem('Coupons', '/coupons', <CreditCardOutlined />),
        getItem('ZQR-Products', '/view-products', <FaShoppingCart />),
        getItem('Products', '/products', <FaShoppingCart />),
        getItem('Banner', '/banner', <PiFlagBannerFill />),
        getItem('Themes', '/themes', <FaAffiliatetheme />),
        getItem('Language', '/languages', <MdLanguage />),
        getItem('Materials', '/materials', <DiMaterializecss  />),
        getItem('Visitors Data', '/visitors', <FaPeopleGroup />),
        getItem('Trash', '/trash', <DeleteOutlined />),
       
        // getItem("Profile", "/admin-profile", <UserOutlined />),
        getItem("SignOut", "/signout", <LogoutOutlined />),
    ];

    return (
        <div className='sideWrap '  >
            {/* <Layout> */}
            <Sider
                style={{
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    height: '100vh',
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    bottom: 0,
                    
                }}
                // className='overflow-y-auto h-[100vh] fixed left-0 top-0 bottom-0 scrollbar-hide '
                trigger={null} collapsible collapsed={collapsed}>
                <div className='flex justify-between'>
                    {!collapsed ?
                    <div className="logo  w-28">
                        <img src={logo} alt='' />
                    </div>
                    :''
                    }
                    <Header
                        style={{
                            width: 48,
                            padding: 0,
                            // background: colorBgContainer,
                        }}
                    >
                        {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                            className: 'trigger text-white  pl-[10px] pr-[38px]',
                            onClick: () => setCollapsed(!collapsed),
                        })}
                    </Header>
                </div>



                {/* <Menu theme="dark" defaultSelectedKeys={[window.location.pathname]} mode="inline" items={items}

                    onClick={({ key }) => {
                        if (key === "/signout") {
                            // const showConfirm = () => {
                            confirm({
                                title: "Are you sure to Signout?",
                                icon: <ExclamationCircleFilled />,
                                // content: `if you delete, all the profile also get deleted.`,
                                okText: "Yes",
                                okType: "danger",
                                cancelText: "No",
                                onOk() {
                                    const signout = async () => {
                                        try {
                                            const refToken = await localStorage.getItem("admin-refToken")
                                            // console.log(refToken, '12345');
                                            const { data } = await logout(refToken)
                                            console.log(data);
                                            if (data.success) {
                                                localStorage.clear()
                                                //   localStorage.removeItem('admin-refToken')
                                                navigate('/')
                                            }
                                        } catch (error) {
                                            // console.log(error.response.data);
                                            localStorage.clear()
                                            // localStorage.removeItem('admin-refToken')
                                            navigate('/')
                                        }
                                    }
                                    signout()
                                },
                                onCancel() {
                                    console.log("Cancel");
                                },
                            });

                        } else {
                            navigate(key)
                        }
                    }}
                /> */}
                <Menu
                    theme="dark"
                    defaultSelectedKeys={[window.location.pathname]}
                    defaultOpenKeys={[current]}
                    mode="inline"
                    items={items}
                    onClick={onClick}
                />
            </Sider>

            {/* </Layout> */}
        </div>
    );
}

export default Sidebar