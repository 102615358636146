import React from 'react'

function SelectCardName({material, materialcolor, materialFavoriteColor, productDetails }) {
    // console.log(materialcolor,'materialcolor');
    // console.log(materialFavoriteColor,'materialFavoriteColor');
    const cardMaterail = productDetails?.CustomCardColor?.[material].filter((data) => (data.value === materialcolor))
    const textMaterail = productDetails?.CustomTextColor?.[materialcolor].filter((data) => (data.value === materialFavoriteColor))
    return (
        <div>
            {materialcolor === "custom" ? (
                <>
                    <p className='text-slate-500 text-[15px]' >Customized Card   </p>
                    <p className='text-slate-500 text-[15px]' >Customized Text   </p>
                </>

            ) :
                <>
                     <p className='text-slate-500 text-[15px]' > {cardMaterail ? cardMaterail[0]?.name : ''} </p>
                    <p className='text-slate-500 text-[15px]' >{textMaterail ? textMaterail[0]?.name : ''}   </p>
                </>
            }
        </div>
    )
}

export default SelectCardName