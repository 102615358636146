// import { ExclamationCircleFilled } from '@ant-design/icons';
import { Avatar, Button, Drawer } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { DeactiveContactCard, DeleteContactCard } from '../../../../api/ContactCardRequest';

// const { confirm } = Modal;
const defaultProfileImage = 'https://zeeqr-files.s3.ap-south-1.amazonaws.com/assets/defaultProfile.jpg'

function MaterialDrawer({ onClose, viewId, open }) {

    const [drawerData, setDrawerData] = useState({});
    // const [cardStatus, setCardStatus] = useState("");
    // // const [loader, setLoader] = useState(true)
    // const [statuslLoder, setStatuslLoder] = useState(false)
    // const [delLoder, setDelLoder] = useState(false)

    const navigate = useNavigate();
    const location = useLocation()

    // console.log(drawerData, 'drrrrrrrrrrr');

    useEffect(() => {
        const singleCards = async () => {
            try {

                setDrawerData(viewId);
                // setCardStatus(viewId.status)
                // setLoader(false)
            } catch (error) {
                console.log(error);
            }
        };
        singleCards();
    }, [viewId]);





    return (
        <div>

            <Drawer
                width={700}
                placement="right"
                closable={false}
                onClose={onClose}
                open={open}

            >
                <div>
                    <div className="flex justify-between">
                        <p
                            className="site-description-item-profile-p text-lg font-semibold"
                            style={{
                                marginBottom: 24,
                            }}
                        >
                            Materials Details
                        </p>

                    </div>

                    {drawerData?.CustomMaterial ?
                        <ul>
                            <h1 className='font-semibold text-xl underline my-3' >Materials Base Details </h1>
                            {drawerData?.CustomMaterial.map((data) => (
                                <li key={data} className='flex gap-2'>
                                    <p className='capitalize font-bold text-lg'>{data.key} - </p>
                                    <div>
                                        {Object.keys(data?.price).map(key => (
                                            <li key={key} className='flex gap-2'>
                                                <p className='capitalize font-medium text-md'>{key}  </p> :
                                                <p>  {data?.price[key]} AED</p>
                                            </li>
                                        ))}
                                    </div>
                                </li>
                            ))}
                        </ul>
                        : ""
                    }

                    {drawerData?.CustomCardColor ?
                        <ul>
                            <h1 className='font-semibold text-xl underline mt-5'>Materials Custom Card Color</h1>
                            {Object.keys(drawerData?.CustomCardColor).map(key => (
                                <div key={key} className='flex gap-5 my-2'>
                                    <p className='capitalize font-bold text-lg'>{key} :  </p>
                                    <p>
                                        {drawerData?.CustomCardColor[key]?.map((item, index) => (
                                            <div className='mb-3'>
                                                <p className='capitalize font-semibold '>{index + 1}) {item?.name}</p>
                                                <p>Key : {item?.key} </p>
                                                <p>Value : {item?.value}</p>
                                                <p>Color : {item?.color}</p>
                                                {item?.images ?
                                                    <div className='flex gap-3'>
                                                        <p>images :</p>
                                                        {Object.keys(item?.images).map(key => (
                                                            <li key={key} className=''>
                                                                <p className='capitalize font-medium mim-w-[150px]'>{key}  </p>
                                                                <img className='w-24' src={item?.images[key]} alt="" srcset="" />
                                                            </li>
                                                        ))}
                                                    </div>
                                                    : ''}
                                            </div>
                                        ))}
                                    </p>
                                </div>
                            ))}
                        </ul>
                        : ""
                    }

                    {drawerData?.CustomTextColor ?
                        <ul>
                            <h1 className='font-semibold text-xl underline mt-5'>Materials Custom Text Color</h1>
                            {Object.keys(drawerData?.CustomTextColor).map(key => (
                                <div key={key} className='flex gap-5 my-2'>
                                    <p className='capitalize font-bold text-lg'>{key} :  </p>
                                    <p>
                                        {drawerData?.CustomTextColor[key]?.map((item, index) => (
                                            <div className='mb-3'>
                                                <p className='capitalize font-semibold mim-w-[150px]'>{index + 1}) {item?.name}</p>
                                                <p>Key: {item?.key} </p>
                                                <p>Value: {item?.value}</p>
                                                <p>Color : {item?.color}</p>
                                                {item?.images ?
                                                    <div className='flex gap-3'>
                                                        <p>images :</p>
                                                        {Object.keys(item?.images).map(key => (
                                                            <li key={key} className=''>
                                                                <p className='capitalize font-medium mim-w-[150px]'>{key}  </p>
                                                                <img className='w-24' src={item?.images[key]} alt="" srcset="" />
                                                            </li>
                                                        ))}
                                                    </div>
                                                    : ''}
                                            </div>
                                        ))}
                                    </p>
                                </div>
                            ))}
                        </ul>
                        : ""
                    }
                </div>

            </Drawer>
            {/* <ToastContainer /> */}

        </div>
    )
}

export default MaterialDrawer