import { Button, Col,  Form, Input, Modal, Row, Tooltip, Typography,  } from 'antd';
import React, {useEffect, useState } from 'react'
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import {  PlusCircleOutlined,  } from '@ant-design/icons';
// import { createProduct, editProduct, getMaterialList } from '../../../api/AdminRequest';
import { useLocation } from 'react-router-dom';
// import ImgCrop from "antd-img-crop";
// import HandleImageUpload from '../../helper/ImageCompress';
import AddMaterial from './AddMaterial';
import AddMaterialCard from './AddMaterialCard';

const { Title } = Typography;
// let index = 0;

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    // wrapperCol: {
    //   xs: {
    //     span: 24,
    //   },
    //   sm: {
    //     span: 16,
    //   },
    // },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

function CreateMaterials({ edit }) {
    const [form] = Form.useForm();
    // const navigate = useNavigate();
    const { state } = useLocation();
    // console.log(state, '************************'); 


    const onFinish = async (values) => {

    }

    const initialData = {
        CustomCardColor: [
            {
                key: "",
                name: "",
                value: "",
                images: {
                    reviewQR: "",
                    BusniessQR: ""
                },
                color: ""
            },
        ]
    }

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editCardColor, setEditCardColor] = useState(false)
    const [change, setChange] = useState('')
    const [cardColorDetails, setCardColorDetails] = useState()

    useEffect(()=>{

    },[change])


    const addNewCardColor = () => {
        setIsModalOpen(true);
    };
    const handleCancelModal = () => {
        setIsModalOpen(false);
    };

    // const handleEdit = (id) => {
    //     // navigate("/edit-products", { state: id });
    //     setIsModalOpen(true);
    //     setEditCardColor(true)
    //     setCardColorDetails(id)
    // }

    // Function to add a new item to the array dynamically
    const addNewItem = (colorKey, newItem) => {
        const updatedData = { ...initialData };

        if (updatedData.CustomCardColor[colorKey]) {
            updatedData.CustomCardColor[colorKey].push(newItem);
        } else {
            // If the colorKey does not exist, create a new array with the newItem
            updatedData.CustomCardColor[colorKey] = [newItem];
        }
        setCardColorDetails(updatedData)
        return updatedData;
    };

    console.log(cardColorDetails,'details');

    return (
        <div>
            <Row gutter={16}>
                <Col span={12}>
                    <Title level={2}>{edit ? 'Edit Material' : 'Create Material'}</Title>
                </Col>
            </Row>

            <div className='flex min-h-screen justify-center mt-14'>

                {/* <div> */}
                {/* <h1 className='text-center  text-xl font-bold'>Create Admin</h1> */}

                <Form
                    {...formItemLayout}
                    form={form}
                    name="register"
                    onFinish={onFinish}
                    initialValues={{
                        productName: state ? state?.productName : '',
                        cardName: state ? state?.cardName : '',
                        price: state ? state?.price : '',
                        description: state ? state?.cardDescription : ''
                    }}
                    style={{
                        // maxWidth: 600,
                        width: 600
                    }}
                    scrollToFirstError
                >
                    <div>
                        <label htmlFor="" className='text-xl font-semibold'>Name </label>
                        <Form.Item
                            name="name"
                            // label="Name "
                            className='mb-2'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your name!',
                                    whitespace: true,
                                },
                            ]}
                        >

                            <Input placeholder='Name' className='py-2' />
                        </Form.Item>
                    </div>

                    <AddMaterial />

                    <div className='flex gap-5'>

                        <h1 className='font-bold text-xl my-4'>Materials Card Color</h1>
                        <button
                            type="button"
                            className=""
                            onClick={() => addNewCardColor()}
                        >
                            <Tooltip placement="top" title="Add More">
                                <PlusCircleOutlined
                                    style={{ fontSize: "20px", color: "green" }}
                                />
                            </Tooltip>
                        </button>
                    </div>
                    

                    <Modal open={isModalOpen} onCancel={handleCancelModal} closable={false} maskClosable={false} destroyOnClose={false} footer={false}

                    >
                        <AddMaterialCard edit={editCardColor} setEdit={setEditCardColor} handleCancelModal={handleCancelModal} cardColorDetails={cardColorDetails} setChange={setChange} addNewItem={addNewItem} />
                    </Modal>




                    <Form.Item {...tailFormItemLayout} className='flex justify-center  mr-14'>
                        <Button className='bg-blue-800 w-32  my-5 ' type="primary" htmlType="submit">
                            {edit ? 'Save' : ' Register'}
                        </Button>
                    </Form.Item>
                </Form>
                {/* </div> */}
                {/* <ToastContainer /> */}
            </div>
        </div>
    )
}

export default CreateMaterials