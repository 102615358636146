import { ExclamationCircleFilled, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Input, Modal, Row, Select, Space, Table, Tabs, Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words';
import { ChangeDeliveryStatus, getOrders,  } from '../../api/AdminRequest';
import OrderDrawer from '../drawer/OrderDrawer';
const { Title } = Typography;
const { confirm } = Modal;


function Orders() {

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [adminOrders, setAdminOrders] = useState([])
    const [userOrders, setUserOrders] = useState([])

    const [loader, setLoader] = useState(true)
    const [change, setChange] = useState('')
    const [open, setOpen] = useState(false);
    const [viewId, setViewID] = useState('')
    const [deliveryStatusLoader, setDeliveryStatusLoader] = useState('')



    useEffect(() => {
        const getAdmins = async () => {
            try {
                const { data } = await getOrders()
                // console.log(data, '12355');
                setAdminOrders(data.adminOrders)
                setUserOrders(data.userOrders)
                setLoader(false)
            } catch (error) {
                console.log(error.message);
            }
        }
        getAdmins()
    }, [change])

    const changeStatus = async (id,value) => {
        try {
            setDeliveryStatusLoader(id)
            const { data } = await ChangeDeliveryStatus(id,value)
            // console.log(data);
            if (data.success) {
                
                setChange(Date.now())
                setDeliveryStatusLoader('')
            }
        } catch (error) {
            console.log(error.message);
        }
    }

   

    const showDrawer = async (ID) => {
        setViewID(ID)
        setOpen(true);

    };
    const onClose = () => {
        setOpen(false);
    };

    const handleStatus = async (id,value) => {
       
        confirm({
            title: `Are you sure to change status to ${value}`,
            icon: <ExclamationCircleFilled />,
            // content: `${admin ? 'if you delete the card the user also get deleted.' : ' }` ,
            // content: `if you delete the card, the get deleted.`,
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                // console.log(`selected ${value}`);
                // console.log(`selectedID ${id}`);
                changeStatus(id,value)
            },
            onCancel() {
                console.log("Cancel");
                // setChange(Date.now())
            },
        });
    }

   

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        className='bg-blue-800'
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>

                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            handleSearch(selectedKeys, confirm)
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    const columns = [
        {

            title: 'No.',
            dataIndex: '_id',
            key: 'index',
            // render: (text, record, index) => index + 1,
            render: (text, record, index) => (userOrders.indexOf(record) + 1),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name'),
        },

        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ...getColumnSearchProps('email'),
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            ...getColumnSearchProps('phone'),
        },
        {
            title: 'Card Type',
            dataIndex: 'cardType',
            key: 'cardType',
            ...getColumnSearchProps('cardType'),
        },
        // {
        //     title: 'Status',
        //     dataIndex: 'status',
        //     key: 'status',
        //     width: '25%',
        //     ...getColumnSearchProps('status'),
        // },
        {
            title: 'Status',
            dataIndex: 'status._id',
            key: '_id',
            render: (status, _id) => {
                return (
                    <div className='flex gap-2'>
                        {/* <Button className='w-24 bg-slate-300 hover:bg-slate-200' onClick={() => handleStatus(_id)} >{_id.status}</Button> */}
                        <Select
                            value={_id.status}
                            style={{
                                width: 120,
                            }}
                            onChange={(value)=>handleStatus(_id._id,value)}
                            loading={deliveryStatusLoader === _id._id ? true : false}
                            // onClick={(e)=>handleStatus()}
                            options={[
                                {
                                    value: 'Processing',
                                    label: 'Processing',
                                      disabled:_id.status === 'Processing' ? false : true,
                                },
                                {
                                    value: 'Shipping',
                                    label: 'Shipping',
                                    disabled:_id.status === 'Processing' ||  _id.status === 'Shipping' ? false : true,
                                },

                                {
                                    value: 'Shipped',
                                    label: 'Shipped',
                                    disabled:_id.status === 'Processing' ||  _id.status === 'Shipping'  ||  _id.status === 'Shipped' ? false : true,
                                },
                                {
                                    value: 'OutForDelivery',
                                    label: 'Out for Delivery',
                                    disabled:_id.status === 'Processing' ||  _id.status === 'Shipping'  ||  _id.status === 'Shipped' ||  _id.status === 'OutForDelivery' ? false : true,
                                },
                                {
                                    value: 'Delivered',
                                    label: 'Delivered',
                                    disabled:_id.status === 'Processing' ||  _id.status === 'Shipping'  ||  _id.status === 'Shipped' ||  _id.status === 'OutForDelivery'  ||  _id.status === 'Delivered' ? false : true,

                                },
                            ]}
                        />
                    </div>
                )
            }

        },
        {
            title: 'Payment Status',
            dataIndex: 'status._id',
            key: '_id',
            render: (status, _id) => {
                return (
                    <div>
                        {
                            _id?.payment?.paymentStatus === 'pending' ?

                                <p className='bg-red-500 text-white text-center rounded-full px-2' >{_id?.payment?.paymentStatus}</p> :
                                _id?.payment?.paymentStatus === 'STARTED' ?
                                    <p className='bg-orange-500 p-1 text-white text-center text-xs rounded-full' >{_id?.payment?.paymentStatus}</p> :
                                    _id?.payment?.paymentStatus === 'PURCHASED' ?
                                        <p className='bg-green-500 p-1 px-1 text-white text-center text-xs rounded-full' >{_id?.payment?.paymentStatus}</p> :
                                        ''
                        }

                    </div>
                )
            }

        },
        {
            title: 'Action',
            dataIndex: 'status._id',
            key: '_id',
            render: (status, _id) => {
                return (
                    <Button className='w-24 bg-slate-300 hover:bg-slate-200' onClick={() => showDrawer(_id)} >View</Button>
                )
            }

        },


        // {
        //     title: 'View',
        //     dataIndex: '_id',
        //     key: '_id',
        //     render: (_id) => <Button className='w-24 bg-slate-300 hover:bg-slate-200' onClick={() => handleRestore(_id)} >Restore</Button>

        // },
        // {
        //     title: 'Acion',
        //     dataIndex: 'status._id',
        //     key: '_id',
        //     render: (status, _id) => {
        //         return (
        //             userOrders ?
        //                 (_id.adminID ?
        //                     <p>{_id.adminID.name}</p>
        //                     // ''
        //                     :
        //                     <Button className='w-24 bg-slate-300 hover:bg-slate-200' onClick={() => handleRestore(_id)} >Restore</Button>
        //                 )
        //                 :
        //                 <Button className='w-24 bg-slate-300 hover:bg-slate-200' onClick={() => handleRestore(_id)} >Restore</Button>
        //         )
        //     }

        // },

    ];

    const onChange = (key) => {
        console.log(key);
    };
    const items = [
        {
            key: '1',
            label: `Admins Order`,
            children: [
                <div className='flex justify-center '>

                    <div className='w-full mt-5 '>
                        <Table columns={columns} loading={loader} dataSource={adminOrders} pagination={adminOrders.length > 10 ? true : false} />
                        <OrderDrawer viewId={viewId} setChange={setChange} users={'users'} open={open} onClose={onClose} />

                    </div>
                </div>
            ],
        },
        {
            key: '2',
            label: `Users Order`,
            children: [
                <div className='flex justify-center '>

                    <div className='w-full mt-5 '>
                        <Table columns={columns} loading={loader} dataSource={userOrders} pagination={userOrders.length > 10 ? true : false} />
                        <OrderDrawer viewId={viewId} setChange={setChange} users={'users'} open={open} onClose={onClose} />

                    </div>
                </div>
            ],
        },

    ];

    return (
        <>
            <Row gutter={16}>
                <Col span={12}>
                    <Title level={2}>Orders </Title>
                </Col>
            </Row>

            <Tabs defaultActiveKey="2" items={items} onChange={onChange} />
            {/* <div className='flex justify-center'>
                <div className='w-full mt-5'>
                    <h1 className='text-lg font-bold'>Admins</h1>
                    <Table columns={columns} loading={loader} dataSource={adminTrash} />
                    <h1 className='mt-2 text-lg font-bold'>Users</h1>
                    <Table columns={columns} loading={loader} dataSource={userTrash} />
                    <DrawerBox viewId={viewId} setChange={setChange} open={open} onClose={onClose} />

                </div>
            </div> */}
        </>
    )
}

export default Orders