import React from 'react'
import Sidebar from '../../components/sidebar/Sidebar';
import ActiveUsers from '../../components/user/ActiveUsers';
import { Layout } from 'antd';
const { Content } = Layout;
function ActiveUsersPage() {
  return (
    <div>
      <Layout>
        <div className='fixed'>
          <Sidebar />
        </div>
        <Layout style={{ marginLeft: 200, }}  >
          <Content style={{ margin: '24px 16px', padding: 24, minHeight: 280, background: 'white', }} >
            <ActiveUsers />
          </Content>
        </Layout>
      </Layout>
    </div>
  )
}

export default ActiveUsersPage