import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Input, Row, Space, Table, Typography } from 'antd';
import Highlighter from 'react-highlight-words';
import { getVistorsList } from '../../api/AdminRequest';
import { SearchOutlined } from '@ant-design/icons';
import Moment from 'moment'

const { Title } = Typography;

function VisitorsList() {

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const [visitors, setVisitors] = useState('')
    const [loader, setLoader] = useState(true)


    useEffect(() => {
        const allvisitors = async () => {
            try {
                const { data } = await getVistorsList()
                // console.log(data, '12355');
                setVisitors(data.visitors)
                setLoader(false)
            } catch (error) {
                // console.log(error.message);
            }
        }
        allvisitors()
    }, [])

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        className='bg-blue-800'
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    {/* <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button> */}
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            handleSearch(selectedKeys, confirm)
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: 'No.',
            dataIndex: '_id',
            key: 'index',
            // render: (text, record, index) => index + 1,
            render: (text, record, index) => (visitors.indexOf(record) + 1),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ...getColumnSearchProps('email'),
        },

        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            ...getColumnSearchProps('phone'),
        },
        {
            title: 'Company Name',
            dataIndex: 'companyName',
            key: 'companyName',
            ...getColumnSearchProps('companyName'),
        },
        {
            title: 'Created Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (createdDate) => {

                return (
                    <>
                        <p>{Moment(createdDate).format("Do MMM YYYY, hh:mm a")}</p>
                    </>
                )
            }
        },


    ];

    return (
        <div>
            <Row gutter={16}>
                <Col span={12}>
                    <Title level={2}>Visitors Data </Title>
                </Col>

            </Row>

            <div className='flex justify-center'>
                <div className='w-full mt-5'>
                    <Table columns={columns} loading={loader} dataSource={visitors} pagination={visitors?.length > 10 ? true : false} />


                </div>
            </div>
        </div>
    )
}

export default VisitorsList