// import { ColorPicker } from 'antd';
import React, { useEffect, useState } from 'react'
import './MaterialButton.css'

function MaterialButton(props) {
    const { material, materialcolor, materialFavoriteColor, setColor, setFovouritecolor, setTextColor, productDetails } = props


    // const handlePickerText = (col) => {
    //     const color = "#" + col.toHex()
    //     // console.log(color,'777777////////////');
    //     setTextColor(color)
    // }
    // const handlePickerCard = (col) => {
    //     const color = "#" + col.toHex()
    //     // console.log(color,'777777////////////');
    //     setCardColor(color)
    // }

    const handleSelectMaterial = (obj) => {
        setColor(productDetails?.CustomCardColor?.[material][0]?.value)
    }
    const handleSelectMaterialColor = () => {
        setFovouritecolor(productDetails?.CustomTextColor?.[materialcolor][0]?.value)
        setTextColor(productDetails?.CustomTextColor?.[materialcolor][0]?.color)
    }
    const handleSelectMaterialFavoriteColor = (obj) => {
        const col = productDetails?.CustomTextColor?.[materialcolor].filter((data) => (data.value === obj.value))
        console.log(col, 'qwertyuiop');
        setTextColor(col[0].color)
    }
    const [changes, setChanges] = useState('')
    const [changes1, setChanges1] = useState('')
    const [chgState, setChgState] = useState(false)
    const [chgState1, setChgState1] = useState(false)
    const handleFnCall = () => {
        // console.log('handleFnCall');
        setChanges(Date.now)
        setChgState(true)
    }
    const handleFnCall1 = () => {
        // console.log('handleFnCall1');
        setChanges1(Date.now)
        setChgState1(true)
    }
    useEffect(() => {
        if (chgState) {

            handleSelectMaterial()
            handleFnCall1()
        }
    }, [changes])

    useEffect(() => {
        if (chgState1) {

            handleSelectMaterialColor()
        }
    }, [changes1])

    return (
        <div>
            {/* <p className='text-3xl font-medium mt-3 '>Customize your Card</p> */}
            <div className='flex flex-col w-100 '>
                {/* <div className='block sm:flex mt-3 pt-0'>
                    <p className='text-sm w-60 font-medium mt-3'>Select your Card Material -</p>
                    <div className='flex gap-2 w-[310px] md:w-[430px] flex-wrap'>
                        {productDetails?.CustomMaterial?.map((obj) => (
                                <p title={obj?.title} className={`${obj.key === material ? 'materialSelect' : 'material'}`} onClick={(e) => { setMaterial(obj.key); handleFnCall(); }} >{obj.key}</p>
                        ))}


                    </div>
                </div> */}
                <div className='block sm:flex mt-3 pt-0'>
                    <p className='text-sm w-60 font-medium mt-3' >Select your Card Color -</p>
                    <div className='flex  gap-2 w-[310px] md:w-[430px] flex-wrap'>

                        {productDetails?.CustomCardColor?.[material]?.map((obj) => (
                            <p className={`${obj.value === materialcolor ? 'materialSelect' : 'material'}`} onClick={(e) => { setColor(obj.value); handleFnCall1() }} >{obj.key}</p>

                        ))}
                        {/* {materialcolor === "custom" ?

                            <ColorPicker
                                presets={[
                                    {
                                        label: 'Recommended',
                                        colors: [
                                            '#000000',
                                            '#000000E0',
                                            '#000000A6',
                                            '#00000073',
                                            '#00000040',
                                            '#00000026',
                                            '#0000001A',
                                            '#00000012',
                                            '#0000000A',
                                            '#00000005',
                                            '#F5222D',
                                            '#FA8C16',
                                            '#FADB14',
                                            '#8BBB11',
                                            '#52C41A',
                                            '#13A8A8',
                                            '#1677FF',
                                            '#2F54EB',
                                            '#722ED1',
                                            '#EB2F96',
                                            '#F5222D4D',
                                            '#FA8C164D',
                                            '#FADB144D',
                                            '#8BBB114D',
                                            '#52C41A4D',
                                            '#13A8A84D',
                                            '#1677FF4D',
                                            '#2F54EB4D',
                                            '#722ED14D',
                                            '#EB2F964D',
                                        ],
                                    },
                                    // {
                                    //   label: 'Recent',
                                    //   colors: [],
                                    // },
                                ]}
                                value={cardColor ? cardColor : '#000000'}
                                onChange={handlePickerCard}
                                className='h-[40px] w-[40px]'
                                placement="bottomRight"
                            />
                            : ''
                        } */}

                    </div>

                </div>
                <div className='block sm:flex mt-3 pt-0'>
                    <p className='text-sm w-60 font-medium mt-3' >Select your Text Color -</p>
                    <div className='flex  gap-2 w-[310px] md:w-[430px] flex-wrap'>

                        {/* {materialcolor === "custom" ?
                            <ColorPicker
                                presets={[
                                    {
                                        label: 'Recommended',
                                        colors: [
                                            '#000000',
                                            '#000000E0',
                                            '#000000A6',
                                            '#00000073',
                                            '#00000040',
                                            '#00000026',
                                            '#0000001A',
                                            '#00000012',
                                            '#0000000A',
                                            '#00000005',
                                            '#F5222D',
                                            '#FA8C16',
                                            '#FADB14',
                                            '#8BBB11',
                                            '#52C41A',
                                            '#13A8A8',
                                            '#1677FF',
                                            '#2F54EB',
                                            '#722ED1',
                                            '#EB2F96',
                                            '#F5222D4D',
                                            '#FA8C164D',
                                            '#FADB144D',
                                            '#8BBB114D',
                                            '#52C41A4D',
                                            '#13A8A84D',
                                            '#1677FF4D',
                                            '#2F54EB4D',
                                            '#722ED14D',
                                            '#EB2F964D',
                                        ],
                                    },
                                    // {
                                    //   label: 'Recent',
                                    //   colors: [],
                                    // },
                                ]}
                                value={textColor ? textColor : '#ffffff'}
                                onChange={handlePickerText}
                                className='h-[40px] w-[40px]'
                                placement="bottomRight"
                            />
                            : */}
                            <>
                                {productDetails?.CustomTextColor?.[materialcolor]?.map((obj) => (
                                    <p className={`${obj.value === materialFavoriteColor ? 'materialSelect' : 'material'}`} onClick={(e) => { setFovouritecolor(obj.value); handleSelectMaterialFavoriteColor(obj) }} >{obj.key}</p>

                                ))}
                            </>
                        {/* } */}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default MaterialButton