import { Button, Col, Form, Input, Row, Select, Typography } from 'antd'
import React, { useState } from 'react'
// import { useRef } from 'react'
const { Title } = Typography;
const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    // wrapperCol: {
    //   xs: {
    //     span: 24,
    //   },
    //   sm: {
    //     span: 16,
    //   },
    // },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};
function AddMaterialCard({ edit, setEdit, handleCancelModal, cardColorDetails, setChange, addNewItem }) {

    const [form] = Form.useForm();
    const defaultMaterials = [
        {
            value: 'PVC_NC',
            label: 'PVC_NC',
        },
        {
            value: 'PVC',
            label: 'PVC',
        },
        {
            value: 'Wooden',
            label: 'Wooden',
        },
        {
            value: 'Metal',
            label: 'Metal',
        },
        {
            value: 'Luxury',
            label: 'Luxury',
        },
    ]

    const [material, setMaterial] = useState()



    const onFinish = async (values) => {
        // console.log(values, 'neww');
        // console.log(material, 'mattttt');

        await addNewItem(material, values);
    }

    const handleCancel = () => {
        // setFileList([])
        // // setFormData(initialValue)
        // form.resetFields()
        // setThemeType(null)
        // setError('')
        // setEdit(false)
        // setChange(Date.now())
        // // setTheme('')
        handleCancelModal()
    }

    const handleChangeMaterial = (value) => {
        // console.log(`selected ${value}`);
        setMaterial(value)

    };

    return (

        <div>
            <Row gutter={16}>
                <Col span={12}>
                    <Title level={4}>{edit ? 'Edit Material Card Color' : 'Add Material Card Color'}</Title>
                </Col>
            </Row>
            <Form
                {...formItemLayout}
                form={form}
                name="register"
                onFinish={onFinish}
                // initialValues={{
                //     name: edit ? theme?.name : '',
                //     value: edit ? theme?.value : '',

                // }}
                // style={{
                //     // maxWidth: 600,
                //     width: 450
                // }}
                scrollToFirstError
            >
                <div className="grid gap-5 grid-cols-2" >
                    <div>
                        <label htmlFor="" className='text-xl font-semibold'>Type</label>
                        <Select
                            // defaultValue="lucy"
                            placeholder='Select the theme type '
                            // value={themeType}
                            style={{
                                width: '100%',
                            }} f
                            // disabled={filterMaterialColor.length === 0 ? true : false}
                            size='large'
                            onChange={handleChangeMaterial}
                            options={defaultMaterials?.map((item) => ({
                                label: item.name,
                                value: item.value,
                            }))}
                        />
                        {/* <p className='text-red-500'>{error.type}</p> */}
                    </div>
                    {/* <div>
                        <label htmlFor="" className='text-xl font-semibold'>Image</label>
                        <ImgCrop rotationSlider aspectSlider showReset>
                            <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture-card"
                                fileList={fileList}
                                onChange={onChange}
                                onPreview={onPreview}
                            >
                                {fileList.length < 1 && "+ Upload"}
                            </Upload>
                        </ImgCrop>
                        <p className='text-red-500'>{error.image}</p>
                    </div> */}
                    <div>
                        <label htmlFor="" className='text-xl font-semibold'>Key</label>
                        <Form.Item
                            name="key"
                            // label="E-mail"
                            className='mb-2'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your key!',
                                },
                            ]}
                        >

                            <Input placeholder='Key Name' className='py-2' />
                        </Form.Item>
                    </div>
                    <div>
                        <label htmlFor="" className='text-xl font-semibold'>Name</label>
                        <Form.Item
                            name="name"
                            // label="E-mail"
                            className='mb-2'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Name!',
                                },
                            ]}
                        >

                            <Input placeholder='Card Color Name' className='py-2' />
                        </Form.Item>
                    </div>
                    <div>
                        <label htmlFor="" className='text-xl font-semibold'>Value</label>
                        <Form.Item
                            name="value"
                            // label="E-mail"
                            className='mb-2'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Value',
                                },
                            ]}
                        >

                            <Input placeholder='Card Color value' className='py-2' />
                        </Form.Item>
                    </div>
                    <div>
                        <label htmlFor="" className='text-xl font-semibold'>Color Code</label>
                        <Form.Item
                            name="color"
                            // label="E-mail"
                            className='mb-2'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Color',
                                },
                            ]}
                        >

                            <Input placeholder='Color Code' className='py-2' />
                        </Form.Item>
                    </div>


                </div>
                <Form.Item {...tailFormItemLayout} className='flex justify-center  mr-14'>
                    <div className='flex gap-3' >

                        <Button key="back" className='w-32 my-5' onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button className='bg-blue-800 w-32  my-5 ' type="primary" htmlType="submit">
                            {edit ? 'Save' : ' Add'}
                        </Button>
                    </div>
                </Form.Item>
            </Form>
            {/* <ToastContainer /> */}
        </div>

    )
}

export default AddMaterialCard