import React, { useEffect, useState } from "react";
import { Drawer, Spin, } from "antd";
import { ToastContainer, } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { useNavigate } from "react-router-dom";
// import { ExclamationCircleFilled } from "@ant-design/icons";
// import { cancelOrder } from "../../../api/AdminRequest";
// import SelectZeeqrCards from "../selectCard/SelectZeeqrCards";
import rotateimg from '../../assests/rotate.png';
import Moment from 'moment'
import SelectBusinessQRCard from "../services/BusinessQRCard/SelectBusinessQRCard";
import SelectCardName from "../services/materailsDetail/SelectCardName";
import MaterialButton from "../services/materailsDetail/MaterialButton";
import SelectReviewCard from "../services/reviewQRCard/SelectReviewCard";

// const { confirm } = Modal;

function ProductDrawer({ onClose, viewId, open, setChanges }) {
  // const navigate = useNavigate();
  const [drawerData, setDrawerData] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [loader, setLoader] = useState(true)
  // const [rotate, setRotate] = useState(false)
  // const [cards, setCards] = useState([])
  const [rotate, setRotate] = useState(false)


  const [material, setMaterial] = useState('')
  const [materialcolor, setColor] = useState('')
  const [materialFavoriteColor, setFovouritecolor] = useState('')
  const [textColor, setTextColor] = useState('');
  const [cardColor, setCardColor] = useState('');
  const [productDetails, setProductDetails] = useState('')
  // console.log(productDetails, 'jjdjdim');

  useEffect(() => {
    const singleProduct = async () => {
      try {

        // const { data } = await getSigleCardData(viewId);
        // console.log(viewId);
        // if (data.success) {
        //   setDrawerData(data.card);
        //   setCardStatus(data.card.status)
        //   setLoader(false)
        // }
        setDrawerData(viewId);
        setOrderStatus(viewId.status)
        // setCards(viewId?.card)

        setMaterial(viewId?.materials?.material)
        setColor(viewId?.materials?.materialcolor)
        setFovouritecolor(viewId?.materials?.materialFavoriteColor)
        setProductDetails(viewId?.materialID)
        setLoader(false)
      } catch (error) {
        console.log(error);
      }
    };
    singleProduct();
  }, [viewId]);


  const Details = [
    { name: 'Name', data: drawerData.productName },
    { name: 'Status', data: orderStatus },
    { name: 'Type', data: drawerData.productType },
    { name: 'Price', data: drawerData.price },
    { name: 'Description', data: drawerData.cardDescription },
    { name: 'Card Name', data: drawerData.cardName },
    { name: 'Created Date', data: Moment(drawerData.createdDate).format("Do MMM YYYY") },
    { name: 'State', data: drawerData.state },
    { name: 'Town/City', data: drawerData.towncity },
    { name: 'Land Mark', data: drawerData.landmark },
    { name: 'Pincode', data: drawerData.postcode },
    { name: 'Order Note', data: drawerData.ordernote },
  ]


  const Rotation = () => {
    setRotate(!rotate)
  }

  return (
    <div>
      <Drawer
        width={640}
        placement="right"
        closable={false}
        onClose={onClose}
        open={open}
      >
        <Spin spinning={loader}>
          <div className="flex justify-between">
            <p
              className="site-description-item-profile-p text-xl font-bold"
              style={{
                marginBottom: 24,
              }}
            >
              Product  Details
            </p>
            {/* {orderStatus === 'cancelled' ? '' :
              <div>
                {orderStatus === 'processing' ?
                  <Button
                    className="text-white bg-red-600 hover:bg-red-700 w-28"
                    onClick={showDeleteConfirm}
                  // loading={delLoder}
                  >
                    Cancel
                  </Button>
                  : ''
                }
                <Button
                  className="text-white bg-slate-600 hover:bg-slate-700 ml-2 w-28 "
                  onClick={handleTrack}
                // loading={statuslLoder}
                >
                  Track
                </Button>
              </div>
            } */}

          </div>


          <div className='grid grid-cols-2 gap-3 mt-3'>

            <div className="w-[350px] ml-2" >
              {/* <SelectReviewCard rotate={rotate} material={material} materialcolor={materialcolor} materialFavoriteColor={materialFavoriteColor} textColor={textColor} cardColor={cardColor} productDetails={productDetails} reviewType={materails?.reviewType}  /> */}
              {drawerData.productType === 'reviewQR' ?
                <div className="flex justify-center">
                  <div className='h-[350px]  w-[222px] flex justify-center'>
                    <SelectReviewCard rotate={rotate} material={material} materialcolor={materialcolor} materialFavoriteColor={materialFavoriteColor} textColor={textColor} cardColor={cardColor} productDetails={productDetails} />
                  </div>
                </div>
                : drawerData.productType === 'BusniessQR' ?
                  <div className='w-[300px] sm:w-[350px]'>
                    <SelectBusinessQRCard rotate={rotate} material={material} materialcolor={materialcolor} materialFavoriteColor={materialFavoriteColor} textColor={textColor} cardColor={cardColor} productDetails={productDetails} />
                  </div>
                  :
                  <div className="h-52 w-32 overflow-hidden rounded-xl shadow-xl border-2">
                    <img src={drawerData?.imageUrl} alt="" srcset="" />
                  </div>
              }

              {drawerData.productType === 'reviewQR' || drawerData.productType === 'BusniessQR' ?
                <div className="flex justify-center item-center mt-5 mb-5">
                  <img className='cursor-pointer' src={rotateimg} onClick={Rotation} alt=''></img>
                </div>
                : ''
              }
            </div>
            <div className=" flex flex-col w-60  justify-center ml-20">

              <p className='text-xl text-slate-600   font-bold '>{material}</p>
              <SelectCardName material={material} materialcolor={materialcolor} materialFavoriteColor={materialFavoriteColor} productDetails={productDetails} />

            </div>


          </div>
          {drawerData.productType === 'reviewQR' || drawerData.productType === 'BusniessQR' ?
            <MaterialButton key={productDetails?._id} material={material} setMaterial={setMaterial} materialcolor={materialcolor} setColor={setColor} materialFavoriteColor={materialFavoriteColor} setFovouritecolor={setFovouritecolor} textColor={textColor} setTextColor={setTextColor} cardColor={cardColor} setCardColor={setCardColor} productDetails={productDetails} />
            : ''
          }

          <div className="grid grid-cols-2 mt-5">
            {Details.map((obj) => {
              return (
                <div className="mt-3">
                  {obj.data ?
                    <div className="flex">
                      <p className="font-medium mr-2 ">{obj.name}: </p>
                      <p  > {obj.data}</p>
                    </div>
                    : ''
                  }
                </div>
              )
            })}
          </div>
          {drawerData.productType === 'reviewQR' || drawerData.productType === 'BusniessQR' ?
            <>
              <div>
                <h1 className="text-lg font-bold mt-5 mb-2">Default Materials</h1>
                <p><span className="font-medium mr-2 ">Material : </span>{drawerData?.materials?.material}</p>
                <p><span className="font-medium mr-2 ">Material Color :</span>{drawerData?.materials?.materialcolor}</p>
                <p><span className="font-medium mr-2 ">Material Favorite Color :</span>{drawerData?.materials?.materialFavoriteColor}</p>
                <p><span className="font-medium mr-2 ">Text Color :</span>{drawerData?.materials?.textColor}</p>
              </div>

              <div>
                <h1 className="text-lg font-bold mt-5 mb-2">Buttons</h1>
                <div className="flex justify-start space-x-4 mt-5">
                  {drawerData?.buttons?.map((button, index) => (
                    <div
                      key={index}
                      className={`w-8 h-8 border-2  hover:border-[#9E740C] flex items-center justify-center cursor-pointer`}

                    >
                      <div
                        style={{ display: 'inline-block', borderTop: `20px solid ${button?.color}`, borderRight: `20px solid ${button?.textColor}`, cursor: 'pointer' }}
                      ></div>
                    </div>
                  ))}
                </div>
              </div>
            </>
            : ""
          }

        </Spin>
        <ToastContainer />
      </Drawer>

    </div>
  );
}

export default ProductDrawer;
