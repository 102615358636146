import React, { useEffect } from 'react'
import {  PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Space, Table, Tabs, Typography } from 'antd';
import { useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { viewUsers } from '../../api/AdminRequest';
// import { Modal } from 'antd';
// import DrawerBox from '../drawer/Drawer';
// import CardDrawer from '../admin/cardsDrawer/CardDrawer';
import UserDrawer from '../drawer/UserDrawer';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// const { confirm } = Modal;
const { Title } = Typography;

function ActiveUsers() {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [users, setUsers] = useState([])
    const [adminUsers, setAdminUsers] = useState([])
    const [normalUsers, setNormalUsers] = useState([])

    const [change, setChange] = useState('')
    const [viewId, setViewID] = useState('')
    const [open, setOpen] = useState(false);
    const [loader, setLoader] = useState(true)
    // const [cardViewId,setCardViewId] = useState('')
    // const [openCard, setOpenCard] = useState(false);
    const [tabIndex, setTabIndex] = useState('')

    const navigate = useNavigate()
    const location = useLocation()



    const showDrawer = async (ID) => {
        setViewID(ID)
        setOpen(true);

    };
    const onClose = () => {
        setOpen(false);
    };

    // const showCardDrawer = async (ID) => {
    //     // console.log(ID,'iiiiiiiiiiiiiiiiidddddddddddd');
    //     setCardViewId(ID)
    //     setOpenCard(true);

    // };

    // const onCloseCard = () => {
    //     setOpenCard(false);
    // };

    const showCards = async (ID) => {
        // console.log(ID,'iiiiiiiiiiiiiiiiidddddddddddd');
        navigate("/cards", { state: { details: ID, status: 'user', from: location } })

    };


    useEffect(() => {
        const getUsers = async () => {
            try {
                const { data } = await viewUsers()
                // console.log(data, '12355');
                setUsers(data.users)
                const adUser = data.users.filter((data) => (

                    data?.adminID
                ))
                // console.log(adUser,'adminuserss');
                setAdminUsers(adUser)
                const norUser = data.users.filter((data)=>(
                   !data.adminID
                ))
                // console.log(norUser,'normal uusserss');
                setNormalUsers(norUser)
                setLoader(false)
            } catch (error) {
                // console.log(error.message);
            }
        }
        getUsers()
    }, [change])
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        className='bg-blue-800'
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    {/* <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button> */}
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            handleSearch(selectedKeys, confirm)
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    const columns = [
        {

            title: 'No.',
            dataIndex: '_id',
            key: 'index',
            width: '10%',
            // render: (text, record, index) => index + 1,
            render: (text, record, index) => (tabIndex=== '2' ? adminUsers.indexOf(record) + 1: tabIndex === '3' ?  normalUsers.indexOf(record) + 1 : users.indexOf(record) + 1 ),
            
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '30%',
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Admin Name',
            dataIndex: 'adminID',
            key: 'adminID',
            width: '20%',
            render: (adminID) => {
                return (
                    <div>
                        <p>{adminID ? adminID.name : 'nil'}</p>
                    </div>
                )
            }
        },

        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '30%',
            ...getColumnSearchProps('email'),
        },
        
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '20%',
            // ...getColumnSearchProps('status'),
            render: (status) => { return status==='active' ?  <p className=' text-center'>Active</p>  : <p className='bg-red-500 text-white text-center rounded-xl'>Blocked</p>  },
        },
        // {
        //     title: 'Profile',
        //     dataIndex: '_id',
        //     key: 'address',
        //     render: (_id) => <Button className='w-24 bg-slate-300 hover:bg-slate-200' onClick={() => showDrawer(_id)} >View</Button>

        // },
        {
            title: 'View',
            dataIndex: 'status._id',
            key: '_id',
            render: (status, _id) => {
                return (
                    <Button className='w-24 bg-slate-300 hover:bg-slate-200' onClick={() => showDrawer(_id)} >View</Button>
                )
            }

        },
        // {
        //     title: 'Cards',
        //     dataIndex: '_id',
        //     key: '_id',
        //     render: (_id) => <Button className='w-24 bg-slate-300 hover:bg-slate-200' onClick={() => showCardDrawer(_id)} >Cards</Button> 

        // },
        {
            title: 'Cards',
            dataIndex: 'status._id',
            key: '_id',
            render: (status, _id) => <Button className='w-24 bg-slate-300 hover:bg-slate-200' onClick={() => showCards(_id)} >Cards</Button>

        },
       
    ];

    const onChange = (key) => {
        // console.log(key);
        setTabIndex(key)
    };
    const items = [
        {
            key: '1',
            label: `All Users`,
            children: [
                <div className='flex justify-center '>

                    <div className='w-full mt-5 '>
                        <Table columns={columns} loading={loader} dataSource={users} pagination={users.length > 10 ? true : false} />
                        <UserDrawer viewId={viewId} setChange={setChange} users={'users'} open={open} onClose={onClose} />
                        {/* <CardDrawer  cardViewId={cardViewId} openCard={openCard} onCloseCard={onCloseCard} users={'users'} /> */}
                    </div>
                </div>
            ],
        },
        {
            key: '2',
            label: `Admin User`,
            children: [
                <div className='flex justify-center '>

                    <div className='w-full mt-5 '>
                        <Table columns={columns} loading={loader} dataSource={adminUsers} pagination={adminUsers.length > 10 ? true : false} />
                        <UserDrawer viewId={viewId}  users={'users'} open={open} onClose={onClose} />
                        {/* <CardDrawer  cardViewId={cardViewId} openCard={openCard} onCloseCard={onCloseCard} users={'users'} /> */}
                    </div>
                </div>
            ],
        },
        {
            key: '3',
            label: `Normal User`,
            children: [
                <div className='flex justify-center '>

                    <div className='w-full mt-5 '>
                        <Table columns={columns} loading={loader} dataSource={normalUsers} pagination={normalUsers.length > 10 ? true : false} />
                        <UserDrawer viewId={viewId} setChange={setChange} users={'users'} open={open} onClose={onClose} />
                        {/* <CardDrawer  cardViewId={cardViewId} openCard={openCard} onCloseCard={onCloseCard} users={'users'} /> */}

                    </div>
                </div>
            ],
        },
    ];
    return (
        <>
            <Row gutter={16}>
                <Col span={12}>
                    <Title level={2}>Users Profile</Title>
                </Col>
                <Col span={12} className='text-right'>
                    <Link className='add-button rounded-lg ml-auto' to="/create-user"><PlusOutlined />Add user</Link>
                </Col>
            </Row>

            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
            {/* <div className='flex justify-center '>

                <div className='w-full mt-5 '>
                    <Table columns={columns} loading={loader} dataSource={users} />
                    <DrawerBox viewId={viewId} setDeletes={setDeletes} open={open} onClose={onClose} />
                </div>
            </div> */}
        </>
    )
}

export default ActiveUsers