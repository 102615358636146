import imageCompression from "browser-image-compression";



async function HandleImageUpload(event) {
    const imageFile = event[0]?.originFileObj


    const options = {
        maxSizeMB: 0.5,
        fileType: "image/webp",
        maxWidthOrHeight: 1920
    };
    try {
        const compressedFile = await imageCompression(imageFile, options);
        // console.log(compressedFile, "1234567890");
        // console.log(compressedFile.size / 1024 / 1024);

        return compressedFile
        // var reader = new FileReader();
        // reader.readAsDataURL(compressedFile);
        // reader.onloadend = function () {
        //     var base64data = reader.result;
        //     console.log(base64data, "qwerty");
        // };
    } catch (error) {
        // console.log(error);
    }
  
}

export default HandleImageUpload