// import { ExclamationCircleFilled } from '@ant-design/icons';
import { Avatar, Button, Drawer } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { DeactiveContactCard, DeleteContactCard } from '../../../../api/ContactCardRequest';

// const { confirm } = Modal;
const defaultProfileImage = 'https://zeeqr-files.s3.ap-south-1.amazonaws.com/assets/defaultProfile.jpg'

function ContactCardDrawer({ onClose, viewId, open, setChange, admin }) {

    const [drawerData, setDrawerData] = useState({});
    // const [cardStatus, setCardStatus] = useState("");
    // // const [loader, setLoader] = useState(true)
    // const [statuslLoder, setStatuslLoder] = useState(false)
    // const [delLoder, setDelLoder] = useState(false)

    const navigate = useNavigate();
    const location = useLocation()

    // console.log(drawerData, 'drrrrrrrrrrr');

    useEffect(() => {
        const singleCards = async () => {
            try {

                setDrawerData(viewId);
                // setCardStatus(viewId.status)
                // setLoader(false)


            } catch (error) {
                console.log(error);
            }
        };
        singleCards();
    }, [viewId]);


    const handleSubmit = () => {
        // dispatch(editData(drawerData));
        const id = drawerData._id
        // if (admin) {

        //     navigate("/admin-editCard", { state: { id } });
        // } else {
        navigate("/contactCard/update-card", { state: { id } });
        // }
    };

    // const handleDeactive = async () => {

    //     try {
    //         setStatuslLoder(true)
    //         const { data } = await DeactiveContactCard(drawerData._id);
    //         // console.log(data);
    //         if (data.deactive) {
    //             // console.log("sucess delete");
    //             setCardStatus("deactive")
    //             setStatuslLoder(false)
    //             setChange(Date.now())
    //             onClose()
    //         }
    //         if (data.active) {
    //             setCardStatus("active")
    //             setStatuslLoder(false)
    //             setChange(Date.now())
    //             onClose()
    //         }
    //     } catch (error) {
    //         console.log(error);
    //         toast(error.response.data.message);
    //     }
    // };

    // const handleDelete = async () => {
    //     try {
    //         setDelLoder(true)
    //         const { data } = await DeleteContactCard(drawerData._id);
    //         // console.log(data);
    //         if (data.success) {
    //             // console.log("sucess delete");
    //             setChange(Date.now())
    //             onClose()
    //             setDelLoder(false)
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    // const showDeactiveConfirm = () => {
    //     confirm({

    //         title: `${cardStatus === 'active' ? 'Do you Want to Deactive the Card ' : 'Do you Want to Active the Card'}`,
    //         icon: <ExclamationCircleFilled />,
    //         content: `${cardStatus === 'active' ? 'Profile will be deactivated ' : 'Profile will be activated'}`,
    //         okText: "Yes",
    //         okType: "danger",
    //         cancelText: "No",
    //         onOk() {
    //             handleDeactive();
    //             // { cardStatus === "active" ? setCardStatus("deactive") : setCardStatus("active") }
    //         },
    //         onCancel() {
    //             console.log("Cancel");
    //         },
    //     });
    // };

    // const showDeleteConfirm = () => {
    //     confirm({
    //         title: "Are you sure delete this Card?",
    //         icon: <ExclamationCircleFilled />,
    //         // content: `${admin ? 'if you delete the card the user also get deleted.' : ' }` ,
    //         content: `${admin ? 'if you delete the card, the user also get deleted. ' : 'if you delete the card, the get deleted.'}`,
    //         okText: "Yes",
    //         okType: "danger",
    //         cancelText: "No",
    //         onOk() {
    //             handleDelete();
    //         },
    //         onCancel() {
    //             console.log("Cancel");
    //         },
    //     });
    // };


    return (
        <div>

            <Drawer
                width={640}
                placement="right"
                closable={false}
                onClose={onClose}
                open={open}

            >
                <div>
                    <div className="flex justify-between">
                        <p
                            className="site-description-item-profile-p text-lg font-semibold"
                            style={{
                                marginBottom: 24,
                            }}
                        >
                            Contact Card Details
                        </p>
                        {/* <div>
                            <Button
                                className="text-white bg-red-600 hover:bg-red-700 w-28"
                                onClick={showDeleteConfirm}
                                loading={delLoder}
                            >
                                Delete
                            </Button>
                            <Button
                                className="text-white bg-slate-600 hover:bg-slate-700 ml-2 w-28 "
                                onClick={showDeactiveConfirm}
                                loading={statuslLoder}
                            >
                                {cardStatus === "deactive" ? "Active" : "Deactive"}
                            </Button>
                            <Button
                                onClick={handleSubmit}
                                type="button"
                                className="text-white bg-orange-600 hover:bg-orange-700 ml-2 w-28 "
                            >
                                Edit
                            </Button>
                        </div> */}
                    </div>
                    <Avatar shape="square" size={64} src={drawerData?.profileImage ? drawerData?.profileImage : defaultProfileImage} />
                    {drawerData?.personalDetails ?
                        <ul>
                            <h1 className='font-semibold text-lg mt-3' >Personal Details </h1>
                            {Object.keys(drawerData?.personalDetails).map(key => (
                                <li key={key} className='flex gap-2'>
                                    <p className='capitalize font-medium mim-w-[150px]'>{key}  </p> :
                                    <p>  {drawerData?.personalDetails[key]}</p>
                                </li>
                            ))}
                        </ul>
                        : ""
                    }
                    <p><b className='text-sm font-semibold'>Tap Count :</b> {drawerData?.tapCount ? drawerData?.tapCount : "0"}</p>
                    {drawerData?.tapCount ? <Button> <Link to={'/admin-locationList'} state={{ loc: drawerData?.location ? drawerData?.location : [], from: location }} > View Location</Link></Button> : ""}


                    {drawerData?.socialMedias ?
                        <ul>
                            <h1 className='font-semibold text-lg mt-5'>Social Medias</h1>
                            {Object.keys(drawerData?.socialMedias).map(key => (
                                <li key={key} className='flex gap-2'>
                                    <p className='capitalize font-medium mim-w-[150px]'>{key}  </p> :
                                    <p>  {drawerData?.socialMedias[key]}</p>
                                </li>
                            ))}
                        </ul>
                        : ""
                    }
                </div>

            </Drawer>
            {/* <ToastContainer /> */}

        </div>
    )
}

export default ContactCardDrawer