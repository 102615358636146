import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import CreateAdminPage from './pages/adminPages/CreateAdminPage';
import ViewAdminPage from './pages/adminPages/ViewAdminPage';
import ActiveUsersPage from './pages/userPages/ActiveUsersPage';
import CardsPage from './pages/CardsPage';
import EditAdminPage from './pages/adminPages/EditAdminPage';
import TrashPage from './pages/TrashPage';

import AdminLoginProtect from './auth/AdminLoginProtect';
import AdminRouteProtect from './auth/AdminRouteProtect';
import OrdersPage from './pages/OrdersPage';
import ViewCouponPage from './pages/couponPages/ViewCouponPage';
import EditCouponPage from './pages/couponPages/EditCouponPage';
import CreateCouponPage from './pages/couponPages/CreateCouponPage';
import ViewProductsPage from './pages/productsPages/ViewProductsPage';
import CreateProductsPage from './pages/productsPages/CreateProductsPage';
import EditProductsPage from './pages/productsPages/EditProductsPage';
import ViewThemesPage from './pages/themesPages/ViewThemesPage';
import VisitiorsListPage from './pages/VisitiorsListPage';
import ViewLanguagePage from './pages/languagesPages/ViewLanguagePage';
import CreateLanguagePage from './pages/languagesPages/CreateLanguagePage';
import EditLanguagePage from './pages/languagesPages/EditLanguagePage';
import ViewMaterialsPage from './pages/materialsPages/ViewMaterialsPage';
import CreateMaterialsPage from './pages/materialsPages/CreateMaterialsPage';
import EditMaterialsPage from './pages/materialsPages/EditMaterialsPage';
import CreateNewProductsPage from './pages/ProductPages/CreateNewProductsPage';
import UpdateProductsPage from './pages/ProductPages/UpdateProductsPage';
import ViewAllProductsPage from './pages/ProductPages/ViewAllProductsPage';
import ViewVariantsPage from './pages/ProductPages/ViewVariantsPage';
import ViewBannerPage from './pages/bannerPages/ViewBannerPage';
import CreateUserPage from './pages/userPages/CreateUserPage';
import EditUserPage from './pages/userPages/EditUserPage';


function App() {
  return (
    <Router>
      <Routes>
        <Route element={<AdminLoginProtect />}>
          <Route path='/' element={<LoginPage />} />
        </Route>

        <Route element={<AdminRouteProtect />}>
          <Route path='/dashboard' element={<DashboardPage />} />
          <Route path='/create-admin' element={<CreateAdminPage />} />
          <Route path='/view-admins' element={<ViewAdminPage />} />
          <Route path='/edit-admin' element={<EditAdminPage />} />
          <Route path='/active-users' element={<ActiveUsersPage />} />
          <Route path='/create-user' element={<CreateUserPage />} />
          <Route path='/edit-user' element={<EditUserPage />} />
          <Route path='/cards' element={<CardsPage />} />
          <Route path='/visitors' element={<VisitiorsListPage />} />

          {/*  --------------------------------- coupon ---------------------------------  */}
          <Route path='/coupons' element={<ViewCouponPage />} />
          <Route path='/create-coupon' element={<CreateCouponPage />} />
          <Route path='/edit-coupon' element={<EditCouponPage />} />

          {/* -------------------------------- Products --------------------------------  */}
          <Route path='/products' element={<ViewProductsPage />} />
          <Route path='/create-products' element={<CreateProductsPage />} />
          <Route path='/edit-products' element={<EditProductsPage />} />

          {/* --------------------------------New Products --------------------------------  */}
          <Route path='/view-products' element={<ViewAllProductsPage />} />
          <Route path='/create-product' element={<CreateNewProductsPage />} />
          <Route path='/update-product' element={<UpdateProductsPage />} />
          <Route path='/variants' element={<ViewVariantsPage />} />


          {/* -------------------------------- Themes --------------------------------  */}
          <Route path='/themes' element={<ViewThemesPage />} />

          {/* -------------------------------- Languages --------------------------------  */}
          <Route path='/languages' element={<ViewLanguagePage />} />
          <Route path='/create-language' element={<CreateLanguagePage />} />
          <Route path='/edit-language' element={<EditLanguagePage />} />

          {/* -------------------------------- Banner --------------------------------  */}
          <Route path='/banner' element={<ViewBannerPage />} />


          {/* -------------------------------- Materials --------------------------------  */}
          <Route path='/materials' element={<ViewMaterialsPage />} />
          <Route path='/create-materials' element={<CreateMaterialsPage />} />
          <Route path='/edit-materials' element={<EditMaterialsPage />} />

          <Route path='/trash' element={<TrashPage />} />
          <Route path='/orders' element={<OrdersPage />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
