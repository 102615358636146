import React from 'react'
import Sidebar from '../../components/sidebar/Sidebar'
import CreateLanguage from '../../components/language/createLanguage/CreateLanguage'
import { Layout } from 'antd';
const { Content } = Layout;

function EditLanguagePage() {
    return (
        <div>
            <Layout>
                <div className='fixed'>
                    <Sidebar />
                </div>
                <Layout style={{ marginLeft: 200, }}  >
                    <Content style={{ margin: '24px 16px', padding: 24, minHeight: 280, background: 'white', }} >
                        <CreateLanguage edit={'edit'} />
                    </Content>
                </Layout>
            </Layout>
        </div>
    )
}

export default EditLanguagePage