import { Button, Col, Collapse, Divider, Form, Input, Row, Select, Space, Tooltip, Typography, Upload, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CloseOutlined, MinusCircleOutlined, PlusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { createProduct, editProduct, getMaterialList } from '../../../api/AdminRequest';
import { useLocation, useNavigate } from 'react-router-dom';
import ImgCrop from "antd-img-crop";
import HandleImageUpload from '../../helper/ImageCompress';
import ImageVideoUploader from './ImageVideoUploader';

const { Title } = Typography;
const { Panel } = Collapse;
// let index = 0;

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    // wrapperCol: {
    //   xs: {
    //     span: 24,
    //   },
    //   sm: {
    //     span: 16,
    //   },
    // },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

function CreateProducts({ edit }) {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { state } = useLocation();
    // console.log(state, '************************'); 

    const imageFile = [
        {
            uid: "-1",
            name: "image.png",
            status: state?.imageUrl ? "done" : "",
            url: state?.imageUrl,
        },
    ]

    const defaultItems = [
        {
            value: 'BusniessQR',
            label: 'Business QR',
        },
        {
            value: 'reviewQR',
            label: 'Review QR',
        },
        {
            value: 'digitalInterface',
            label: 'Digital Interface',
        }
    ]
    const [name, setName] = useState('');
    const [items, setItems] = useState(defaultItems);
    const [error, setError] = useState({});
    const [fileList, setFileList] = useState(state ? imageFile : [])
    const [productType, setproductType] = useState(state ? state?.productType : null);
    const [materialList, setMaterialList] = useState([]);
    const [filterMaterial, setFilterMaterial] = useState(state ? state?.materialID : '');
    const [filterMaterialColor, setFilterMaterialColor] = useState(state ? filterMaterial?.CustomCardColor?.[state?.materials?.material] : []);
    const [filterMaterialFavColor, setFilterMaterialFavColor] = useState(state ? filterMaterial?.CustomTextColor?.[state?.materials?.materialcolor] : []);
    // const [filterButtonMaterialColor, setFilterButtonMaterialColor] = useState([]);
    const [filterButtonMaterialFavColor, setFilterButtonMaterialFavColor] = useState(state ? filterMaterial?.CustomTextColor?.[state?.materials?.materialcolor] : []);
    const initialMaterial = {
        material: null,
        materialcolor: null,
        materialFavoriteColor: null,
        textColor: null,
        // Add more selects as needed
    }
    const initialButtons = [{
        name: null,
        materialcolor: null,
        materialFavoriteColor: null,
        color: null,
        textColor: null,
        // Add more selects as needed
    }]
    const [selectMaterialValues, setSelectMaterialValues] = useState(state ? state?.materials : initialMaterial)
    const [selectButtonValues, setSelectButtronValues] = useState([])
    const [images, setImages] = useState([]);
    console.log(images, "imagesss create product");

    console.log(fileList, 'file');
    const inputRef = useRef(null);

    useEffect(() => {
        const getallMaterials = async () => {
            try {
                const { data } = await getMaterialList()
                // console.log(data);
                setMaterialList(data.materials)
            } catch (error) {
                console.log(error);
            }
        }

        getallMaterials()
        setSelectButtronValues(state ? state?.buttons : initialButtons)
    }, [])

 useEffect(() => {
    setImages(state?.images);
  }, []);

    const onFinish = async (values) => {
        values.productType = productType
        if (productType === 'BusniessQR' || productType === 'reviewQR') {
            values.materialObject = filterMaterial?._id
        }
        if (selectMaterialValues?.material) {
            values.defaultMaterial = selectMaterialValues
        }
        if (selectButtonValues[0]?.materialcolor) {
            values.buttons = selectButtonValues
        }
        // console.log(values, 'valuesssss');
        let id = state?._id

        const errors = validateForm(values);
        setError(errors)
        if (Object.keys(errors).length === 0) {

            try {

                let datas
                if (productType === 'BusniessQR' || productType === 'reviewQR') {

                    datas = values
                } else {
                    const ProductImgCompress = await HandleImageUpload(fileList)
                    datas = new FormData();
                    datas.append("productImages", ProductImgCompress);
                    // for (const keys in formValues) {
                    //     datas.append(`${keys}`, `${formValues[keys]}`);
                    // }

                    datas.append('details', JSON.stringify(values))

                    images.forEach((item, index) => {
                        // If originFileObj exists and it's not empty
                        console.log(item.originFileObj,'item');
                        if (item?.originFileObj && item?.originFileObj?.type && item?.status ==='done' ) {
                            console.log(item.originFileObj,'item.originFileObj');
                            datas.append(`productImages`, item.originFileObj);
                        }
                    });
                }


                const { data } = edit ? await editProduct(id, datas) : await createProduct(datas)
                // console.log(data);
                if (data.success) {
                    toast.success('New Product Created', {
                        position: "top-right",
                        autoClose: 3000,
                        theme: "dark",
                    });
                } else if (data.update) {
                    toast.success('Updated Successfully', {
                        position: "top-right",
                        autoClose: 3000,
                        theme: "dark",
                    });

                }
                setTimeout(() => {
                    navigate('/products')
                }, 3000)
            } catch (error) {
                console.log(error);
                toast(error.message)
            }
        } else {
            const firstValue = Object.values(errors)[0];
            // console.log(firstValue, '23456hggfgfg');
            toast.error(`Please fill required fields, ${firstValue}`, {
                autoClose: 3000,
                theme: "dark",
            });
        }


    }

    const validateForm = (data) => {
        const error = {};

        if (!data.productType) {
            error.productType = "Product Type required";
        }


        if (data.productType && (data.productType === 'BusniessQR' || data.productType === 'reviewQR')) {

            if (!data.materialObject) {
                error.materialObject = "Material Object required";
            }

            if (!selectMaterialValues.material) {
                error.material = "Material  required";
            }
            if (!selectMaterialValues.materialcolor) {
                error.materialcolor = "Material Color  required";
            }
            if (!selectMaterialValues.materialFavoriteColor) {
                error.materialFavoriteColor = "Material Favorite Color required";
            }
            if (!selectMaterialValues.textColor) {
                error.textColor = "Material Text Color required";
            }


        }


        return error;
    };



    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };


    const handleChangeProductType = (value) => {
        // console.log(`selected ${value}`);
        if (value !== 'BusniessQR' || value !== 'reviewQR') {
            setFilterMaterial(null)
        }
        setFileList([])
        setproductType(value)
    };
    const handleChangeMaterials = async (value) => {
        // console.log(`selected ${value}`);
        setSelectMaterialValues(initialMaterial)
        setSelectButtronValues(initialButtons)
        const filterData = await materialList.filter((item) => item.name === value)
        setFilterMaterial(filterData[0])
    };

    // const handleChangeMaterial = async (value) => {
    //     console.log(`selected ${value}`);
    //     const filteredArray = await filterMaterial?.CustomCardColor?.[value]
    //     setFilterMaterialColor(filteredArray)
    //     // console.log(filteredArray);
    // };
    // const handleChangeMaterialColor = async (value) => {
    //     console.log(`selected ${value}`);
    //     const filteredArray = await filterMaterial?.CustomTextColor?.[value]
    //     setFilterMaterialFavColor(filteredArray)
    //     // console.log(filteredArray);
    // };

    const handleChangeMaterial = async (name, value) => {
        // console.log(`selected ${value}`);
        if (name === "material") {
            setSelectMaterialValues(initialMaterial)
            setSelectButtronValues(initialButtons)
            const filteredArray = await filterMaterial?.CustomCardColor?.[value]
            setFilterMaterialColor(filteredArray)

        }
        if (name === "materialcolor") {
            setSelectMaterialValues(prevValues => ({
                ...prevValues,
                materialFavoriteColor: null,
                textColor: null,
            }))
            const filteredArray = await filterMaterial?.CustomTextColor?.[value]
            setFilterMaterialFavColor(filteredArray)
        }
        setSelectMaterialValues(prevValues => ({
            ...prevValues,
            [name]: value,
        }))

    };
    // const handleChangeButton = async (name, value) => {
    //     if (name === "materialcolor") {
    //         setSelectButtronValues(prevValues => ({
    //             ...prevValues,
    //             materialFavoriteColor: null,
    //             color: null,
    //             textColor: null,
    //         }))
    //         const filteredArray = await filterMaterial?.CustomTextColor?.[value]
    //         setFilterButtonMaterialFavColor(filteredArray)
    //     }
    //     setSelectButtronValues(prevValues => ({
    //         ...prevValues,
    //         [name]: value,
    //     }))
    // }

    const handleChangeButton = async (name, index, value) => {
        // console.log(index, 'index');
        setSelectButtronValues(prevValues => {
            let updatedValues = [...prevValues];

            // console.log(updatedValues, '777777');

            // Reset certain values if materialcolor is being changed
            if (name === "materialcolor") {
                // updatedValues = updatedValues.map((item,index) => ({
                //     materialFavoriteColor: null,
                //     color: null,
                //     textColor: null,
                // }));
                updatedValues[index] = {
                    ...updatedValues[index],
                    materialFavoriteColor: null,
                    color: null,
                    textColor: null,
                };


                const filteredArray = filterMaterial?.CustomTextColor?.[value];
                setFilterButtonMaterialFavColor(filteredArray);
            }


            // if (name === "materialcolor") {
            //     updatedValues?.[index].materialFavoriteColor = null
            //     updatedValues?.[index].color = null
            //     updatedValues?.[index].textColor = null

            //     const filteredArray = filterMaterial?.CustomTextColor?.[value];
            //     setFilterButtonMaterialFavColor(filteredArray);

            // }
            // Update the specified property in the array at the given index
            updatedValues[index] = {
                ...updatedValues[index],
                [name]: value,
            };

            return updatedValues;
        })
    };

    // console.log(selectButtonValues, '88888');
    // console.log(selectMaterialValues, '88888');

    // console.log(filterMaterialColor,'pppp');
    // console.log(filterMaterial, ';;;;;;;');


    let addFormFields = () => {
        setSelectButtronValues([...selectButtonValues, {
            name: null,
            materialcolor: null,
            materialFavoriteColor: null,
            color: null,
            textColor: null,
        }]);
    };

    let removeFormFields = (i) => {
        let newFormValues = [...selectButtonValues];
        newFormValues.splice(i, 1);
        setSelectButtronValues(newFormValues);
    };

    const onNameChange = (event) => {
        setName(event.target.value);
    };

    const addItem = (e) => {
        e.preventDefault();
        // Validation: Check if the item already exists or if the name is empty
        if (!name || items.some(item => item?.value === name)) {
            // Handle validation error (you can show a message, etc.)

            return;
        }
        setItems([...items, { label: name, value: name }]);
        setName('');
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };


    const removeItem = (removedItem) => {
        // Filter out the removed item
        const updatedItems = items.filter((item) => item.value !== removedItem.value);
        setItems(updatedItems);
    };

    const getOptionLabel = (selectedItem) => {
        if (defaultItems.some((item) => item.value === selectedItem.value)) {
            // For default items, exclude the delete button
            return <span>{selectedItem.label}</span>;
        }

        // For dynamically added items, include the delete button
        return (
            <div className='flex justify-between items-center'>
                <p>{selectedItem.label}</p>

                <CloseOutlined
                    onClick={() => removeItem(selectedItem)}
                    style={{ cursor: 'pointer', marginLeft: '8px', color: 'rgba(0, 0, 0, 0.25)' }}
                />
            </div>
        );
    };

    const onChange = ({ fileList: newFileList }) => {
        if (newFileList[0]?.status) { newFileList[0].status = "done"; }
        setFileList(newFileList);

        // dispatch(addProfileImage(fileList));
    };
    ;

    const props = {
        name: 'file',
        action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    return (
        <div>
            <Row gutter={16}>
                <Col span={12}>
                    <Title level={2}>{edit ? 'Edit Product' : 'Create Product'}</Title>
                </Col>
            </Row>

            <div className='flex min-h-screen justify-center mt-14'>

                {/* <div> */}
                {/* <h1 className='text-center  text-xl font-bold'>Create Admin</h1> */}

                <Form
                    {...formItemLayout}
                    form={form}
                    name="register"
                    onFinish={onFinish}
                    initialValues={{
                        productName: state ? state?.productName : '',
                        cardName: state ? state?.cardName : '',
                        price: state ? state?.price : '',
                        description: state ? state?.cardDescription : ''
                    }}
                    style={{
                        // maxWidth: 600,
                        width: 600
                    }}
                    scrollToFirstError
                >
                    <div className='grid grid-cols-2 gap-2 '>
                        <div>
                            <label htmlFor="" className='text-xl font-semibold'>Product Name </label>
                            <Form.Item
                                name="productName"
                                // label="Name "
                                className='mb-2'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Product Name!',
                                        whitespace: true,
                                    },
                                ]}
                            >

                                <Input placeholder='Product Name' className='py-2' />
                            </Form.Item>
                        </div>
                        <div>
                            <label htmlFor="" className='text-xl font-semibold'>Product Type </label>
                            <Select
                                style={{
                                    width: '100%',
                                }}
                                value={productType}
                                size='large'
                                placeholder="Select Product Type"
                                onChange={handleChangeProductType}
                                dropdownRender={(menu) => (
                                    <>
                                        {menu}
                                        <Divider
                                            style={{
                                                margin: '8px 0',
                                            }}
                                        />
                                        <Space
                                            style={{
                                                padding: '0 8px 4px',
                                            }}
                                        >
                                            <Input
                                                placeholder="Please enter item"
                                                ref={inputRef}
                                                value={name}
                                                onChange={onNameChange}
                                                onKeyDown={(e) => e.stopPropagation()}
                                            />
                                            {(!name || items.some(item => item.value === name)) ?
                                                <Button type="text" disabled icon={<PlusOutlined />} > Add item </Button>
                                                :
                                                <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                                                    Add item
                                                </Button>
                                            }
                                        </Space>
                                    </>
                                )}
                                options={items.map((item) => ({
                                    label: getOptionLabel(item),
                                    value: item.value,
                                }))}
                            />
                            <p className='text-red-500'>{error.productType}</p>
                        </div>
                        {/* </div>
                        <div className='grid grid-cols-2 gap-2 '> */}
                        <div>
                            <label htmlFor="" className='text-xl font-semibold'>Card Name</label>
                            <Form.Item
                                name="cardName"
                                // label="E-mail"
                                className='mb-2'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Card Name!',
                                    },
                                ]}
                            >

                                <Input placeholder='Card Name' className='py-2' />
                            </Form.Item>
                        </div>
                        <div>
                            <label htmlFor="" className='text-xl font-semibold'>Price</label>
                            <Form.Item
                                name="price"
                                // label="E-mail"
                                className='mb-2'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Card Price!',
                                    },
                                ]}
                            >

                                <Input placeholder='Price' className='py-2' />
                            </Form.Item>
                        </div>

                        {/* </div>
                        <div className='grid grid-cols-2 gap-2 '> */}
                        <div>
                            <label htmlFor="" className='text-xl font-semibold'>Card Description</label>
                            <Form.Item
                                name="description"
                                // label="E-mail"
                                className='mb-2'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Card Description!',
                                    },
                                ]}
                            >

                                <Input placeholder='Description' className='py-2' />
                            </Form.Item>
                        </div>
                        {(productType !== 'BusniessQR' && productType !== 'reviewQR') ?
                            <div>
                                <label htmlFor="" className='text-xl font-semibold'>Product Image </label>
                                <div className={`border  ${fileList[0]?.originFileObj ? 'pl-2' : ''}  rounded-lg  h-10 pb-3`}>

                                    <ImgCrop showGrid rotationSlider aspectSlider showReset fillColor="rgba(76, 175, 80, 0)" >
                                        <Upload {...props}
                                            fileList={fileList}
                                            onChange={onChange}
                                            onPreview={onPreview}

                                        >
                                            {fileList[0]?.originFileObj || fileList[0]?.url ? (
                                                ""
                                            ) : (
                                                <Button className=" w-[295px] h-10" icon={<UploadOutlined />}>Click to Upload</Button>
                                            )}
                                        </Upload>
                                    </ImgCrop>
                                    {/* {fileList[0]?.originFileObj ?
                                        <p className='text-xs mt-3'><span className='font-semibold'>Note:</span> This is not a final artwork. Our designing team will connect with you once you place the order.</p>
                                        : ''
                                    } */}
                                </div>
                            </div>
                            :
                            <div>
                                <label htmlFor="" className='text-xl font-semibold'>Materials Objects </label>
                                <Select
                                    // defaultValue="lucy"
                                    style={{
                                        width: '100%',
                                    }}
                                    size='large'
                                    value={filterMaterial?.name}
                                    disabled={productType === 'BusniessQR' || productType === 'reviewQR' ? false : true}
                                    placeholder='Select the Materials'
                                    onChange={handleChangeMaterials}
                                    options={materialList?.map((item) => ({
                                        label: item.name,
                                        value: item.name,
                                    }))}
                                />
                                <p className='text-red-500'>{error.materialObject}</p>
                            </div>
                        }

                    </div>

                    <div>
                        <Collapse defaultActiveKey={['0']} >

                            <Panel header="Images" key="1"  >

                                <ImageVideoUploader images={images} setImages={setImages} />

                            </Panel>

                        </Collapse>
                    </div>

                    {filterMaterial ?
                        <>
                            <h1 className='font-bold text-xl my-4'>Default materials</h1>
                            <div className='grid grid-cols-2 gap-2 '>
                                <div>
                                    <label htmlFor="" className='text-xl font-semibold'>Material </label>
                                    <Select
                                        // defaultValue="lucy"
                                        placeholder='Select the Material'
                                        value={selectMaterialValues.material}
                                        style={{
                                            width: '100%',
                                        }}
                                        size='large'
                                        onChange={(value) => handleChangeMaterial('material', value)}
                                        options={filterMaterial?.CustomMaterial?.map((material) => ({
                                            label: material.key,
                                            value: material.key,
                                        }))}
                                    />
                                    <p className='text-red-500'>{error.material}</p>
                                </div>
                                <div>
                                    <label htmlFor="" className='text-xl font-semibold'>Material color </label>
                                    <Select
                                        // defaultValue="lucy"
                                        placeholder='Select the Material color'
                                        value={selectMaterialValues.materialcolor}
                                        style={{
                                            width: '100%',
                                        }} f
                                        disabled={filterMaterialColor.length === 0 ? true : false}
                                        size='large'
                                        onChange={(value) => handleChangeMaterial('materialcolor', value)}
                                        options={filterMaterialColor?.map((material) => ({
                                            label: material.name,
                                            value: material.value,
                                        }))}
                                    />
                                    <p className='text-red-500'>{error.materialcolor}</p>
                                </div>
                            </div>
                            <div className='grid grid-cols-2 gap-2 '>
                                <div>
                                    <label htmlFor="" className='text-xl font-semibold'> Material Favorite Color </label>
                                    <Select
                                        // defaultValue="lucy"
                                        placeholder='Select the Material Favorite Color'
                                        value={selectMaterialValues.materialFavoriteColor}
                                        style={{
                                            width: '100%',
                                        }}
                                        disabled={filterMaterialFavColor.length === 0 ? true : false}
                                        size='large'
                                        onChange={(value) => handleChangeMaterial('materialFavoriteColor', value)}
                                        options={filterMaterialFavColor?.map((material) => ({
                                            label: material.name,
                                            value: material.value,
                                        }))}
                                    />
                                    <p className='text-red-500'>{error.materialFavoriteColor}</p>
                                </div>
                                <div>
                                    <label htmlFor="" className='text-xl font-semibold'>Text color </label>
                                    <Select
                                        // defaultValue="lucy"
                                        placeholder='Select the Material Text Color'
                                        value={selectMaterialValues.textColor}
                                        style={{
                                            width: '100%',
                                        }}
                                        size='large'
                                        disabled={filterMaterialFavColor.length === 0 ? true : false}
                                        onChange={(value) => handleChangeMaterial('textColor', value)}
                                        options={filterMaterialFavColor?.map((material) => ({
                                            label: material.value,
                                            value: material.color,
                                        }))}
                                    />
                                    <p className='text-red-500'>{error.textColor}</p>
                                </div>
                            </div>
                        </>
                        : ''
                    }
                    {filterMaterial && selectMaterialValues.material ?
                        <>
                            <h1 className='font-bold text-xl my-4'>Default Buttons</h1>
                            {selectButtonValues?.map((element, index) => (
                                <div key={index}>
                                    <div className='flex justify-between gap-3 mr-2'>
                                        <div className='w-full'>
                                            <label htmlFor="" className='text-xl font-semibold'>Name</label>
                                            {/* <Form.Item
                                                name="name"
                                                // label="E-mail"
                                                className='mb-2 '
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your Card Limit!',
                                                    },
                                                ]}
                                            >

                                            </Form.Item> */}
                                            <Input className='py-2' name='name' value={element?.name} onChange={(e) => handleChangeButton('name', index, e.target.value)} />
                                        </div>
                                        <div>
                                            {index ? (
                                                <button
                                                    type="button"
                                                    className="mt-8"
                                                    onClick={() => removeFormFields(index)}
                                                >
                                                    <Tooltip placement="top" title="Remove">
                                                        <MinusCircleOutlined
                                                            style={{ fontSize: "20px", color: "red" }}
                                                        />
                                                    </Tooltip>
                                                </button>
                                            ) : (
                                                <button
                                                    type="button"
                                                    className="mt-8"
                                                    onClick={() => addFormFields()}
                                                >
                                                    <Tooltip placement="top" title="Add More">
                                                        <PlusCircleOutlined
                                                            style={{ fontSize: "20px", color: "green" }}
                                                        />
                                                    </Tooltip>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                    <div className='grid grid-cols-2 gap-2 mb-5'>
                                        <div>
                                            <label htmlFor="" className='text-xl font-semibold'>Material color </label>
                                            <Select
                                                // defaultValue="lucy"
                                                placeholder='Select the Material color'
                                                style={{
                                                    width: '100%',
                                                }}
                                                value={element?.materialcolor}
                                                onChange={(value) => handleChangeButton('materialcolor', index, value)}
                                                disabled={filterMaterialColor.length === 0 ? true : false}
                                                size='large'
                                                options={filterMaterialColor?.map((material) => ({
                                                    label: material.name,
                                                    value: material.value,
                                                }))}
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="" className='text-xl font-semibold'> color </label>
                                            <Select
                                                // defaultValue="lucy"
                                                placeholder='Select the  color'
                                                style={{
                                                    width: '100%',
                                                }}
                                                size='large'
                                                value={element?.color}
                                                onChange={(value) => handleChangeButton('color', index, value)}
                                                disabled={filterButtonMaterialFavColor.length === 0 ? true : false}
                                                options={filterMaterialColor?.map((material) => ({
                                                    label: material.key,
                                                    value: material.color,
                                                }))}
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="" className='text-xl font-semibold'>Material Favorite Color </label>
                                            <Select
                                                // defaultValue="lucy"
                                                placeholder='Select the Material Favorite Color'
                                                style={{
                                                    width: '100%',
                                                }}
                                                size='large'
                                                value={element?.materialFavoriteColor}
                                                disabled={filterButtonMaterialFavColor.length === 0 ? true : false}
                                                onChange={(value) => handleChangeButton('materialFavoriteColor', index, value)}
                                                options={filterButtonMaterialFavColor?.map((material) => ({
                                                    label: material.name,
                                                    value: material.value,
                                                }))}
                                            />
                                        </div>

                                        <div>
                                            <label htmlFor="" className='text-xl font-semibold'>Text color </label>
                                            <Select
                                                // defaultValue="lucy"
                                                placeholder='Select the Material Text Color'

                                                style={{
                                                    width: '100%',
                                                }}
                                                size='large'
                                                value={element?.textColor}
                                                disabled={filterButtonMaterialFavColor.length === 0 ? true : false}
                                                onChange={(value) => handleChangeButton('textColor', index, value)}
                                                options={filterButtonMaterialFavColor?.map((material) => ({
                                                    label: material.value,
                                                    value: material.color,
                                                }))}
                                            />
                                        </div>
                                    </div>

                                </div>
                            ))}
                        </>

                        : ""
                    }

                    <Form.Item {...tailFormItemLayout} className='flex justify-center  mr-14'>
                        <Button className='bg-blue-800 w-32  my-5 ' type="primary" htmlType="submit">
                            {edit ? 'Save' : ' Register'}
                        </Button>
                    </Form.Item>
                </Form>
                {/* </div> */}
                <ToastContainer />
            </div>
        </div>
    )
}

export default CreateProducts