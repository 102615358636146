import { Button, Col, Input, Modal, Row, Space, Table, Tooltip, Typography, } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { ExclamationCircleFilled, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { FaEdit, FaEye } from "react-icons/fa";
import { CgBlock, CgUnblock } from "react-icons/cg";
import { MdDelete } from 'react-icons/md';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Highlighter from 'react-highlight-words';
import { deactiveMaterials, deleteMaterials, getMaterialList, getMaterials } from '../../../api/AdminRequest';
import MaterialDrawer from '../../drawer/MaterialDrawer';
const { Title } = Typography;
const { confirm } = Modal;

function ViewMaterials() {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const [materails, setMaterials] = useState('')
    const [loader, setLoader] = useState(true)
    const [change, setChange] = useState('')
    const [open, setOpen] = useState(false);
    const [viewId, setViewID] = useState('')

    const navigate = useNavigate();

    useEffect(() => {
        const allMaterials = async () => {
            try {
                const { data } = await getMaterialList()
                console.log(data, '12355');
                setMaterials(data.materials)
                setLoader(false)
            } catch (error) {
                // console.log(error.message);
            }
        }
        allMaterials()
    }, [change])

    const handleEdit = (id) => {
        // const id = drawerData._id
        navigate("/edit-materials", { state: id });
    };

    const handleDeactive = async (id) => {

        try {
            setLoader(true)
            const { data } = await deactiveMaterials(id);
            // console.log(data);
            if (data.block) {
                // console.log("sucess blocked");
                // setCardStatus("blocked")
                setLoader(false)
            }
            if (data.active) {
                // setCardStatus("active")
                setLoader(false)
            }
            setChange(Date.now())
        } catch (error) {
            console.log(error);
            toast(error.response.data.message);
        }
    };

    const handleDelete = async (id) => {
        try {
            setLoader(true)
            const { data } = await deleteMaterials(id);
            // console.log(data);
            if (data.delete) {
                // console.log("sucess delete");
                setChange(Date.now())
                setLoader(false)
            }
        } catch (error) {
            console.log(error);
        }
    };

    const showDeactiveConfirm = (data) => {
        confirm({

            title: `${data?.status === 'active' ? 'Are you sure to Block ' : 'Are you sure to Active'}`,
            icon: <ExclamationCircleFilled />,
            content: `${data?.status === 'active' ? 'if you block, the materials cannot use any more ' : 'if you activate, we can use the materials.'}`,
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                handleDeactive(data._id);
                // { cardStatus === "active" ? setCardStatus("deactive") : setCardStatus("active") }
            },
            onCancel() {
                console.log("Cancel");
            },
        });



    };

    const showDeleteConfirm = (data) => {
        confirm({
            title: "Are you sure delete?",
            icon: <ExclamationCircleFilled />,
            // content: `${admin ? 'if you delete the card the user also get deleted.' : ' }` ,
            content: `if you delete, the coupon will deleted`,
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                handleDelete(data._id);
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        className='bg-blue-800'
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    {/* <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button> */}
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            handleSearch(selectedKeys, confirm)
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    const showDrawer = async (ID) => {

        setViewID(ID)
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

   

    const columns = [
        {
            title: 'No.',
            dataIndex: '_id',
            key: 'index',
            // render: (text, record, index) => index + 1,
            render: (text, record, index) => (materails.indexOf(record) + 1),
        },
        {
            title: 'ID',
            dataIndex: '_id',
            key: '_id',
            ...getColumnSearchProps('_id'),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            // ...getColumnSearchProps('status'),
            render: (status) => { return status === 'active' ? <p className=' text-center'>Active</p> : <p className='bg-red-500 px-1 text-white text-center rounded-xl'>Blocked</p> },
        },
        {
            title: 'Action',
            dataIndex: 'status._id',
            key: '_id',
            render: (status, _id) => {
                return (
                    <>
                        <div className='flex gap-3 items-center'>
                            <Tooltip title="view"><FaEye size={20} className='cursor-pointer' onClick={() => showDrawer(_id)} /></Tooltip>

                            {/* <Tooltip title="edit"><FaEdit size={20} className='cursor-pointer' onClick={() => handleEdit(_id)} /></Tooltip> */}
                            {_id.status === 'active' ?
                                <Tooltip title="block"><CgBlock size={28} className='cursor-pointer' onClick={() => showDeactiveConfirm(_id)} /></Tooltip>
                                :
                                <Tooltip title="unblock"> <CgUnblock size={20} className='cursor-pointer' onClick={() => showDeactiveConfirm(_id)} /></Tooltip>
                            }
                            <Tooltip title="delete"><MdDelete size={20} className='cursor-pointer' onClick={() => showDeleteConfirm(_id)} /></Tooltip>
                        </div>
                    </>
                )
            }

        },

    ];
    return (
        <div>
            <Row gutter={16}>
                <Col span={11}>
                    <Title level={2}>Materials </Title>
                </Col>
                
                <Col span={12} className='text-right'>
                    {/* <Link className='add-button rounded-lg ml-auto' to="/create-materials"><PlusOutlined />Create Materials</Link> */}
                </Col>
            </Row>

            <div className='flex justify-center min-h-screen'>
                <div className='w-full mt-5'>
                    <Table columns={columns} loading={loader} dataSource={materails} pagination={materails?.length > 10 ? true : false} />
                    <MaterialDrawer viewId={viewId} open={open} onClose={onClose} />

                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default ViewMaterials