import React from 'react'
import Sidebar from '../../components/sidebar/Sidebar'
import CreateProducts from '../../components/products/createProducts/CreateProducts'
import { Layout } from 'antd';
const { Content } = Layout;

function EditProductsPage() {
  return (
    <div>
        <Layout>
                <div className='fixed'>
                    <Sidebar />
                </div>
                <Layout style={{ marginLeft: 200, }}  >
                    <Content style={{ margin: '24px 16px', padding: 24, minHeight: 280, background: 'white', }} >
                        <CreateProducts edit={'edit'} />
                    </Content>
                </Layout>
            </Layout>
    </div>
  )
}

export default EditProductsPage