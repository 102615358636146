import React, { useState } from 'react'
import Variant from './Variant';
import { addVariant, updateVariant } from '../../../api/AdminRequest';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AddVariants({ variantsButton, Id, setChange, edit, detail }) {

    // console.log(edit, 'oooooo');
    // console.log(detail, 'detail');


    const [open, setOpen] = useState(false);
    const [variants, setVariants] = useState([])
    const [details, setDetails] = useState()

    // console.log(Id,'Id');

    // useEffect(()=>{

    // },[detail])

    const handleDataFromChild = async (details) => {
        // Do something with the data in the parent component
        // console.log('Data received from child:', data);
        // console.log('base64Img received from child:', base64Img);
        if (edit) {

            details.productID = detail.productID
        }
        // console.log(details, 'dataadadadad');


        try {
            const datas = new FormData();

            datas.append('details', JSON.stringify(details))

            // details?.images?.forEach((item, index) => {
            //     // If originFileObj exists and it's not empty
            //     // console.log(item.originFileObj, 'item');
            //     if (item?.originFileObj && item?.originFileObj?.type && item?.status === 'done') {
            //         console.log(item.originFileObj, 'item.originFileObj');
            //         datas.append(`variantImages`, item.originFileObj);
            //     }
            // })

            // console.log(details._id,'details._id');

            let variantImagesArray = []
            details?.images?.forEach((item, index) => {
                // removing thd removed images 
                if (item?.status !== 'removed') {
                    variantImagesArray.push(item)
                }
            });
            variantImagesArray.forEach((item, index) => {
                // If originFileObj exists and it's not empty
                if (item?.originFileObj && item?.originFileObj?.type && item?.status === 'done') {
                    // console.log(item.originFileObj, 'item.originFileObj');
                    datas.append(`variantImages`, item.originFileObj, `variantImages-${index}`);
                }
            });


            if (details?.frontImage) {
                // if (details?.frontImage[0]?.originFileObj && details?.frontImage[0]?.originFileObj?.type && details?.frontImage[0]?.status === 'done') {
                //     // console.log(item.originFileObj, 'item.originFileObj');
                //     datas.append(`frontsideImage`, details?.frontImage[0].originFileObj);
                // }
                const image = details?.frontImage?.find(item => item?.status === "done");
                // console.log(image, 'imagefront');
                if (image?.originFileObj && image?.originFileObj?.type && image?.status === 'done') {
                    // console.log(item.originFileObj, 'item.originFileObj');
                    const index = 0
                    datas.append(`frontsideImage`, image.originFileObj,`frontsideImage-${index}`);
                }
            }
            if (details?.backImage) {

                // if (details?.backImage[0]?.originFileObj && details?.backImage[0]?.originFileObj?.type && details?.backImage[0]?.status === 'done') {
                //     // console.log(item.originFileObj, 'item.originFileObj');
                //     datas.append(`backsideImage`, details?.backImage[0].originFileObj);
                // }
                const image =  details?.backImage?.find(item => item?.status === "done");
                // console.log(image, 'imageback');
                if (image?.originFileObj && image?.originFileObj?.type && image?.status === 'done') {
                    // console.log(item.originFileObj, 'item.originFileObj');
                    const index = 0
                    datas.append(`backsideImage`, image.originFileObj,`frontsideImage-${index}`);
                }
            }


            const { data } = edit ? await updateVariant(Id, datas) : await addVariant(Id, datas)
            // console.log(data);

            if (data.success) {
                toast.success('New Variant Created', {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "dark",
                });
                setOpen(false)
                setChange(Date.now())
            } else if (data.update) {
                toast.success('Updated Successfully', {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "dark",
                });
                setOpen(false)
                setChange(Date.now())
            }
        } catch (error) {
            console.log(error);
            setChange(Date.now())
            toast('Something Went Wrong')
            setOpen(false)
        }


    };

    // console.log(variants, 'variants');

    const handleAddVariant = async (id) => {
        setOpen(true)

    };

    return (
        <div>
            <span onClick={() => handleAddVariant()} >{variantsButton}</span>
            <Variant open={open} setOpen={setOpen} details={detail} edit={edit} onDataFromChild={handleDataFromChild} setChange={setChange} productID={edit ? detail.productID : Id} />
            <ToastContainer />
        </div>
    )
}

export default AddVariants