import React, { useEffect, useState } from 'react'
import { Col, Row, Statistic, Card, Typography } from 'antd';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import BarChart from './BarChart';
// import { getBookedCards, getViewUsers, profileDetails } from '../../../api/AdminRequest';
// import {  Tooltip, Alert } from 'antd';
import { UserOutlined, CreditCardOutlined, PlusOutlined, TeamOutlined } from '@ant-design/icons';
import { viewAdmins, viewCards, viewUsers } from '../../api/AdminRequest';
// import ErrorLogout from '../../../helper/ErrorLogout';

const { Title } = Typography;

const formatter = (value) => <CountUp end={value} separator="," />;

function Dashboard() {
    const [datas, setDatas] = useState({})
    const [users, setUsers] = useState([])
    const [cards, setCards] = useState([])
    const [admin, setAdmins] = useState()

    const data = [
        { year: '1991', value: 3 },
        { year: '1992', value: 4 },
        { year: '1993', value: 3.5 },
        { year: '1994', value: 5 },
        { year: '1995', value: 4.9 },
        { year: '1996', value: 6 },
        { year: '1997', value: 7 },
        { year: '1998', value: 9 },
        { year: '1999', value: 13 },
    ];


    useEffect(() => {
        const graph = async () => {
            try {
                // const { data } = await graphPost()
                // console.log(data);
                setDatas({
                    labels: data.map((obj) => obj.year),
                    datasets: [{
                        label: "counts",
                        data: data.map((obj) => obj.value),
                        backgroundColor: ["rgba(75,196,1116,2)"],
                        borderColor: "black",
                        borderWidth: 0,
                    }]
                })
                // console.log(datas, '*********');
                // console.log(Object.keys(datas).length,'+++++++++++');



            } catch (error) {
                console.log(error.message);
                if (error.response.status === 403) {
                    // ErrorLogout(error)
                }
            }
        }

        const user = async () => {
            try {
                const { data } = await viewUsers()
                const activeUsers= data.users.filter((data)=>(
                    data.status ==="active"
                ))
                setUsers(activeUsers.length)
            } catch (error) {
                console.log(error.message);
                if (error.response.status === 403) {
                    // ErrorLogout(error)
                }
            }
        }

        const card = async () => {
            try {
                const { data } = await viewCards()
                const activeCards= data.cards.filter((data)=>(
                    data.status ==="active"
                ))
                setCards(activeCards.length)
            } catch (error) {
                console.log(error.message);
            }
        }
        const admin = async () => {
            try {
                const { data } = await viewAdmins()
                const activeAdmins= data.admins.filter((data)=>(
                    data.status ==="active"
                ))
                setAdmins(activeAdmins.length)
            } catch (error) {
                console.log(error.message);
            }
        }

        graph()
        user()
        card()
        admin()
    }, [])

    

    return (
        <>
            <Row gutter={16}>
                <Col span={12}>
                    <div className='flex align-middle'>
                        <Title level={2}>Dashboard </Title>
                        <Link className='add-button rounded-lg ml-5' to="/create-admin"><PlusOutlined />Create Admin</Link>
                    </div>
                </Col>
            </Row>

            <div className='my-8 mt-5 card-counts'>
                <Row gutter={16}>
                    <Col span={8}>
                        <Card bordered={true}>
                            <Statistic title="Active Users" value={users} formatter={formatter} valueStyle={{ color: '#3f8600' }} precision={2} prefix={<UserOutlined />} />
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card bordered={true}>
                            <Statistic title="Active Admins" value={admin} formatter={formatter} valueStyle={{ color: '#1677ff' }} precision={2} prefix={<TeamOutlined />} />
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card bordered={true}>
                            <Statistic title="Active Cards" value={cards} formatter={formatter} valueStyle={{ color: '#ff2b3c' }} precision={2} prefix={<CreditCardOutlined />} />
                        </Card>
                    </Col>
                </Row>
            </div>
            <div>

                {Object.keys(datas).length !== 0 ?
                    <BarChart chartData={datas} />
                    : ''}
            </div>
        </>
    )
}

export default Dashboard