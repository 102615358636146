import React, { useEffect, useState } from "react";
import { Drawer, Spin, Avatar, Space } from "antd";
function VariantDrawer({ onClose, viewId, open, setChanges }) {

    const [loader, setLoader] = useState(false)
    const [variant, setVariant] = useState()
    const [selectedImg, setSelectedImg] = useState();
    const [selectedPrice, setSelectedPrice] = useState();

    // console.log(viewId, 'kkkk');

    useEffect(() => {
        setVariant(viewId)
        setSelectedImg(viewId?.images ? viewId?.images[0] : '')
        setSelectedPrice(viewId?.pricing?.prices ? viewId?.pricing?.prices[0] : '')
    }, [viewId])

    const handleClick = (image) => {
        setSelectedImg(image);
    };
    return (
        <div>
            <Drawer
                width={750}
                placement="right"
                closable={false}
                onClose={onClose}
                open={open}
            >
                <Spin spinning={loader}>

                    <div className=" mb-10 min-h-screen">
                        <div className="mt-3">
                            <p
                                className="site-description-item-profile-p text-xl font-bold"
                                style={{
                                    marginBottom: 16,
                                }}
                            >
                                Variant  Details
                            </p>
                            <p className="font-semibold">#{variant?._id}</p>
                        </div>
                        <div className="flex gap-5 justify-between ">
                            <div className=" my-4">
                                <div className="bg-[#F9F9F9] w-[400px] mb-2">

                                    <div>
                                        {selectedImg && (
                                            <div className="selected-image-container h-[200px] w-[400px]">
                                                {/* <img src={selectedImg.url} alt="Selected img" className="selected-image" /> */}
                                                {selectedImg?.type?.startsWith("video") ? (

                                                    <video
                                                        className="VideoInput_video h-[200px] w-[400px]"
                                                        // width="400"
                                                        // height="200px"
                                                        controls
                                                        src={selectedImg?.url}
                                                    />
                                                ) : selectedImg?.type?.startsWith("embed") ? (

                                                    <iframe
                                                        width="100%"
                                                        height="100%"
                                                        src={selectedImg?.url}
                                                        title="YouTube video player"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen
                                                    ></iframe>
                                                ) : (
                                                    <img
                                                        className="w-[400px] h-[200px] object-contain"
                                                        src={selectedImg?.url}
                                                        alt="Uploaded"
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </div>

                                </div>
                                <Space wrap size={16}>
                                    {variant?.images ?
                                        // variant.images.map((obj, index) => (

                                        //     obj.type?.startsWith("video") ?
                                        //         <Avatar shape="square" className="cursor-pointer flex items-center bg-gray-200" key={obj._id} size={64} src={<video width="100%" height="100%" src={obj?.url} />} onClick={() => handleClick(obj)} />

                                        //         :
                                        //         <Avatar shape="square" className="cursor-pointer" key={obj._id} size={64} src={obj?.url} onClick={() => handleClick(obj)} />

                                        // ))
                                        variant.images.map((obj, index) => {

                                            var video_thumbnail
                                            if (obj.type?.startsWith("embed")) {

                                                const youtube_video_id = obj?.url?.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/)?.pop();

                                                if (youtube_video_id && youtube_video_id.length === 11) {
                                                    video_thumbnail = `//img.youtube.com/vi/${youtube_video_id}/0.jpg`;
                                                    // console.log(video_thumbnail, 'video_thumbnail');
                                                }

                                            }
                                            return (

                                                obj.type?.startsWith("video") ?
                                                    <Avatar shape="square" className="cursor-pointer flex items-center bg-gray-200" key={obj._id} size={64} src={<video width="100%" height="100%" src={obj?.url} />} onClick={() => handleClick(obj)} />

                                                    : obj.type?.startsWith("embed") ?
                                                        <Avatar shape="square" className="cursor-pointer flex items-center bg-gray-200" key={obj._id} size={64} src={video_thumbnail} onClick={() => handleClick(obj)} />
                                                        :
                                                        <Avatar shape="square" className="cursor-pointer" key={obj._id} size={64} src={obj?.url} onClick={() => handleClick(obj)} />

                                            )
                                        })
                                        : ''
                                    }
                                    {/* <Avatar shape="square" size={64} src={card2} onClick={() => handleClick(card2)} /> */}
                                </Space>


                            </div>
                            <div className=" ">


                                <p className="text-[#2E2E2E] text-xl font-bold text-start">
                                    {variant?.name}
                                </p>
                                <p className="text-lg font-normal  text-[#2E2E2E]">{variant?.subtittle}</p>
                                <div className="mt-2">
                                    <p className="text-md font-normal  text-[#2E2E2E]">{variant?.pricing?.tittle}</p>
                                    <div className="flex gap-3 ">
                                        <p className="text-[#000000] text-[24px] font-bold"> {selectedPrice?.sellingPrice}
                                            <span className="text-sm">{selectedPrice?.currency}</span>
                                        </p>
                                        <p className="text-[#2E2E2E] text-[24px] font-boldl"> {selectedPrice?.retailPrice}
                                            <span className="text-sm" >{selectedPrice?.currency}</span>
                                        </p>
                                    </div>
                                    <p className="text-md font-normal  text-[#2E2E2E]">{variant?.pricing?.description}</p>

                                </div>
                                <div className="mt-[40px] flex gap-2 items-center">
                                    <p className="text-normal text-[#000000] text-md ">{variant?.color?.tittle} : </p>
                                    <button className={`bg-[${variant?.color?.primaryColor}] rounded flex flex-col justify-center w-[20px] h-[20px]`}> </button>
                                    <span className="font-bold">{variant?.color?.name}</span>

                                </div>

                                <div>
                                    {variant?.attributes ?
                                        variant?.attributes.map((obj) => (
                                            <div className="mt-5 flex gap-2 items-center">
                                                <p className="text-normal text-[#000000] text-md ">{obj?.name} : </p>
                                                <span className="font-bold">{obj?.value}</span>

                                            </div>
                                        ))
                                        : ''}
                                </div>


                            </div>
                        </div>

                        <div>
                            <p>Product ID : <span className="font-semibold">{variant?.productID}</span></p>
                            <p>Status : <span className="font-semibold" >{variant?.status}</span></p>
                        </div>

                        {variant?.features ?
                            <div className="mt-5">
                                <h1 className="font-bold text-lg">Features</h1>
                                {variant?.features.map((obj, index) => (
                                    <div className="mt-1">
                                        <span className="font-bold">{index + 1}) {obj?.tittle}</span>
                                        {obj.highlights.map((item) => (
                                            <p className="mt-3">{item}</p>
                                        ))}
                                        {obj.details.map((item) => (
                                            <div className="mt-3 flex gap-2 items-center">
                                                <p className=" ">{item?.key} : </p>
                                                <span className="font-semibold">{item?.value}</span>
                                            </div>
                                        ))}

                                    </div>
                                ))}
                            </div>
                            : ""
                        }

                        <div className="mt-3">
                            <h1 className="font-bold text-lg" >Quantity Rules</h1>
                            <p>Minumun : <span>{variant?.quantityRule?.min}</span></p>
                            <p>Maximun : <span>{variant?.quantityRule?.max}</span></p>
                            <p>Increment : <span>{variant?.quantityRule?.increment}</span></p>
                        </div>
                        {variant?.customImages?.frontSide?.url || variant?.customImages?.backSide?.url ?
                            <div>
                                <h1 className="font-bold text-lg" >Custom Images</h1>
                                {variant?.customImages?.frontSide?.url &&
                                    <>
                                        <p>Frontside Image </p>
                                        <img
                                            className="w-[400px] h-[200px] object-contain"
                                            src={variant?.customImages?.frontSide?.url}
                                            alt="Uploaded"
                                        />
                                    </>
                                }
                                {variant?.customImages?.backSide?.url &&
                                    <>
                                        <p>Backside Image </p>
                                        <img
                                            className="w-[400px] h-[200px] object-contain"
                                            src={variant?.customImages?.backSide?.url}
                                            alt="Uploaded"
                                        />
                                    </>
                                }
                            </div>
                            : ""
                        }


                    </div>

                </Spin>
                {/* <ToastContainer /> */}
            </Drawer>
        </div>
    )
}

export default VariantDrawer