import React from 'react'
import { Layout } from 'antd';
import Sidebar from '../components/sidebar/Sidebar';
import Trash from '../components/trash/Trash';
const { Content } = Layout;

function TrashPage() {
    return (
        <div>
            <Layout>
                <Sidebar />
                <Content style={{ margin: '24px 16px', padding: 24, minHeight: 280, background: 'white', }} >
                    <Trash />
                </Content>
            </Layout>
        </div>
    )
}

export default TrashPage