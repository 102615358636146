import React from 'react'
import {Bar, Line} from 'react-chartjs-2'
import Chart from 'chart.js/auto';


const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
  labels,
  datasets: [
    {
      label: 'QR Cards',
      data: "10",
      // backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: 'NFC Cards',
      data: "100",
      // backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};



function BarChart({chartData}) {
  return (
    <div className='flex gap-10 '>
      <div className="w-1/2" >
        <Bar data={chartData}/>
      </div>
      <div className="w-1/2" >
        <Line data={data} />
      </div>
    </div>

  )
}

export default BarChart