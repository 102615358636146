import React from 'react'
import Sidebar from '../../components/sidebar/Sidebar'
import ViewMaterials from '../../components/materials/viewMaterials/ViewMaterials'
import { Layout } from 'antd';
const { Content } = Layout;

function ViewMaterialsPage() {
    return (
        <div>
            <Layout>
                <Sidebar />
                <Layout style={{ marginLeft: 200, }}  >
                    <Content style={{ margin: '24px 16px', padding: 24, minHeight: 280, background: 'white', }} >
                        <ViewMaterials />
                    </Content>
                </Layout>
            </Layout>
        </div>
    )
}

export default ViewMaterialsPage