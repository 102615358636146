import React from 'react'
import Sidebar from '../../components/sidebar/Sidebar'
import { Layout } from 'antd';
import CreateUser from '../../components/user/CreateUser';
const { Content } = Layout;

function EditUserPage() {
    return (
        <Layout>
            <div className='fixed'>
                <Sidebar />
            </div>
            <Layout style={{ marginLeft: 200, }}  >
                <Content style={{ margin: '24px 16px', padding: 24, minHeight: 280, background: 'white', }} >
                    <CreateUser edit={'edit'} />
                </Content>
            </Layout>
        </Layout>
    )
}

export default EditUserPage