import { ExclamationCircleFilled, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Input, Modal, Row, Space, Table, Tabs, Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words';
import { getTrash, restoreTrash } from '../../api/AdminRequest';
const { Title } = Typography;
const { confirm } = Modal;


function Trash() {

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [adminTrash, setAdminTrash] = useState([])
    const [userTrash, setUserTrash] = useState([])

    const [loader, setLoader] = useState(true)
    const [change, setChange] = useState('')


    useEffect(() => {
        const getAdmins = async () => {
            try {
                const { data } = await getTrash()
                // console.log(data, '12355');
                setAdminTrash(data.admins)
                setUserTrash(data.users)
                setLoader(false)
            } catch (error) {
                console.log(error.message);
            }
        }
        getAdmins()
    }, [change])

    const restore = async (id) => {
        try {
            const { data } = await restoreTrash(id._id)
            console.log(data);
            setChange(Date.now())
        } catch (error) {
            console.log(error.message);
        }
    }

    const handleRestore = (id) => {
        confirm({
            title: "Are you sure to restore",
            icon: <ExclamationCircleFilled />,
            // content: `${admin ? 'if you delete the card the user also get deleted.' : ' }` ,
            // content: `if you delete the card, the get deleted.`,
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                restore(id)
            },
            onCancel() {
                console.log("Cancel");
            },
        });

    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        className='bg-blue-800'
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>

                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            handleSearch(selectedKeys, confirm)
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    const columns = [
        {

            title: 'No.',
            dataIndex: '_id',
            key: 'index',
            width: '10%',
            // render: (text, record, index) => index + 1,
            render: (text, record, index) => (items.key===1 ? adminTrash.indexOf(record) + 1 : userTrash.indexOf(record) + 1 ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            ...getColumnSearchProps('name'),
        },

        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '25%',
            ...getColumnSearchProps('email'),
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            width: '25%',
            ...getColumnSearchProps('phone'),
        },


        // {
        //     title: 'View',
        //     dataIndex: '_id',
        //     key: '_id',
        //     render: (_id) => <Button className='w-24 bg-slate-300 hover:bg-slate-200' onClick={() => handleRestore(_id)} >Restore</Button>

        // },
        {
            title: 'Acion',
            dataIndex: 'status._id',
            key: '_id',
            render: (status, _id) => {
                return (
                    userTrash ?
                        (_id.adminID ?
                            <p>{_id.adminID.name}</p>
                            // ''
                            :
                            <Button className='w-24 bg-slate-300 hover:bg-slate-200' onClick={() => handleRestore(_id)} >Restore</Button>
                        )
                        :
                        <Button className='w-24 bg-slate-300 hover:bg-slate-200' onClick={() => handleRestore(_id)} >Restore</Button>
                )
            }

        },

    ];

    const onChange = (key) => {
        console.log(key);
    };
    const items = [
        {
            key: '1',
            label: `Admins`,
            children: [
                <div className='flex justify-center '>

                    <div className='w-full mt-5 '>
                        <Table columns={columns} loading={loader} dataSource={adminTrash} pagination={adminTrash.length > 10 ? true : false} />
                    </div>
                </div>
            ],
        },
        {
            key: '2',
            label: `Users`,
            children: [
                <div className='flex justify-center '>

                    <div className='w-full mt-5 '>
                        <Table columns={columns} loading={loader} dataSource={userTrash} pagination={userTrash.length > 10 ? true : false} />
                    </div>
                </div>
            ],
        },

    ];

    return (
        <>
            <Row gutter={16}>
                <Col span={12}>
                    <Title level={2}>Trash Bin </Title>
                </Col>
            </Row>

            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
            {/* <div className='flex justify-center'>
                <div className='w-full mt-5'>
                    <h1 className='text-lg font-bold'>Admins</h1>
                    <Table columns={columns} loading={loader} dataSource={adminTrash} />
                    <h1 className='mt-2 text-lg font-bold'>Users</h1>
                    <Table columns={columns} loading={loader} dataSource={userTrash} />
                    <DrawerBox viewId={viewId} setChange={setChange} open={open} onClose={onClose} />

                </div>
            </div> */}
        </>
    )
}

export default Trash