import { Button, Col, Input, Modal, Row, Space, Table, Tabs, Tooltip, Typography, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import { createZeroCoupon, deactiveCoupon, deleteCoupon, viewCoupons } from '../../../api/AdminRequest';
import Moment from 'moment'
import { Link, useNavigate } from 'react-router-dom';
import { ExclamationCircleFilled, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { FaEdit, FaEye } from "react-icons/fa";
import { CgBlock, CgUnblock } from "react-icons/cg";
import { MdDelete } from 'react-icons/md';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Highlighter from 'react-highlight-words';
import CouponCardDrawer from '../../drawer/CouponCardDrawer';
const { Title } = Typography;
const { confirm } = Modal;

function ViewCoupon() {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    // const [coupons, setCoupons] = useState('')
    const [loader, setLoader] = useState(true)
    const [change, setChange] = useState('')
    const [zeroAvtiveCoupon, setZeroActiveCoupon] = useState('')
    const [normalCoupon, setNormalCoupon] = useState('')
    const [zeroCoupon, setZeroCoupon] = useState('')
    const [tabIndex, setTabIndex] = useState('')
    const [open, setOpen] = useState(false);
    const [viewId, setViewID] = useState('')


    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();


    useEffect(() => {
        const getCoupon = async () => {
            try {
                const { data } = await viewCoupons()
                // console.log(data, '12355');
                // setCoupons(data.coupon)

                const normalCpn = data.coupon.filter((data) => (

                    data?.type !== 'zeroCoupon'
                ))
                // console.log(adUser,'adminuserss');
                setNormalCoupon(normalCpn)
                const zeroCpn = data.coupon.filter((data) => (
                    data?.type === 'zeroCoupon'
                ))
                setZeroCoupon(zeroCpn)
                setLoader(false)
            } catch (error) {
                // console.log(error.message);
            }
        }
        getCoupon()
    }, [change])

    const handleEdit = (id) => {
        // const id = drawerData._id
        navigate("/edit-coupon", { state: id });
    };

    const handleDeactive = async (id) => {

        try {
            setLoader(true)
            const { data } = await deactiveCoupon(id);
            // console.log(data);
            if (data.block) {
                // console.log("sucess blocked");
                // setCardStatus("blocked")
                setLoader(false)
            }
            if (data.active) {
                // setCardStatus("active")
                setLoader(false)
            }
            setChange(Date.now())
        } catch (error) {
            console.log(error);
            toast(error.response.data.message);
        }
    };

    const handleDelete = async (id) => {
        try {
            setLoader(true)
            const { data } = await deleteCoupon(id);
            // console.log(data);
            if (data.delete) {
                // console.log("sucess delete");
                setChange(Date.now())
                setLoader(false)
            }
        } catch (error) {
            console.log(error);
        }
    };

    const showDeactiveConfirm = (data) => {
        confirm({

            title: `${data?.status === 'active' ? 'Are you sure to Block ' : 'Are you sure to Active'}`,
            icon: <ExclamationCircleFilled />,
            content: `${data?.status === 'active' ? 'if you block, the coupon cannot use any more ' : 'if you activate, all can use the coupon.'}`,
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                handleDeactive(data._id);
                // { cardStatus === "active" ? setCardStatus("deactive") : setCardStatus("active") }
            },
            onCancel() {
                console.log("Cancel");
            },
        });



    };

    const showDeleteConfirm = (data) => {
        confirm({
            title: "Are you sure delete?",
            icon: <ExclamationCircleFilled />,
            // content: `${admin ? 'if you delete the card the user also get deleted.' : ' }` ,
            content: `if you delete, the coupon will deleted`,
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                handleDelete(data._id);
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        className='bg-blue-800'
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    {/* <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button> */}
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            handleSearch(selectedKeys, confirm)
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    const showDrawer = async (ID) => {

        setViewID(ID)
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    const success = () => {

        messageApi.open({
            type: 'success',
            content: 'Copy to Clipboard',
        });
    };

    const columns = [
        {
            title: 'No.',
            dataIndex: '_id',
            key: 'index',
            // render: (text, record, index) => index + 1,
            render: (text, record, index) => (normalCoupon.indexOf(record) + 1),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name'),
        },

        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            ...getColumnSearchProps('code'),
        },
        {
            title: 'Value',
            dataIndex: 'status._id',
            key: '_id',
            render: (status, _id) => {
                // Check if data is undefined or null
                if (!_id || !_id.value) {
                    return <p>Data is missing or invalid.</p>;
                }

                const { BusniessQR, reviewQR, digitalInterface } = _id.value;

                return (
                    <div>
                        <h2 className='font-semibold'>Business QR Values:</h2>
                        <ul>
                            {BusniessQR && Object.entries(BusniessQR).map(([key, value]) => (
                                <li key={key}>{`${key}: ${value}`}</li>
                            ))}
                        </ul>

                        <h2 className='font-semibold'>Review QR Values:</h2>
                        <ul>
                            {reviewQR && Object.entries(reviewQR).map(([key, value]) => (
                                <li key={key}>{`${key}: ${value}`}</li>
                            ))}
                        </ul>

                        <h2 className='font-semibold'>Digital Interface Value:</h2>
                        <p>{`Value: ${digitalInterface || 'N/A'}`}</p>
                    </div>
                );
            }
        },
        {
            title: 'Sales Person',
            dataIndex: 'status._id',
            key: '_id',
            render: (status, _id) => {

                return (
                    <>
                        <p><span className='font-semibold'>Name:</span> {_id?.salesPerson?.name}</p>
                        <p><span className='font-semibold'>Email:</span> {_id?.salesPerson?.email}</p>
                        <p><span className='font-semibold'>Phone:</span>: {_id?.salesPerson?.phone}</p>
                    </>

                )
            }

        },
        {
            title: 'Expiry Date',
            dataIndex: 'status._id',
            key: '_id',
            render: (status, _id) => {
                const ExpiryDate = new Date(Moment(_id.createdDate) + _id.expiryDate * 24 * 60 * 60 * 1000)

                return (
                    <p>
                        {Moment() < ExpiryDate ?
                            Moment(ExpiryDate).format("Do MMM YYYY")
                            :
                            <p className="text-sm text-red-500 font-bold">Expired</p>
                        }
                    </p>

                )
            }

        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            // ...getColumnSearchProps('status'),
            render: (status) => { return status === 'active' ? <p className=' text-center'>Active</p> : <p className='bg-red-500 px-1 text-white text-center rounded-xl'>Blocked</p> },
        },
        {
            title: 'Action',
            dataIndex: 'status._id',
            key: '_id',
            render: (status, _id) => {
                return (
                    <>
                        <div className='flex gap-3 items-center'>
                            <Tooltip title="view"><FaEye size={20} className='cursor-pointer' onClick={() => showDrawer(_id)} /></Tooltip>

                            <Tooltip title="edit"><FaEdit size={20} className='cursor-pointer' onClick={() => handleEdit(_id)} /></Tooltip>
                            {_id.status === 'active' ?
                                <Tooltip title="block"><CgBlock size={28} className='cursor-pointer' onClick={() => showDeactiveConfirm(_id)} /></Tooltip>
                                :
                                <Tooltip title="unblock"> <CgUnblock size={20} className='cursor-pointer' onClick={() => showDeactiveConfirm(_id)} /></Tooltip>
                            }
                            <Tooltip title="delete"><MdDelete size={20} className='cursor-pointer' onClick={() => showDeleteConfirm(_id)} /></Tooltip>
                        </div>
                    </>
                )
            }

        },

    ];
    const columnsZero = [
        {
            title: 'No.',
            dataIndex: '_id',
            key: 'index',
            // render: (text, record, index) => index + 1,
            render: (text, record, index) => (zeroCoupon.indexOf(record) + 1),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name'),
        },

        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            render: (code) => (
                <div className='flex'>
                    <p id='cpCode'>{code}</p>
                    <div className='cursor-pointer ml-3' onClick={() => { navigator.clipboard.writeText(document.getElementById('cpCode').innerHTML); success() }}>

                        <svg style={{ color: "black", width: '25px' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28"><path d="M16,20H8a3,3,0,0,1-3-3V7A1,1,0,0,0,3,7V17a5,5,0,0,0,5,5h8a1,1,0,0,0,0-2Zm-6-7a1,1,0,0,0,1,1h5a1,1,0,0,0,0-2H11A1,1,0,0,0,10,13ZM21,8.94a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19.32.32,0,0,0-.09,0A.88.88,0,0,0,14.05,2H10A3,3,0,0,0,7,5V15a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V9S21,9,21,8.94ZM15,5.41,17.59,8H16a1,1,0,0,1-1-1ZM19,15a1,1,0,0,1-1,1H10a1,1,0,0,1-1-1V5a1,1,0,0,1,1-1h3V7a3,3,0,0,0,.18,1H11a1,1,0,0,0,0,2h8Z" fill="black"></path></svg>
                    </div>
                </div>
            )
        },
        {
            title: 'Expiry Date',
            dataIndex: 'status._id',
            key: '_id',
            render: (status, _id) => {
                const ExpiryDate = new Date(Moment(_id.createdDate) + _id.expiryDate * 24 * 60 * 60 * 1000)

                return (
                    <p>
                        {Moment() < ExpiryDate ?
                            Moment(ExpiryDate).format("Do MMM YYYY")
                            :
                            <p className="text-sm text-red-500 font-bold">Expired</p>
                        }
                    </p>

                )
            }

        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            // ...getColumnSearchProps('status'),
            render: (status) => { return status === 'active' ? <p className=' text-center'>Active</p> : <p className='bg-red-500 px-1 text-white text-center rounded-xl'>Blocked</p> },
        },
        {
            title: 'Action',
            dataIndex: 'status._id',
            key: '_id',
            render: (status, _id) => {
                return (
                    <>
                        <div className='flex gap-3 items-center'>
                            <Tooltip title="view"><FaEye size={20} className='cursor-pointer' onClick={() => showDrawer(_id)} /></Tooltip>

                            <Tooltip title="edit"><FaEdit size={20} className='cursor-pointer' onClick={() => handleEdit(_id)} /></Tooltip>
                            {_id.status === 'active' ?
                                <Tooltip title="block"><CgBlock size={28} className='cursor-pointer' onClick={() => showDeactiveConfirm(_id)} /></Tooltip>
                                :
                                <Tooltip title="unblock"> <CgUnblock size={20} className='cursor-pointer' onClick={() => showDeactiveConfirm(_id)} /></Tooltip>
                            }
                            <Tooltip title="delete"><MdDelete size={20} className='cursor-pointer' onClick={() => showDeleteConfirm(_id)} /></Tooltip>
                        </div>
                    </>
                )
            }

        },

    ];

    const onChange = (key) => {
        console.log(key);
        setTabIndex(key)
    };

    const items = [
        {
            key: '1',
            label: `Coupons`,
            children: [
                <>
                    <Table columns={columns} loading={loader} dataSource={normalCoupon} pagination={normalCoupon.length > 10 ? true : false} />
                    <CouponCardDrawer cardViewId={viewId} openCard={open} onCloseCard={onClose} users={'users'} />

                </>


            ],
        },
        {
            key: '2',
            label: `Free Coupon`,
            children: [
                <>
                    <Table columns={columnsZero} loading={loader} dataSource={zeroCoupon} pagination={zeroCoupon.length > 10 ? true : false} />
                    <CouponCardDrawer cardViewId={viewId} openCard={open} onCloseCard={onClose} users={'users'} />
                </>

            ],
        },
        // {
        //     key: '3',
        //     label: `All Coupons`,
        //     children: [
        //         <Table columns={columns} loading={loader} dataSource={coupons} pagination={coupons.length > 10 ? true : false} />

        //     ],
        // },
    ];

    const showZeroCouponConfirm = (data) => {
        confirm({

            title: 'Are you sure to Generate Zero Coupon',
            icon: <ExclamationCircleFilled />,
            content: 'if you generate, with this coupon people can purchase with zero amount.The validity of free coupon is only 1 day',
            okText: "Generate",
            okType: "danger",
            cancelText: "Cancel",
            onOk() {
                generateZeroCoupon()
                // { cardStatus === "active" ? setCardStatus("deactive") : setCardStatus("active") }
            },
            onCancel() {
                console.log("Cancel");
            },
        });



    };

    const generateZeroCoupon = async () => {

        try {
            const { data } = await createZeroCoupon()
            console.log(data);
            if (data.success) {
                setZeroActiveCoupon(data.coupon)
                setChange(Date.now())
                toast.success('New Zero Coupon Created', {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "dark",
                });
            }
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <div>
            <Row gutter={16}>
                <Col span={11}>
                    <Title level={2}>Coupons </Title>
                </Col>
                <Col span={8} className='text-right'>
                    {tabIndex === '2' ?
                        <div className='flex gap-3 justify-between'>
                            <div>
                                {zeroAvtiveCoupon.code ?
                                    <>
                                        <p> Free coupon available</p>
                                        <p>{zeroAvtiveCoupon.code}</p>
                                    </>
                                    : ''
                                }
                            </div>
                            <Button className='h-[38px]' onClick={showZeroCouponConfirm}>Generate Free Coupon</Button>
                        </div>
                        : ''
                    }
                </Col>
                <Col span={4} className='text-right'>
                    <Link className='add-button rounded-lg ml-auto' to="/create-coupon"><PlusOutlined />Create Coupon</Link>
                </Col>
            </Row>

            <div className='flex justify-center min-h-screen'>
                <div className='w-full mt-5'>
                    {/* <Table columns={columns} loading={loader} dataSource={coupons} pagination={coupons.length > 10 ? true : false} /> */}
                    <Tabs defaultActiveKey="1" items={items} onChange={onChange} />

                </div>
            </div>
            <ToastContainer />
            {contextHolder}
        </div>
    )
}

export default ViewCoupon