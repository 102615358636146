import React from 'react'
import VisitorsList from '../components/visitorsList/VisitorsList'
import Sidebar from '../components/sidebar/Sidebar'
import { Layout } from 'antd';
const { Content } = Layout;

function VisitiorsListPage() {
    return (
        <div>
            <Layout hasSider>
                {/* <div className='h-screen'> */}

                <Sidebar />
                {/* </div> */}
                <Layout style={{ marginLeft: 200, }}  >
                    <Content style={{ margin: '24px 16px', padding: 24, minHeight: 280, background: 'white', }} >
                        <VisitorsList />
                    </Content>
                </Layout>
            </Layout>
        </div>
    )
}

export default VisitiorsListPage