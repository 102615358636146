import React, { useEffect, useState } from "react";
import { Avatar, Divider, Drawer, Modal, Spin, Button } from "antd";
// import {
//   DeactiveCard,
//   DeleteCard,
//   getSigleCardData,
// } from "../../../api/AdminRequest";
// import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ExclamationCircleFilled } from "@ant-design/icons";
// import { editData } from "../../../Redux/Actions/CardAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DeactiveCard, DeleteCard } from "../../../api/AdminRequest";
import Moment from 'moment'

const { confirm } = Modal;
const defaultProfileImage = 'https://zeeqr-files.s3.ap-south-1.amazonaws.com/assets/defaultProfile.jpg'

function CardDetailsDrawer({ onClose, viewId, open, setChange }) {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const [drawerData, setDrawerData] = useState("");
  const [cardStatus, setCardStatus] = useState("");
  const [loader, setLoader] = useState(true)
  const [delLoder, setDelLoder] = useState(false)
  const [statuslLoder, setStatuslLoder] = useState(false)



  useEffect(() => {
    const singleBookedCards = async () => {
      try {
        // const { data } = await getSigleCardData(viewId);
        // console.log(data);
        // if (data.success) {
        // setDrawerData(data.card);
        setDrawerData(viewId);
        setCardStatus(viewId.status)
        setLoader(false)

        // }
      } catch (error) {
        console.log(error);
      }
    };
    singleBookedCards();
  }, [viewId]);

  const handleSubmit = () => {
    // dispatch(editData(drawerData));
    // const id = drawerData._id
    // if (admin) {

    //   navigate("/admin-editCard", { state: { id } });
    // } else {
    //   navigate("/edit-card", { state: { id } });
    // }
  };

  const handleDeactive = async () => {

    try {
      setStatuslLoder(true)
      const { data } = await DeactiveCard(drawerData._id);
      console.log(data);
      if (data.deactive) {
        console.log("sucess delete");
        setCardStatus("deactive")
        setStatuslLoder(false)
        setChange(Date.now())
        onClose()
      }
      if (data.active) {
        setCardStatus("active")
        setStatuslLoder(false)
        setChange(Date.now())
        onClose()
      }
    } catch (error) {
      console.log(error);
      toast(error.response.data.message);
    }
  };

  const handleDelete = async () => {
    try {
      setDelLoder(true)
      const { data } = await DeleteCard(drawerData._id);
      console.log(data);
      if (data.success) {
        console.log("sucess delete");
        setChange(Date.now())
        onClose()
        setDelLoder(false)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showDeactiveConfirm = () => {
    confirm({

      title: `${cardStatus === 'active' ? 'Do you Want to Deactive the Card ' : 'Do you Want to Active the Card'}`,
      icon: <ExclamationCircleFilled />,
      content: `${cardStatus === 'active' ? 'Profile will be deactivated ' : 'Profile will be activated'}`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDeactive();
        { cardStatus === "active" ? setCardStatus("deactive") : setCardStatus("active") }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const showDeleteConfirm = () => {
    confirm({
      title: "Are you sure delete this Card?",
      icon: <ExclamationCircleFilled />,
      // content: `${admin ? 'if you delete the card the user also get deleted.' : ' }` ,
      // content: `${admin ? 'if you delete the card, the user also get deleted. ' : 'if you delete the card, the get deleted.'}`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  // const socMedia = [
  //   { name: 'whatsappNumber', link: drawerData.whatsappNumber },
  //   { name: 'facebook', link: drawerData.facebook },
  //   { name: 'instagram', link: drawerData.instagram },
  //   { name: 'twitter', link: drawerData.twitter },
  //   { name: 'youtube', link: drawerData.youtube },
  //   { name: 'linkedin', link: drawerData.linkedin },
  //   { name: 'skype', link: drawerData.skype },
  //   { name: 'tiktok', link: drawerData.tiktok },
  //   { name: 'snapchat', link: drawerData.snapchat },
  // ]

  const address = [
    { name: 'Address', data: drawerData.address },
    { name: 'State', data: drawerData.state },
    { name: 'Country', data: drawerData.country },
  ]

  const company = [
    { name: 'Name', data: drawerData.companyName },
    { name: 'Designation', data: drawerData.companyDesignation },
    // { name: 'Website Name', data: drawerData.websiteName },
    // { name: 'Url', data: drawerData.websiteUrl },
  ]

  const personalDetails = [
    { name: 'Full Name', data: drawerData.name },
    { name: 'Status', data: cardStatus },
    { name: 'Email', data: drawerData.email },
    { name: 'Phone', data: drawerData.phone },
    { name: 'Created Date', data:  Moment(drawerData.date).format("Do MMM YYYY") },
    { name: 'About', data: drawerData.about },
  ]

  const handleOrder = () => {
    const id = drawerData._id
    navigate('/order-card', { state: { id } })
  }

  return (
    <div>

      <Drawer
        width={640}
        placement="right"
        closable={false}
        onClose={onClose}
        open={open}

      >

        <Spin spinning={loader}>

          <div className="flex justify-between">
            <p
              className="site-description-item-profile-p text-xl font-bold"
              style={{
                marginBottom: 24,
              }}
            >
              User Profile
            </p>
            {drawerData?.userID?.adminID ?
              ''
              :
              <div>
                <Button
                  className="text-white bg-red-600 hover:bg-red-700 w-28"
                  onClick={showDeleteConfirm}
                  loading={delLoder}
                >
                  Delete
                </Button>
                <Button
                  className="text-white bg-slate-600 hover:bg-slate-700 ml-2 w-28 "
                  onClick={showDeactiveConfirm}
                  loading={statuslLoder}
                >
                  {cardStatus === "deactive" ? "Active" : "Deactive"}
                </Button>

              </div>
            }
          </div>
          <Avatar shape="square" size={64} src={drawerData?.profileImage ? drawerData?.profileImage : defaultProfileImage} />

          <p className="site-description-item-profile-p font-bold text-sm mt-3">Personal</p>
          <div className="grid grid-cols-2">
            {personalDetails.map((obj) => {
              return (
                <div className="mt-3">
                  {obj.data ?
                    <div className="flex ">
                      <p className="font-medium mr-2">{obj.name} : </p>
                      <p  > {obj.data}</p>
                    </div>
                    : ''
                  }
                </div>
              )
            })}
          </div>
          {/* <Row className="pt-2">
            <Col span={12} >
              <p>FullName : {drawerData?.name}</p>
            </Col>
            <Col span={12}>
              <p>Card Status : {cardStatus}</p>
            </Col>
          </Row>
          <Row className="pt-2">
            <Col span={12} >
              <p>Email : {drawerData?.email}</p>
            </Col>
            <Col span={12}>
              <p>Phone : {drawerData?.phone}</p>
            </Col>
          </Row>
          <Row className="pt-2">
            <Col span={12} >
              <p>About : {drawerData?.about}</p>
            </Col>

          </Row> */}

          <Divider />
          <p className="site-description-item-profile-p font-bold text-sm  ">Company</p>
          <div className="grid grid-cols-2">
            {company.map((obj) => {
              return (
                <div className="mt-3">
                  {obj.data ?
                    <div className="flex ">
                      <p className="font-medium mr-2">{obj.name} : </p>
                      <p  > {obj.data}</p>
                    </div>
                    : ''
                  }
                </div>
              )
            })}
          </div>
          {/* <Row className="pt-2">
            <Col span={12} >
              <p>Name : {drawerData?.companyName}</p>
            </Col>
            <Col span={12}>
              <p>Designation : {drawerData?.companyDesignation}</p>
            </Col>
          </Row>
          <Row className="pt-2">
            {drawerData?.websiteUrl && drawerData?.websiteName ?
              <Col span={12}>
                <p>
                  Website : {" "}
                  <a href={drawerData?.websiteUrl}>{drawerData?.websiteName}</a>
                </p>
              </Col>
              : ''
            }

          </Row> */}

          <Divider />
          {drawerData?.address || drawerData?.state || drawerData?.country ?
            <>
              <p className="site-description-item-profile-p font-bold text-sm " >Contacts</p>
              <div className="grid grid-cols-2">
                {address.map((obj) => {
                  return (
                    <div className="mt-3">
                      {obj.data ?
                        <div className="flex ">
                          <p className="font-medium mr-2">{obj.name} : </p>
                          <p  > {obj.data}</p>
                        </div>
                        : ''
                      }
                    </div>
                  )
                })}
              </div>

            </>
            : ''
          }




          {/* <Divider /> */}
          {/* {drawerData?.whatsappNumber || drawerData?.facebook || drawerData?.instagram || drawerData?.twitter || drawerData?.youtube || drawerData?.skype || drawerData?.tiktok || drawerData?.snapchat || drawerData?.linkedin ?
            <div>
              <p className="site-description-item-profile-p font-bold text-sm  ">Social Media</p>

              {socMedia.map((obj) => {
                return (
                  <div className="mt-3">
                    {obj.link ?
                      <div className="flex">
                        <p className="font-medium mr-2">{obj.name} : </p>
                        <p  > {obj.link}</p>
                      </div>
                      : ''
                    }
                  </div>
                )
              })}
            </div> : ''
          } */}

          {/* <Divider /> */}

          {/* {admin ? '' :
            <Button
              onClick={handleOrder}
              type="button"
              className="text-white bg-orange-600 hover:bg-orange-700 ml-2 w-44 "
            >
              Order Physical Card
            </Button>
          } */}
        </Spin>

      </Drawer>

      <ToastContainer />
    </div>
  );
}

export default CardDetailsDrawer;
