import React, { useEffect, useState } from "react";
import { Drawer, Spin, } from "antd";
import { ToastContainer, } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "moment";


// const { confirm } = Modal;

function ProductNewDrawer({ onClose, viewId, open, setChanges }) {
  // const navigate = useNavigate();

  const [loader, setLoader] = useState(true)

  const [productDetails, setProductDetails] = useState('')
  // console.log(productDetails, 'jjdjdim');

  const card2 = "https://metatestbucket.s3.ap-south-1.amazonaws.com/95d34196acac3e493e1a83efcd07df855f3959a2115f868c4d2e7ef1a3c7db18";
  const card1 = "https://metatestbucket.s3.ap-south-1.amazonaws.com/4a5a441198cf155f2a5891b2f3c3b97946b30bcf9d97d454203f4724850b06e0";

  // console.log(viewId, 'qwerty');

  useEffect(() => {
    const singleProduct = async () => {
      try {

        setProductDetails(viewId)
        setLoader(false)
      } catch (error) {
        console.log(error);
      }
    };
    singleProduct();
  }, [viewId]);



  const Details = [
    { name: 'Name', data: productDetails.productName },
    { name: 'Product Type', data: productDetails.productType },
    { name: 'Subtittle', data: productDetails.subtittle },
    { name: 'vendor', data: productDetails.vendor },
    { name: 'Status', data: productDetails.status },
    { name: 'Created Date', data: Moment(productDetails.createdDate).format("Do MMM YYYY") },
    { name: 'Description', data: productDetails.description },

  ]

  return (
    <div>
      <Drawer
        width={600}
        placement="right"
        closable={false}
        onClose={onClose}
        open={open}
      >
        <Spin spinning={loader}>

          <div >
            <p
              className="site-description-item-profile-p text-xl font-bold"
              style={{
                marginBottom: 24,
              }}
            >
              Product  Details
            </p>

            <div >
              {Details.map((obj) => {
                return (
                  <div className="mt-3">
                    {obj.data ?
                      <div className="flex">
                        <p className="font-medium mr-2 ">{obj.name}: </p>
                        <p  > {obj.data}</p>
                      </div>
                      : ''
                    }
                  </div>
                )
              })}

            </div>
            <div className="my-3">

              <p className="text-lg font-semibold">Products Offers</p>
              <div className="flex mt-2">
                <p className="font-medium mr-2 ">Tittle: </p>
                <p  > {productDetails.productOffers?.tittle}</p>
              </div>
              <div className="flex mt-2">
                <p className="font-medium mr-2 ">offers: </p>
                <p >
                  {productDetails.productOffers?.offers.map((obj) => (
                    <p>{obj}</p>
                  ))}
                </p>
              </div>
            </div>
            <div className="my-3">

              <p className="text-lg font-semibold">Products Highlight</p>
              <div className="flex mt-2">
                <p className="font-medium mr-2 ">Tittle: </p>
                <p  > {productDetails.highlights?.tittle}</p>
              </div>
              <div className=" mt-2">
                <p className="font-medium mr-2 mb-2">Medias </p>
                <p >
                  {productDetails.highlights?.medias.map((obj) => (
                   <img  src={obj?.url} alt="" />
                  ))}
                </p>
              </div>
            </div>

          </div>

        </Spin>
        <ToastContainer />
      </Drawer>

    </div>
  );
}

export default ProductNewDrawer;
