import React, { useState } from 'react'
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Typography,
} from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { createUser, editUser } from '../../api/AdminRequest';
const { Title } = Typography;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },

};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

function CreateUser({ edit }) {

  const navigate = useNavigate();

  const { state } = useLocation();
  // console.log(state?.drawerData, '************************');
  const [userData] = useState(edit ? state.drawerData : '')


  const [phone, setPhone] = useState(edit ? '' + state.drawerData.phone : '971')
  const [form] = Form.useForm();
  const onFinish = async (values) => {

    // console.log('Received values of phone: ', phone);
    values.phone = phone
    console.log('Received values of form: ', values);
    try {
      const { data } = edit ? await editUser(userData._id, values) : await createUser(values)
      console.log(data);
      if (data.success) {
        toast.success('New User Created', {
          position: "top-right",
          autoClose: 3000,
          theme: "dark",
        });
      } else if (data.update) {
        toast.success('Updated Successfully', {
          position: "top-right",
          autoClose: 3000,
          theme: "dark",
        });

      }
      setTimeout(() => {
        navigate('/active-users')
      }, 3000)
    } catch (error) {
      console.log(error.message);
      toast(error.message)
    }
  };

  const passRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/


  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Title level={2}>{edit ? 'Edit User' : 'Create User'}</Title>
        </Col>
      </Row>

      <div className='flex h-screen justify-center mt-14'>

        {/* <div> */}
        {/* <h1 className='text-center  text-xl font-bold'>Create Admin</h1> */}

        <Form
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={onFinish}
          initialValues={{
            name: userData.name,
            email: userData.email,
            userType: userData.userType
          }}
          style={{
            // maxWidth: 600,
            width: 600
          }}
          scrollToFirstError
        >
          <div className='grid grid-cols-2 gap-2 '>
            <div>
              <label htmlFor="" className='text-xl font-semibold'>Name </label>
              <Form.Item
                name="name"
                // label="Name "
                className='mb-2'
                tooltip="What do you want others to call you?"
                rules={[
                  {
                    required: true,
                    message: 'Please input your name!',
                    whitespace: true,
                  },
                ]}
              >

                <Input className='py-2' />
              </Form.Item>
            </div>
            <div>
              <label htmlFor="" className='text-xl font-semibold'>E-mail</label>
              <Form.Item
                name="email"
                // label="E-mail"
                className='mb-2'
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                  {
                    required: true,
                    message: 'Please input your E-mail!',
                  },
                ]}
              >

                <Input className='py-2' />
              </Form.Item>
            </div>
            <div>
              <label htmlFor="" className='text-xl font-semibold'>Phone Number</label>
              <PhoneInput country={"eg"} enableSearch={true} value={phone} onChange={(phone) => setPhone(phone)} inputStyle={{ width: '300px', height: '10px' }} />
            </div>


            <div>
              <label htmlFor="" className='text-xl font-semibold'> User Type</label>
              <Form.Item
                name="userType"
                // label="E-mail"
                className='mb-2'
                rules={[
                  {
                    required: true,
                    message: 'Please input your User Type!',
                  },
                ]}
              >

                <Select size='large' >
                  <Select.Option value="demo">normal</Select.Option>
                </Select>
              </Form.Item>
            </div>
          </div>

          {edit ? "" :
            <>
              <div className='grid grid-cols-2 gap-2 '>
                <div>
                  <label htmlFor="" className='text-xl font-semibold'>Password</label>
                  <Form.Item
                    name="password"
                    //   label="Password"
                    className='mb-2'
                    rules={[

                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                      {
                        pattern: passRegex,
                        message: 'password should contain Minimum 8 characters, at least one number, one uppercase and one lowercase letter and one special character!',
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password className='py-2' />
                  </Form.Item>

                </div>
                <div>
                  <label htmlFor="" className='text-xl font-semibold'>Confirm Password</label>
                  <Form.Item
                    name="confirm"
                    // label="Confirm Password"
                    className='mb-2'
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password!',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('The two passwords that you entered do not match!'));
                        },
                      }),
                    ]}
                  >

                    <Input.Password className='py-2' />
                  </Form.Item>
                </div>
              </div>

              <Form.Item
                name="agreement"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
                  },
                ]}
                {...tailFormItemLayout}
              >
                <Checkbox>
                  I have read the <Link href="">agreement</Link>
                </Checkbox>
              </Form.Item>
            </>
          }
          <Form.Item {...tailFormItemLayout} className='flex justify-center  mr-14'>
            <Button className='bg-blue-800 w-32 mt-2 ' type="primary" htmlType="submit">
              {edit ? 'Save' : ' Register'}
            </Button>
          </Form.Item>
        </Form>
        {/* </div> */}
        <ToastContainer />
      </div>
    </>
  );
}

export default CreateUser