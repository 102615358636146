import { CloseOutlined, MinusCircleOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Divider, Input, Select, Space, Tooltip } from 'antd'
import React, { useState } from 'react'
import { useRef } from 'react'

function AddMaterial() {

    const defaultItems = [
        {
            value: 'BusniessQR',
            label: 'Business QR',
        },
        {
            value: 'reviewQR',
            label: 'Review QR',
        },
    ]
    const defaultMaterials = [
        {
            value: 'PVC_NC',
            label: 'PVC_NC',
        },
        {
            value: 'PVC',
            label: 'PVC',
        },
        {
            value: 'Wooden',
            label: 'Wooden',
        },
        {
            value: 'Metal',
            label: 'Metal',
        },
        {
            value: 'Luxury',
            label: 'Luxury',
        },
    ]
    // const initialPrice = [{ cardType: null, price: "" }]
    const initialMaterials = [{
        material: null,
        price: [
            {
                cardType: null,
                price: ''
            }
        ]
    }]

    // const initialMaterials = [{
    //     material: null,
    //     price: {
    //         key: ''
    //     }
    // }]

    const inputRef = useRef(null);

    const [name, setName] = useState('');
    const [items, setItems] = useState(defaultItems);
    const [defMaterials, setDefMaterials] = useState(defaultMaterials)
    const [materialsArray, setMaterialsArray] = useState(initialMaterials)

    // const [material, setMaterial] = useState()
    // const [price, setPrice] = useState()
    // const [priceObject, setPriceObject] = useState(initialPrice)


    let addFormFields = () => {
        setMaterialsArray([...materialsArray, {
            material: null,
            price: [
                {
                    cardType: null,
                    price: ''
                }
            ]

        }]);
    };

    let removeFormFields = (i) => {
        let newFormValues = [...materialsArray];
        newFormValues.splice(i, 1);
        setMaterialsArray(newFormValues);
    };

    const addPriceFields = (index) => {
        setMaterialsArray((prevMaterialsArray) => {
            const updatedMaterialsArray = [...prevMaterialsArray];
            const newPriceObject = {
                cardType: null,
                price: null
            };

            // Add the newPriceObject to the price array at the specified index
            updatedMaterialsArray[index].price.push(newPriceObject);

            return updatedMaterialsArray;
        });
    };

    const removePriceFields = (index, priceIndex) => {
        setMaterialsArray((prevMaterialsArray) => {
            const updatedMaterialsArray = [...prevMaterialsArray];

            // Remove the price object at the specified priceIndex within the price array
            updatedMaterialsArray[index].price.splice(priceIndex, 1);

            return updatedMaterialsArray;
        });
    };


    const onNameChange = (event) => {
        setName(event.target.value);
    };
    const addItem = (e) => {
        e.preventDefault();
        // Validation: Check if the item already exists or if the name is empty
        if (!name || defMaterials.some(item => item?.value === name)) {
            // Handle validation error (you can show a message, etc.)

            return;
        }
        setDefMaterials([...defMaterials, { label: name, value: name }]);
        setName('');
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };


    const removeItem = (removedItem) => {
        // Filter out the removed item
        const updatedItems = defMaterials.filter((item) => item.value !== removedItem.value);
        setDefMaterials(updatedItems);
        // setMaterial('')
    };

    const getOptionLabel = (selectedItem) => {
        if (defaultMaterials.some((item) => item.value === selectedItem.value)) {
            // For default items, exclude the delete button
            return <span>{selectedItem.label}</span>;
        }

        // For dynamically added items, include the delete button
        return (
            <div className='flex justify-between items-center'>
                <p>{selectedItem.label}</p>

                <CloseOutlined
                    onClick={() => removeItem(selectedItem)}
                    style={{ cursor: 'pointer', marginLeft: '8px', color: 'rgba(0, 0, 0, 0.25)' }}
                />
            </div>
        );
    };

    const addPriceItem = (e) => {
        e.preventDefault();
        // Validation: Check if the item already exists or if the name is empty
        if (!name || items.some(item => item?.value === name)) {
            // Handle validation error (you can show a message, etc.)

            return;
        }
        setItems([...items, { label: name, value: name }]);
        setName('');
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };


    const removePriceItem = (removedItem) => {
        // Filter out the removed item
        const updatedItems = items.filter((item) => item.value !== removedItem.value);
        setItems(updatedItems);
        // setPrice('')
    };

    const getPriceOptionLabel = (selectedItem) => {
        if (defaultItems.some((item) => item.value === selectedItem.value)) {
            // For default items, exclude the delete button
            return <span>{selectedItem.label}</span>;
        }

        // For dynamically added items, include the delete button
        return (
            <div className='flex justify-between items-center'>
                <p>{selectedItem.label}</p>

                <CloseOutlined
                    onClick={() => removePriceItem(selectedItem)}
                    style={{ cursor: 'pointer', marginLeft: '8px', color: 'rgba(0, 0, 0, 0.25)' }}
                />
            </div>
        );
    };

    const handleChangeMaterials = (field, index, value, priceIndex, keyName) => {
        // console.log(priceIndex, 'orice index');
        setMaterialsArray((prevMaterialsArray) => {
            const updatedMaterialList = [...prevMaterialsArray];
            // console.log(updatedMaterialList, '55555');

            if (field === 'price') {
                // Update the price value at the specified priceIndex
                // updatedMaterialList[index][field][value] = '';
                updatedMaterialList[index][field][priceIndex][keyName] = value;
            } else {
                // Update other fields
                updatedMaterialList[index][field] = value;
            }

            return updatedMaterialList;
        });
    };

    // const handleChangePrice = (field, index, value) => {
    //     const updatedPriceList = [...priceObject];
    //     updatedPriceList[index][field] = value;
    //     setPriceObject(updatedPriceList);
    // };

    // console.log(priceObject);
    // console.log(materialsArray, 'tttttt');
    const transformedMaterials = materialsArray.map(({ material, price }) => {
        const priceObject = {};
        price.forEach(({ cardType, price }) => {
            priceObject[cardType] = price;
        });

        return {
            key: material,
            price: priceObject,
        };
    });

    console.log(transformedMaterials,'00000');

    return (
        <div>
            <>
                <div className='flex gap-5'>

                    <h1 className='font-bold text-xl my-4'>Materials</h1>
                    <button
                        type="button"
                        className=""
                        onClick={() => addFormFields()}
                    >
                        <Tooltip placement="top" title="Add More">
                            <PlusCircleOutlined
                                style={{ fontSize: "20px", color: "green" }}
                            />
                        </Tooltip>
                    </button>
                </div>
                {materialsArray?.map((element, index) => (
                    <div key={index}>
                        <div className='flex justify-between gap-3 mr-2'>
                            <div className='w-full'>
                                <label htmlFor="" className='text-xl font-semibold'>Material </label>
                                <Select
                                    style={{
                                        width: '100%',
                                    }}
                                    value={element?.material}
                                    size='large'
                                    placeholder="Select Product Type"
                                    onChange={(value) => handleChangeMaterials('material', index, value)}
                                    dropdownRender={(menu) => (
                                        <>
                                            {menu}
                                            <Divider
                                                style={{
                                                    margin: '8px 0',
                                                }}
                                            />
                                            <Space
                                                style={{
                                                    padding: '0 8px 4px',
                                                }}
                                            >
                                                <Input
                                                    placeholder="Please enter item"
                                                    ref={inputRef}
                                                    value={name}
                                                    onChange={onNameChange}
                                                    onKeyDown={(e) => e.stopPropagation()}
                                                />
                                                {(!name || defMaterials.some(item => item.value === name)) ?
                                                    <Button type="text" disabled icon={<PlusOutlined />} > Add item </Button>
                                                    :
                                                    <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                                                        Add item
                                                    </Button>
                                                }
                                            </Space>
                                        </>
                                    )}
                                //     options={defMaterials.map((item) => ({
                                //         label: getOptionLabel(item),
                                //         value: item.value,
                                //     }))}
                                // />
                                >
                                    {defMaterials?.map((item) => (
                                        <Select.Option
                                            key={item.value}
                                            value={item.value}
                                            disabled={materialsArray.some((feature) => feature.material === item.value)}
                                        >
                                            {getOptionLabel(item)}
                                        </Select.Option>
                                    ))}
                                </Select>
                                {/* <p className='text-red-500'>{error.productType}</p> */}
                            </div>
                            <div>
                                {index ? (
                                    <button
                                        type="button"
                                        className="mt-8"
                                        onClick={() => removeFormFields(index)}
                                    >
                                        <Tooltip placement="top" title="Remove">
                                            <MinusCircleOutlined
                                                style={{ fontSize: "20px", color: "red" }}
                                            />
                                        </Tooltip>
                                    </button>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                        <div className='my-3'>
                            {/* <label htmlFor="" className='text-xl font-semibold'>Features</label> */}
                            {element?.price?.map((key, priceIndex) => (
                                <div className="grid gap-5 md:grid-cols-2" key={priceIndex}>
                                    <div>
                                        <label
                                            for="first_name"
                                            className="block mb-2 text-sm font-medium text-gray-900 mt-3"
                                        >
                                            card Type
                                        </label>
                                        <Select
                                            style={{
                                                width: '100%',
                                            }}
                                            value={key.cardType}
                                            size='large'
                                            placeholder="Select Card Type"
                                            onChange={(value) => handleChangeMaterials('price', index, value, priceIndex, 'cardType')}
                                            dropdownRender={(menu) => (
                                                <>
                                                    {menu}
                                                    <Divider
                                                        style={{
                                                            margin: '8px 0',
                                                        }}
                                                    />
                                                    <Space
                                                        style={{
                                                            padding: '0 8px 4px',
                                                        }}
                                                    >
                                                        <Input
                                                            placeholder="Please enter item"
                                                            ref={inputRef}
                                                            value={name}
                                                            onChange={onNameChange}
                                                            onKeyDown={(e) => e.stopPropagation()}
                                                        />
                                                        {(!name || items.some(item => item.value === name)) ?
                                                            <Button type="text" disabled icon={<PlusOutlined />} > Add item </Button>
                                                            :
                                                            <Button type="text" icon={<PlusOutlined />} onClick={addPriceItem}>
                                                                Add item
                                                            </Button>
                                                        }
                                                    </Space>
                                                </>
                                            )}
                                        //   options={items.map((item) => ({
                                        //     label: getOptionLabel(item),
                                        //     value: item.value,
                                        //   }))}
                                        // />
                                        >
                                            {items?.map((item) => (
                                                <Select.Option
                                                    key={item.value}
                                                    value={item.value}
                                                    disabled={element?.price?.some((feature) => feature.cardType === item.value)}
                                                >
                                                    {getPriceOptionLabel(item)}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </div>
                                    <div className="flex gap-5 w-full">
                                        <div className="w-full">

                                            <>
                                                <label
                                                    for="first_name"
                                                    className="block mb-2 text-sm font-medium text-gray-900 mt-3"
                                                >
                                                    Price
                                                </label>
                                                <input
                                                    type="text"
                                                    name="price"
                                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                                    placeholder="Card Price"
                                                    value={key?.price || ""}
                                                    onChange={(e) => handleChangeMaterials('price', index, e.target.value, priceIndex, 'price')}
                                                />
                                            </>


                                        </div>
                                        {priceIndex ? (

                                            <button
                                                type="button"
                                                className="mt-8"
                                                onClick={() => removePriceFields(index, element.price.length - 1)}
                                            >
                                                <Tooltip placement="top" title="Remove">
                                                    <MinusCircleOutlined
                                                        style={{ fontSize: "20px", color: "red" }}
                                                    />
                                                </Tooltip>
                                            </button>
                                        ) : (

                                            <button
                                                type="button"
                                                className="mt-8"
                                                onClick={() => addPriceFields(index)}
                                            >
                                                <Tooltip placement="top" title="Add More">
                                                    <PlusCircleOutlined
                                                        style={{ fontSize: "20px", color: "green" }}
                                                    />
                                                </Tooltip>
                                            </button>
                                        )}

                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>
                ))}
            </>
        </div>
    )
}

export default AddMaterial