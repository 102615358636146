import { Button, Col, Form, Input, Row, Typography } from 'antd';
import React, { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { createCoupon, editCoupon } from '../../../api/AdminRequest';
import { useLocation, useNavigate } from 'react-router-dom';
const { Title } = Typography;

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    // wrapperCol: {
    //   xs: {
    //     span: 24,
    //   },
    //   sm: {
    //     span: 16,
    //   },
    // },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

function CreateCoupon({ edit }) {

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { state } = useLocation();
    // console.log(state, '************************');
    // const initialValues =
    const [formData] = useState({
        BusniessQR: {
            Luxury: '',
            Metal: '',
            PVC: '',
            PVC_NC: '',
            Wooden: '',
        },
        reviewQR: {
            Luxury: '',
            Metal: '',
            PVC: '',
            PVC_NC: '',
            Wooden: '',
        },
        digitalInterface: '',
        salesPerson: {
            name: '',
            email: '',
        }
    });

    const [phone, setPhone] = useState(edit ? '' + state?.salesPerson?.phone : '971')


    const onFinish = async (values) => {
        values.salesPerson.phone = phone
        // console.log('Form Values:', values);
        let id = state._id
        try {
            const { data } = edit ? await editCoupon(id, values) : await createCoupon(values)
            // console.log(data);
            if (data.success) {
                toast.success('New Coupon Created', {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "dark",
                });
            } else if (data.update) {
                toast.success('Updated Successfully', {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "dark",
                });

            }
            setTimeout(() => {
                navigate('/coupons')
            }, 3000)
        } catch (error) {
            console.log(error.message);
            toast(error.message)
        }
    }

    return (
        <div>
            <>
                <Row gutter={16}>
                    <Col span={12}>
                        <Title level={2}>{edit ? 'Edit Admin' : 'Create Admin'}</Title>
                    </Col>
                </Row>

                <div className='flex  justify-center mt-14 mb-10'>

                    {/* <div> */}
                    {/* <h1 className='text-center  text-xl font-bold'>Create Admin</h1> */}

                    <Form
                        {...formItemLayout}
                        form={form}
                        name="register"
                        onFinish={onFinish}
                        initialValues={{
                            couponName : state?.name,
                            couponCode: state?.code,
                            expiryDate: state?.expiryDate,
                            BusniessQR:state?.value.BusniessQR,
                            reviewQR:state?.value.reviewQR,
                            digitalInterface:state?.value.digitalInterface,
                            salesPerson:state?.salesPerson,
                        }}
                        style={{
                            // maxWidth: 600,
                            width: 600
                        }}
                        scrollToFirstError
                    >

                        <label htmlFor="" className='text-xl font-semibold'>Coupon Name </label>
                        <Form.Item
                            name="couponName"
                            // label="Name "
                            className='mb-2'
                            tooltip="What do you want others to call you?"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your coupon name!',
                                    whitespace: true,
                                },
                            ]}
                        >

                            <Input className='py-2' />
                        </Form.Item>

                        <div className='grid grid-cols-2 gap-2 '>
                            <div>
                                <label htmlFor="" className='text-xl font-semibold'>Coupon Code</label>
                                <Form.Item
                                    name="couponCode"
                                    // label="E-mail"
                                    className='mb-2'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Card Limit!',
                                        },
                                    ]}
                                >

                                    <Input className='py-2' />
                                </Form.Item>
                            </div>
                            <div>
                                <label htmlFor="" className='text-xl font-semibold'>Expiry Days</label>
                                <Form.Item
                                    name="expiryDate"
                                    // label="E-mail"
                                    className='mb-2'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Expiry Days!',
                                        },
                                    ]}
                                >

                                    <Input className='py-2' />
                                </Form.Item>
                            </div>
                        </div>

                        <div>
                            <h2 className='font-bold text-xl my-2'>Business QR Values:</h2>
                            {Object.entries(formData.BusniessQR).map(([key, value]) => (
                                <div key={key}>
                                    {/* <label>{key}:</label>
                                    <input
                                        type="text"
                                        value={value}
                                        onChange={(e) => handleChange('BusniessQR', key, e.target.value)}
                                    /> */}
                                    <div className='grid grid-cols-2 gap-2 '>
                                        <label htmlFor="" className='text-xl font-semibold'>{key}</label>
                                        <Form.Item
                                            name={['BusniessQR', key]}
                                            // label="E-mail"
                                            className='mb-2'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: `Please input your ${key}`,
                                                },
                                            ]}
                                        >

                                            <Input className='py-2' />
                                        </Form.Item>
                                    </div>
                                </div>
                            ))}

                            <h2 className='font-bold text-xl my-2'>Review QR Values:</h2>
                            {Object.entries(formData.reviewQR).map(([key, value]) => (
                                <div key={key}>
                                    {/* <label>{key}:</label>
                                    <input
                                        type="text"
                                        value={value}
                                        onChange={(e) => handleChange('reviewQR', key, e.target.value)}
                                    /> */}
                                    <div className='grid grid-cols-2 gap-2 '>
                                        <label htmlFor="" className='text-xl font-semibold'>{key}</label>
                                        <Form.Item
                                            name={['reviewQR', key]}
                                            // label="E-mail"
                                            className='mb-2'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: `Please input your ${key}`,
                                                },
                                            ]}
                                        >

                                            <Input className='py-2' />
                                        </Form.Item>
                                    </div>
                                </div>
                            ))}

                            <h2 className='font-bold text-xl my-2'>Digital Interface Value:</h2>
                            <div>
                                {/* <label>Digital Interface:</label>
                                <input
                                    type="text"
                                    value={formData.digitalInterface}
                                    onChange={(e) => handleDigitalInterfaceChange(e.target.value)}
                                /> */}
                                <div className='grid grid-cols-2 gap-2 '>
                                    <label htmlFor="" className='text-xl font-semibold'>Digital Interface</label>
                                    <Form.Item
                                        name="digitalInterface"
                                        // label="E-mail"
                                        className='mb-2'
                                        rules={[
                                            {
                                                required: true,
                                                message: `Please input your digitalInterface`,
                                            },
                                        ]}
                                    >

                                        <Input className='py-2' />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>

                        <div>
                            <h2 className='font-bold text-xl my-2'>Sales person Details</h2>
                            <label htmlFor="" className='text-xl font-semibold'>Name </label>
                            <Form.Item
                                name={['salesPerson', 'name']}
                                // label="Name "
                                className='mb-2'
                                tooltip="What do you want others to call you?"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your coupon name!',
                                        whitespace: true,
                                    },
                                ]}
                            >

                                <Input className='py-2' />
                            </Form.Item>
                            <div className='grid grid-cols-2 gap-2 '>
                                <div>
                                    <label htmlFor="" className='text-xl font-semibold'>E-mail</label>
                                    <Form.Item
                                        name={['salesPerson', 'email']}
                                        // label="E-mail"
                                        className='mb-2'
                                        rules={[
                                            {
                                                type: 'email',
                                                message: 'The input is not valid E-mail!',
                                            },
                                            {
                                                required: true,
                                                message: 'Please input your E-mail!',
                                            },
                                        ]}
                                    >

                                        <Input className='py-2' />
                                    </Form.Item>
                                </div>
                                <div>
                                    <label htmlFor="" className='text-xl font-semibold'>Phone Number</label>
                                    <PhoneInput country={"eg"} enableSearch={true} value={phone} onChange={(phone) => setPhone(phone)} inputStyle={{ width: '300px', height: '10px' }} />
                                </div>
                            </div>
                        </div>



                        <Form.Item {...tailFormItemLayout} className='flex justify-center  mr-14'>
                            <Button className='bg-blue-800 w-32  my-5' type="primary" htmlType="submit">
                                {edit ? 'Save' : ' Register'}
                            </Button>
                        </Form.Item>
                    </Form>
                    {/* </div> */}
                    <ToastContainer />
                </div>
            </>
        </div>
    )
}

export default CreateCoupon