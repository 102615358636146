import React from 'react'
import Sidebar from '../../components/sidebar/Sidebar';
import { Layout } from 'antd';
import CreateUser from '../../components/user/CreateUser';
const { Content } = Layout;

function CreateUserPage() {
  return (
    <div>

      <Layout>
        <div className='fixed'>
          <Sidebar />
        </div>
        <Layout style={{ marginLeft: 200, }}  >
          <Content style={{ margin: '24px 16px', padding: 24, minHeight: 280, background: 'white', }} >
            <CreateUser />
          </Content>
        </Layout>
      </Layout>

    </div>
  )
}

export default CreateUserPage