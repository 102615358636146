import React, { useEffect, useRef, useState } from "react";
import { Drawer, Button, Table, Input, Space, } from "antd";
// import {
//   DeactiveCard,
//   DeleteCard,
//   getSigleCardData,
// } from "../../../api/AdminRequest";
// import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
// import { editData } from "../../../Redux/Actions/CardAction";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { adminBlock, adminDelete, userBlock, userDelete } from "../../api/AdminRequest";
// import { adminCards, userCards } from "../../../api/AdminRequest";
import Highlighter from 'react-highlight-words';
import CardDetailsDrawer from "../admin/cardsDrawer/CardDetailsDrawer";
import { couponWiseCards } from "../../api/AdminRequest";


// const { confirm } = Modal;

function CouponCardDrawer({ onCloseCard, cardViewId, openCard, setChange, users }) {
    // const dispatch = useDispatch();
    // console.log(cardViewId, 'cardViewId');
    // const navigate = useNavigate();
    const [drawerData, setDrawerData] = useState("");
    const [loader, setLoader] = useState(true)
    const [dataChanges, setDataChanges] = useState('')
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [viewId, setViewID] = useState('')
    const [open, setOpen] = useState(false);






    useEffect(() => {
        const singleBookedCards = async () => {
            try {
                setLoader(true)
                const { data } = await couponWiseCards(cardViewId.code);
                // console.log(data, 'lllll');
                if (data.success) {

                    setDrawerData(data.cards);

                    setLoader(false)

                }
            } catch (error) {
                console.log(error);
            }
        };
        singleBookedCards();
    }, [cardViewId, dataChanges]);

    const showDrawer = async (ID) => {
        setViewID(ID)
        setOpen(true);

    };
    const onClose = () => {
        setOpen(false);
    };


    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        className='bg-blue-800'
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            handleSearch(selectedKeys, confirm)
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            // title: 'ID',
            // dataIndex: '_id',
            // key: '_id',
            // width: '30%',
            // ...getColumnSearchProps('shopperId'),
            title: 'No.',
            dataIndex: '_id',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name'),
        },

        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ...getColumnSearchProps('email'),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '10%',
            // ...getColumnSearchProps('status'),
            render: (status) => { return status === 'active' ? "Active" : "Blocked" },
        },
        {
            title: 'View',
            dataIndex: 'status._id',
            key: '_id',
            render: (status, _id) => {
                return (
                    <Button className='w-24 bg-slate-300 hover:bg-slate-200' onClick={() => showDrawer(_id)} >View</Button>
                )
            }

        },
        {
            title: 'Preview',
            dataIndex: '_id',
            key: '_id',
            render: (_id) => <Link to={`https://zeeqr.com/profile-view/${_id}`} target="_blank"> <Button className='w-24 bg-slate-400 hover:bg-slate-300' >  Preview </Button></Link>

        },

    ];


    return (
        <div>

            <Drawer
                width={750}
                placement="right"
                closable={false}
                onClose={onCloseCard}
                open={openCard}
            >

                {/* <Spin spinning={loader}> */}

                <div className="flex flex-col justify-between">
                    <p
                        className="site-description-item-profile-p text-2xl font-bold"
                        style={{
                            marginBottom: 24,
                        }}
                    >
                        Cards
                    </p>

                    <Table columns={columns} loading={loader} dataSource={drawerData} pagination={drawerData.length > 10 ? true : false} />
                    <CardDetailsDrawer viewId={viewId} setChange={setDataChanges} open={open} onClose={onClose} />

                </div>
                {/* </Spin> */}

            </Drawer>

            {/* <ToastContainer /> */}
        </div>
    );
}

export default CouponCardDrawer;
