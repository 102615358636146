import React from 'react'
import CreateMaterials from '../../components/materials/createMaterials/CreateMaterials'
import Sidebar from '../../components/sidebar/Sidebar'
import { Layout } from 'antd';
const { Content } = Layout;

function EditMaterialsPage() {
    return (
        <div>
            <Layout>
                <Sidebar />
                <Layout style={{ marginLeft: 200, }}  >
                    <Content style={{ margin: '24px 16px', padding: 24, minHeight: 280, background: 'white', }} >
                        <CreateMaterials edit={'edit'} />
                    </Content>
                </Layout>
            </Layout>
        </div>
    )
}

export default EditMaterialsPage