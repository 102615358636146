import React from 'react'
// import logo from '../../assests/zeeqr.png'
// import QR from '../../assests/QR_code.png'



function BusinessCardFrontSide({ imgPre, cardColor, textColor }) {



    return (
        <div className='flex justify-center'>
            <div className='w-[470px]'>
                <div className="group relative  w-[350px]  h-[222px]  p-4 transition-all border border-2 rounded-xl  lg:p-8 border" style={{ backgroundColor: `${cardColor ? cardColor : "black"}` }}>

                </div>
            </div>
        </div>
    )
}

export default BusinessCardFrontSide