import { Button, Form, Input } from 'antd'
import React from 'react'
import { login } from '../../api/AdminRequest';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import loginImg from '../../assests/banner01.webp'

// const defaultProfileImage = 'https://zeeqr.com/assets/img/footerlogo.svg'


function Login() {

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    // wrapperCol: {
    //   xs: {
    //     span: 24,
    //   },
    //   sm: {
    //     span: 16,
    //   },
    // },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  const onFinish = async (values) => {

    // console.log('Received values of phone: ', phone);
    console.log('Received values of form: ', values);
    try {
      const { data } = await login(values)
      console.log(data);
      if (data.success) {
        localStorage.setItem("admin-refToken", data.refreshToken)
        toast.success('login sucessfully', {
          position: "top-right",
          autoClose: 2500,
          theme: "dark",
        });
        setTimeout(() => {
          navigate('/dashboard')
        }, 2500)
      }
    } catch (error) {
      console.log(error.message);
      toast(error.message)
    }
  };

  return (
    <div className='flex justify-center'>
      <section class="h-screen w-4/6">
        <div class="h-full">
          <div
            class=" flex h-full flex-wrap items-center justify-center lg:justify-around">
            <div
              class="shrink-1 mb-12 grow-0 basis-auto md:mb-0 md:w-9/12 md:shrink-0 lg:w-6/12 xl:w-6/12">
              <img
                src={loginImg}
                class="w-full"
                alt="Sampleimage" />
            </div>

            <div class="mb-12 md:mb-0 md:w-8/12 lg:w-5/12 xl:w-5/12">
              <Form
                {...formItemLayout}
                form={form}
                name="register"
                onFinish={onFinish}
                style={{
                  maxWidth: 400,
                }}
                scrollToFirstError
              >


                <div
                  class="my-4 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-neutral-300 after:mt-0.5 after:flex-1 after:border-t after:border-neutral-300">
                  <p
                    class="mx-4 mb-0 text-center text-xl font-bold dark:text-white">
                    Admin Login
                  </p>
                </div>

                <label htmlFor="" className='text-xl font-semibold'>E-mail</label>
                <Form.Item
                  name="email"
                  // label="E-mail"
                  className='mb-2'
                  rules={[
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!',
                    },
                    {
                      required: true,
                      message: 'Please input your E-mail!',
                    },
                  ]}
                >

                  <Input className='py-2' />
                </Form.Item>

                <label htmlFor="" className='text-xl font-semibold'>Password</label>
                <Form.Item
                  name="password"
                  //   label="Password"
                  className='mb-2'
                  rules={[

                    {
                      required: true,
                      message: 'Please input your password!',
                    },
        
                  ]}
                // hasFeedback
                >
                  <Input.Password className='py-2' />
                </Form.Item>

                <Form.Item {...tailFormItemLayout} className='flex justify-center  mr-14'>
                  <Button className='bg-blue-800 w-32 mt-2 ' type="primary" htmlType="submit">
                    Login
                  </Button>
                </Form.Item>

              </Form>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </div>
  )
}

export default Login