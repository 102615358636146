import { Button, Col, Input, Modal, Row, Space, Table, Tooltip, Typography } from 'antd';
import Highlighter from 'react-highlight-words';
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { deactiveBanner, deactiveTheme, deleteBanner, deleteTheme, getBanner } from '../../api/AdminRequest';
import { FaEdit } from 'react-icons/fa';
import { CgBlock, CgUnblock } from 'react-icons/cg';
import { MdDelete } from 'react-icons/md';
import { ExclamationCircleFilled, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreateBanner from './CreateBanner';
const { Title } = Typography;
const { confirm } = Modal;

function ViewBanner() {

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const [edit, setEdit] = useState(false)
    const [banner, setbanner] = useState('')
    const [bannerDetails, setBannerDetails] = useState('')
    const [loader, setLoader] = useState(true)
    const [change, setChange] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false);
    // const navigate = useNavigate();

    useEffect(() => {
        const allBanner = async () => {
            try {
                const { data } = await getBanner()
                // console.log(data, '12355');
                setbanner(data.banner)
                setLoader(false)
            } catch (error) {
                // console.log(error.message);
            }
        }
        allBanner()
    }, [change])

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        className='bg-blue-800'
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    {/* <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button> */}
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            handleSearch(selectedKeys, confirm)
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: 'No.',
            dataIndex: '_id',
            key: 'index',
            // render: (text, record, index) => index + 1,
            render: (text, record, index) => (banner.indexOf(record) + 1),
        },
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            render: (image) => {
                return (
                    <div className="h-52 w-60  flex items-center ">
                        {/* <img className='rounded-xl shadow-xl' src={image.url} alt="" srcset="" /> */}

                        {image?.type?.startsWith("video") ? (

                            <video
                                className="rounded-xl shadow-xl "
                                // width="400"
                                // height="200px"
                                controls
                                src={image?.url}
                            />
                        ) : (
                            <img className='rounded-xl shadow-xl' src={image.url} alt="img" srcset="" />
                        )}
                    </div>
                )
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Link',
            dataIndex: 'link',
            key: 'link',
            ...getColumnSearchProps('link'),
        },

        {
            title: 'positiom',
            dataIndex: 'position',
            key: 'position',
            ...getColumnSearchProps('typositionpe'),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            // ...getColumnSearchProps('status'),
            render: (status) => { return status === 'active' ? <p className=' text-center'>Active</p> : <p className='bg-red-500 px-1 text-white text-center rounded-xl'>Blocked</p> },
        },
        {
            title: 'Action',
            dataIndex: 'status._id',
            key: '_id',
            render: (status, _id) => {
                return (
                    <>
                        <div className='flex gap-3 items-center'>
                            {/* <Tooltip title="view"><FaEye size={20} className='cursor-pointer' onClick={() => showDrawer(_id)} /></Tooltip> */}
                            <Tooltip title="edit"><FaEdit size={20} className='cursor-pointer' onClick={() => handleEdit(_id)} /></Tooltip>
                            {_id.status === 'active' ?
                                <Tooltip title="block"><CgBlock size={28} className='cursor-pointer' onClick={() => showDeactiveConfirm(_id)} /></Tooltip>
                                :
                                <Tooltip title="unblock"> <CgUnblock size={20} className='cursor-pointer' onClick={() => showDeactiveConfirm(_id)} /></Tooltip>
                            }
                            <Tooltip title="delete"><MdDelete size={20} className='cursor-pointer' onClick={() => showDeleteConfirm(_id)} /></Tooltip>
                        </div>
                    </>
                )
            }

        },

    ];


    const handleCancelModal = () => {
        setIsModalOpen(false);
    };

    const handleEdit = (id) => {
        // navigate("/edit-products", { state: id });
        setIsModalOpen(true);
        setEdit(true)
        setBannerDetails(id)
    }
    // console.log(edit,'view pg');
    
    const handleDeactive = async (id) => {

        try {
            setLoader(true)
            const { data } = await deactiveBanner(id);
            // console.log(data);
            if (data.block) {
                // console.log("sucess blocked");
                // setCardStatus("blocked")
                setLoader(false)
            }
            if (data.active) {
                // setCardStatus("active")
                setLoader(false)
            }
            setChange(Date.now())
        } catch (error) {
            console.log(error);
            toast(error?.response?.data?.message);
        }
    };

    const handleDelete = async (id) => {
        try {
            setLoader(true)
            const { data } = await deleteBanner(id);
            // console.log(data);
            if (data.delete) {
                // console.log("sucess delete");
                setChange(Date.now())
                setLoader(false)
            }
        } catch (error) {
            console.log(error);
        }
    };

    const showDeactiveConfirm = (data) => {
        confirm({

            title: `${data?.status === 'active' ? 'Are you sure to Block ' : 'Are you sure to Active'}`,
            icon: <ExclamationCircleFilled />,
            content: `${data?.status === 'active' ? 'if you block, the product cannot view any more ' : 'if you activate, all can view the product.'}`,
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                handleDeactive(data._id);
                // { cardStatus === "active" ? setCardStatus("deactive") : setCardStatus("active") }
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    }
    const showDeleteConfirm = (data) => {
        confirm({
            title: "Are you sure delete?",
            icon: <ExclamationCircleFilled />,
            // content: `${admin ? 'if you delete the card the user also get deleted.' : ' }` ,
            content: `if you delete, the product will deleted`,
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                handleDelete(data._id);
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    }

    return (
        <div>
            <Row gutter={16}>
                <Col span={12}>
                    <Title level={2}>Banner </Title>
                </Col>
                <Col span={12} className='text-right'>
                    <Link className='add-button rounded-lg ml-auto' onClick={() => { setIsModalOpen(true); setEdit(false); setBannerDetails('') }} ><PlusOutlined />Add Banner</Link>
                </Col>
            </Row>

            <div className='flex justify-center'>
                <div className='w-full mt-5'>
                    <Table columns={columns} loading={loader} dataSource={banner} pagination={banner.length > 10 ? true : false} />


                </div>
            </div>
            <Modal open={isModalOpen} onCancel={handleCancelModal} closable={false} maskClosable={false} destroyOnClose={false} footer={false}

            >
                <CreateBanner edit={edit} setEdit={setEdit} handleCancelModal={handleCancelModal} bannerDetails={bannerDetails} setChanges={setChange} />
            </Modal>
            <ToastContainer />
        </div>
    )
}

export default ViewBanner