import React from 'react'
import Cards from '../components/cards/Cards'
import Sidebar from '../components/sidebar/Sidebar'
import { Layout } from 'antd';
const { Content } = Layout;

function CardsPage() {
  return (
    <div>

      <Layout hasSider>
        <Sidebar />
        <Layout style={{ marginLeft: 200, }}  >
          <Content style={{ margin: '24px 16px', padding: 24, minHeight: 280, background: 'white', }} >
            <Cards />
          </Content>
        </Layout>
      </Layout>

    </div>
  )
}

export default CardsPage