import { useEffect, useState } from "react";
import "./ProductImagePreview.css";
import { FaCircleChevronLeft, FaCircleChevronRight } from "react-icons/fa6";
import { RxCross1 } from "react-icons/rx";
import { getProductImages } from "../../../api/AdminRequest";
import { MdOutlineDelete, MdOutlineRemoveRedEye } from "react-icons/md";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { PiSelectionAllThin } from "react-icons/pi";
import { Modal, Spin } from "antd";

const { confirm } = Modal;


function ProductImagePreview({ change, uploadPage, onDataFromChild, productID }) {

    const [images, setImages] = useState([]);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [modal, setModal] = useState(false);
    // const [changeMade, setChangeMade] = useState()
    const [tempImg, setTempImg] = useState({
        url: "",
        id: ""
    });
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const fetchImageData = async () => {
            setLoaded(true);
            const { data } = await getProductImages(productID)
            // console.log(data);
            if (data.success) {
                setLoaded(false);
                setImages(data.productImage);
            }
        };

        fetchImageData();
    }, [change]);

    const getImg = (img) => {
        setTempImg({ id: img?.id, url: img?.url });
        setModal(true);
    };



    const handlePrevClick = () => {
        setCurrentImageIndex((prevIndex) => {
            const newIndex = prevIndex - 1;
            if (newIndex >= 0) {
                const newImage = images[newIndex];
                setTempImg({ url: newImage.url, id: newImage.id });
            }
            return newIndex;
        });
    };

    const handleNextClick = () => {
        setCurrentImageIndex((prevIndex) => {
            const newIndex = prevIndex + 1;
            if (newIndex < images.length) {
                const newImage = images[newIndex];
                setTempImg({ url: newImage.url, id: newImage.id });
            }
            return newIndex;
        });
    };

    // const handleDelete = async (img) => {
    //     try {
    //         setLoaded(true)
    //         const { data } = await deleteGalleryImage(img.id);
    //         // console.log(data);
    //         if (data.delete) {
    //             // console.log("sucess delete");
    //             setChangeMade(Date.now())
    //             // onClose()
    //             setLoaded(false)
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    const showDeleteConfirm = (img) => {
        confirm({
            title: "Are you sure delete this Image?",
            icon: <ExclamationCircleFilled />,
            // content: `${admin ? 'if you delete the card the user also get deleted.' : ' }` ,
            content: 'if you delete the Image, the Image cannnot use any more,also if you had use this image for any purpose that also be deleted.',
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                // handleDelete(img);
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };


    const handleUploadImage = (img) => {

        onDataFromChild(img);
    }

    return (
        <div className="min-h-[400px]" >
            <Spin spinning={loaded} >
                <div className={modal ? "modal open" : "modal"}>
                    <button
                        onClick={handlePrevClick}
                        className="previous-button text-white"
                        type="button"
                    >
                        <FaCircleChevronLeft size={28} />
                    </button>
                    <img src={tempImg.url} alt="image" />
                    <button
                        className="close-button text-white"
                        type="button"
                        onClick={() => setModal(false)}
                    >
                        <RxCross1 size={28} />
                    </button>
                    <button onClick={handleNextClick} className="next-button text-white" type="button">
                        <FaCircleChevronRight size={28} />
                    </button>
                </div>
                {/* {!loaded ? (
            <div>...loading</div>
        ) : ( */}
                <div
                    className="gallery columns-3"
                >
                    {images.map((image) => {
                        return (
                            <div
                                // className="pics"
                                className="image-item"
                                key={image.id}
                            // onClick={() => getImg(image)}
                            >
                                {image?.type.startsWith("video") ? (

                                    <video
                                        className="VideoInput_video"
                                        // width="400"
                                        height="200"
                                        controls
                                        src={image.url}
                                    />
                                ) : image?.type?.startsWith("embed") ? (

                                    <iframe
                                        width="100%"
                                        height="100%"
                                        src={image?.url}
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                ) : (
                                    <img
                                        loading="lazy"
                                        style={{ width: "100%" }}
                                        src={image.url}
                                        alt={image.author}
                                        className={`${uploadPage ? 'cursor-pointer' : ''}`}
                                    />
                                )}

                                {uploadPage ?
                                    <div className="image-overlay">
                                        <span className="icon" onClick={() => handleUploadImage(image)}>
                                            <PiSelectionAllThin />
                                        </span>
                                    </div>
                                    :

                                    <div className="image-overlay">
                                        <span className="icon" onClick={() => getImg(image)}>
                                            <MdOutlineRemoveRedEye />
                                        </span>
                                        <span className="icon" onClick={() => showDeleteConfirm(image)}>
                                            <MdOutlineDelete />
                                        </span>
                                    </div>
                                }
                            </div>
                        );
                    })}
                </div>
                {/* )} */}
            </Spin>
        </div>
    )
}

export default ProductImagePreview