import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const AdminLoginProtect=()=> {
    let auth = {'token':localStorage.getItem('admin-refToken')}
    return(
        !auth.token ? <Outlet/> : <Navigate to={"/dashboard"} />
       
    )
}

export default AdminLoginProtect