import React from 'react'
import CreateAdmin from '../../components/admin/createAdmin/CreateAdmin'
import Sidebar from '../../components/sidebar/Sidebar'
import { Layout } from 'antd';
const { Content } = Layout;

function EditAdminPage() {
    return (
        <Layout>
            <div className='fixed'>
                <Sidebar />
            </div>
            <Layout style={{ marginLeft: 200, }}  >
                <Content style={{ margin: '24px 16px', padding: 24, minHeight: 280, background: 'white', }} >
                    <CreateAdmin edit={'edit'} />
                </Content>
            </Layout>
        </Layout>
    )
}

export default EditAdminPage