import { Button, Col, Form, Input, Modal, Row, Select, Typography, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import React, { useEffect, useRef, useState } from 'react'
// import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBanner, createThemes, editBanner, editThemes } from '../../api/AdminRequest';
import HandleImageUpload from '../helper/ImageCompress';

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import imageCompression from "browser-image-compression";
import { UploadOutlined } from '@ant-design/icons';
import { FaRegEdit } from 'react-icons/fa';
import { MdOutlineDelete } from 'react-icons/md';
const { Title } = Typography;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  // wrapperCol: {
  //   xs: {
  //     span: 24,
  //   },
  //   sm: {
  //     span: 16,
  //   },
  // },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

function CreateBanner({ edit, setEdit, handleCancelModal, bannerDetails, setChanges }) {
  const [form] = Form.useForm();
  // const { state } = useLocation();

  // console.log(state, '************************');
  // console.log(edit, '++++++++++++++++');
  // console.log(bannerDetails, '************************');

  // const [theme, setTheme] = useState({})
  // const [fileList, setFileList] = useState([])
  const [themeType, setThemeType] = useState(null)
  const [error, setError] = useState({});
  const fileInputRef = useRef(null);
  const [editingIndex, setEditingIndex] = useState(null);

  const [imageType, setImageType] = useState("");
  const [imageName, setImageName] = useState("");
  const [mediaFile, setMediaFile] = useState(null);
  const [images, setImages] = useState();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [pending, setPending] = useState([]);
  const [pending1, setPending1] = useState([]);
  const [cropper, setCropper] = useState();
  const [loader, setLoader] = useState(false);
  const [Btnloader, setBtnLoader] = useState(false);
  const [change, setChange] = useState("");


  // console.log(images, 'images');
  useEffect(() => {
    // console.log('useEffect');

    if (bannerDetails) {
      // console.log('oooo');

      const initialValues = {
        name: bannerDetails?.name,
        link: bannerDetails?.link
      }

      // setTheme(bannerDetails)

      form.setFieldsValue(initialValues);
      // setFileList(imageFile)
      setImages(bannerDetails?.image)
      setThemeType(bannerDetails?.position)
    }
  }, [bannerDetails, edit])


  // console.log(theme, 'llll');
  const onFinish = async (values) => {
    values.position = themeType
    // console.log('Form Values:', values);
    let id = bannerDetails?._id

    const errors = validateForm(values);
    setError(errors)
    if (Object.keys(errors).length === 0) {
      setBtnLoader(true)
      try {

        const datas = new FormData();
        datas.append("bannerImage", images.originFileObj);
        for (const keys in values) {
          datas.append(`${keys}`, `${values[keys]}`);
        }

        // datas.append('details', JSON.stringify(values))

        const { data } = edit ? await editBanner(id, datas) : await createBanner(datas)
        // console.log(data);
        if (data.success) {
          toast.success('New Banner Created', {
            position: "top-right",
            autoClose: 2000,
            theme: "dark",
          });
        } else if (data.update) {
          toast.success('Updated Successfully', {
            position: "top-right",
            autoClose: 2000,
            theme: "dark",
          });

        }
        setTimeout(() => {
          setChanges(Date.now())
          // setFileList([])
          form.resetFields()
          //    setFormData(initialValue)
          setThemeType(null)
          setEdit(false)
          // setTheme('')
          setBtnLoader(false)
          handleCancelModal()
        }, 3000)
      } catch (error) {
        // console.log(error);
        setBtnLoader(false)
        if (error?.response?.data?.message) {
          toast(error?.response?.data?.message)
        } else {

          toast(error.message)
        }
      }
    } else {
      const firstValue = Object.values(errors)[0];
      // console.log(firstValue, '23456hggfgfg');
      toast.error(`Please fill required fields, ${firstValue}`, {
        autoClose: 3000,
        theme: "dark",
      });
    }
  }

  const themeTypes = [
    {
      name: 1,
      value: 1
    },
    {
      name: 2,
      value: 2
    },
    {
      name: 3,
      value: 3
    },
    {
      name: 4,
      value: 4
    },
    {
      name: 5,
      value: 5
    },
    {
      name: 6,
      value: 6
    },
    {
      name: 7,
      value: 7
    },
    {
      name: 8,
      value: 8
    },
    {
      name: 9,
      value: 9
    },
    {
      name: 10,
      value: 10
    },
  ]

  const handleChangeThemeType = (value) => {
    // console.log(`selected ${value}`);
    setThemeType(value)

  };

  // const onChange = ({ fileList: newFileList }) => {
  //   if (newFileList[0]?.status) { newFileList[0].status = "done"; }
  //   setFileList(newFileList);


  // };
  // const onPreview = async (file) => {
  //   let src = file.url;
  //   if (!src) {
  //     src = await new Promise((resolve) => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file.originFileObj);
  //       reader.onload = () => resolve(reader.result);
  //     });
  //   }
  //   const image = new Image();
  //   image.src = src;
  //   const imgWindow = window.open(src);
  //   imgWindow?.document.write(image.outerHTML);
  // };

  const 
  handleCancel = () => {
    // setFileList([])
    // setFormData(initialValue)
    // console.log('qwerty');
    form.resetFields()
    setThemeType(null)
    setError('')
    setEdit(false)
    setChanges(Date.now())
    // setTheme('')
    setImages('')
    handleCancelModal()
  }
  // console.log(formData,'kkkkkkk');
  // console.log(edit,'lllll');

  const validateForm = (data) => {
    const error = {};

    if (!data.position) {
      error.position = "Banner Position required";
    }
    // if (!fileList[0]?.originFileObj && !fileList[0]?.url) {
    //   error.image = "Theme Image required";
    // }
    if (!images) {
      error.image = "Banner Image required";
    } else if (images?.status === 'removed') {
      error.image = "Banner Image required";
    }



    return error;
  };



  const getImage = (file) => {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onload = () => {
        resolve(fr.result);
      };
      fr.addEventListener("error", (e) => {
        reject(e);
      });
      fr.readAsDataURL(file);
    });
  };

  const handleChangeImage = async (event) => {
    // console.log(event.target.files, "event.target.files");
    // setImageName(event.target.files[0].name);
    // setImageType(event.target.files[0].type);
    // setMediaFile(event.target.files[0]);
    const files = event.target.files;
    if (files) {
      setImageName(files[0].name);
      setImageType(files[0].type);

      if (files[0].type.startsWith("image")) {
        if (files.length) {
          // console.log("number of files", files.length);
          setPending(
            await Promise.all(Array.from(files).map((file) => getImage(file)))
          );
          setPending1(files[0])
          setDialogOpen(true);
        }
      } else if (files[0].type.startsWith("video")) {
        setMediaFile(files[0]);
        setChange(Date.now());
      }
    }
  };

  const handleCancelUploadModal = () => {
    setDialogOpen(false);
    fileInputRef.current.value = null;
  };

  const onComplete = async () => {
    setLoader(true);
    const blob = await new Promise((resolve, reject) => {
      cropper.getCroppedCanvas().toBlob(resolve);
    });
    // console.log(blob, 'kkkkk');
    const options = {
      maxSizeMB: 0.5,
      fileType: "image/webp",
      maxWidthOrHeight: 1920,
    };
    const image = await imageCompression(blob, options);
    // console.log(image, "pppp");
    setMediaFile(image);
    setChange(Date.now());
    // const base64Img = await blobToBase64(image);
    // console.log(base64Img, '77777');

    // onDataFromChild(image, base64Img);
    setLoader(false);
    const newPending = [...pending];
    newPending.shift();
    if (newPending.length === 0) {
      handleCancelUploadModal();
    }
    setPending(newPending);
  };

  const onWithoutCrop = async () => {
    // console.log(pending1, 'croppercroppercropper');
    // console.log(pending[0], 'pending[0]');
    setMediaFile(pending1);
    setChange(Date.now());
    handleCancelUploadModal();
  }



  const addSocialMediaLink = () => {
    if (imageType && imageName && mediaFile) {
      setImages(
        { name: imageName, type: imageType, status: 'done', originFileObj: mediaFile }
      );
      setImageType("");
      setImageName("");
      setMediaFile(null);
    }
  };

  const removeSocialMediaLink = () => {
    // const updatedLinks = images
    // // updatedLinks.splice(index, 1);
    // updatedLinks.status = 'removed'
    // console.log(updatedLinks,"updatedLinks");

    setImages((preImage) => ({
      ...preImage,
      status: 'removed'
    }));
  };

  const editSocialMediaLink = (index, name, type, media) => {
    setEditingIndex(index);
    // setImageName(name);
    // setImageType(type);
    // setMediaFile(media);
    document.getElementById(`file-image-input`).click();
  };

  const saveEditedLink = () => {
    console.log("edittttttttt");
    if (editingIndex !== null && imageType && imageName && mediaFile) {
      // const updatedLinks = images
      const updatedLinks = {
        name: imageName,
        type: imageType,
        status: 'done',
        originFileObj: mediaFile,
      };

      setImages(updatedLinks);
      setEditingIndex(null);
      setImageType("");
      setImageName("");
      setMediaFile(null);
    }
  };


  useEffect(() => {
    if (editingIndex !== null) {
      saveEditedLink();
    } else {
      addSocialMediaLink();
    }
  }, [change]);

  return (
    <div>
      <Row gutter={16}>
        <Col span={12}>
          <Title level={3}>{edit ? 'Edit Banner' : 'Create Banner'}</Title>
        </Col>
      </Row>
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        // initialValues={{
        //     name: edit ? theme?.name : '',
        //     value: edit ? theme?.value : '',

        // }}
        // style={{
        //     // maxWidth: 600,
        //     width: 450
        // }}
        scrollToFirstError
      >
        <div >

          <div>
            <label htmlFor="" className='text-xl font-semibold'>Name</label>
            <Form.Item
              name="name"
              // label="E-mail"
              className='mb-2'
              rules={[
                {
                  required: true,
                  message: 'Please input your Name!',
                },
              ]}
            >

              <Input placeholder='Banner Name' className='py-2' />
            </Form.Item>
          </div>
          <div>
            <label htmlFor="" className='text-xl font-semibold'>Link</label>
            <Form.Item
              name="link"
              // label="E-mail"
              className='mb-2'
              rules={[
                {
                  required: true,
                  message: 'Please input your link',
                },
              ]}
            >

              <Input placeholder='Banner link' className='py-2' />
            </Form.Item>
          </div>
          <div>
            <label htmlFor="" className='text-xl font-semibold'>Position</label>
            <Select
              // defaultValue="lucy"
              placeholder='Select the Banner Position'
              value={themeType}
              style={{
                width: '100%',
              }} f
              // disabled={filterMaterialColor.length === 0 ? true : false}
              size='large'
              onChange={handleChangeThemeType}
              options={themeTypes?.map((item) => ({
                label: item.name,
                value: item.value,
              }))}
            />
            <p className='text-red-500'>{error.type}</p>
          </div>

          <div className='py-2 flex flex-col'>
            <label htmlFor="" className='text-xl font-semibold'>Image</label>
            {/* <ImgCrop rotationSlider aspectSlider showReset>
              <Upload
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                listType="picture-card"
                fileList={fileList}
                onChange={onChange}
                onPreview={onPreview}
              >
                {fileList.length < 1 && "+ Upload"}
              </Upload>
            </ImgCrop> */}
            <input
              type="file"
              id="file-image-input"
              style={{ display: 'none' }}
              // value={mediaFile}
              ref={fileInputRef}
              onChange={handleChangeImage}
            />

            {images?.status === 'done' || (images?.url && images?.status !== 'removed') ?
              <div className="relative  flex">
                {/* <span className="font-semibold">{name}:</span> {type} */}

                {(images?.originFileObj?.type || images?.url) && (
                  <div>
                    {images?.type.startsWith("image") ? (
                      <img
                        className="w-[400px] h-[200px] object-contain"
                        src={images?.originFileObj?.type ? URL.createObjectURL(images?.originFileObj) : images?.url}
                        alt="Uploaded"
                      />
                    ) : (
                      <video
                        className="VideoInput_video"
                        // width="400"
                        height="200"
                        controls
                        // src={mediaFile?.type ? URL.createObjectURL(mediaFile) : mediaFile}
                        src={images?.originFileObj?.type ? URL.createObjectURL(images?.originFileObj) : images?.url}
                      />
                    )}

                  </div>
                )}


                <div className="mt-2">

                  <div className="absolute top-0 left-0 right-0 bottom-20 pt-2 pr-2 flex flex-col gap-5 justify-start items-end  bg-opacity-50 opacity-0 transition-opacity duration-300 hover:opacity-100  ">
                    <span onClick={() => editSocialMediaLink('0')} className="text-white bg-sky-500 p-2  rounded-full text-2xl cursor-pointer " >
                      <FaRegEdit />
                    </span>
                    <span onClick={() => removeSocialMediaLink()} className="text-white bg-sky-500 p-2  rounded-full text-2xl text-2xl cursor-pointer" >
                      <MdOutlineDelete />
                    </span>
                  </div>

                </div>
              </div>
              :
              <Button icon={<UploadOutlined />} onClick={() => fileInputRef.current.click()} >Click to Upload</Button>
            }

            <p className='text-red-500'>{error.image}</p>
          </div>

        </div>
        <Form.Item {...tailFormItemLayout} className='flex justify-center  mr-14'>
          <div className='flex gap-3' >

            <Button key="back" className='w-32 my-5' onClick={handleCancel}>
              Cancel
            </Button>
            <Button loading={Btnloader} className='bg-blue-800 w-32  my-5 ' type="primary" htmlType="submit">
              {/* {edit ? 'Save' : ' Register'} */}
              save
            </Button>
          </div>
        </Form.Item>
      </Form>
      {/* <ToastContainer /> */}


      <Modal
        open={dialogOpen}
        onCancel={handleCancelUploadModal}
        closable={false}
        maskClosable={false}
        destroyOnClose={false}
        footer={false}
      >
        <div className="bg-white ">
          <h1 className="mb-4 font-semibold text-base capitalize">
            Crop Image
          </h1>

          <Cropper
            style={{ height: 400, width: "100%" }}
            src={pending[0]}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
          />
        </div>
        <div className="flex justify-end mt-4">
          <div className="flex gap-2">
            <Button onClick={handleCancelUploadModal}>Cancel</Button>

            <Button
              // loading={loader}
              onClick={onWithoutCrop}
              type="none"
              className="bg-gray-500 hover:bg-gray-400 hover:text-white text-white px-4 py-1 rounded"
            >
              Without Crop & Save
            </Button>
            <Button
              loading={loader}
              onClick={onComplete}
              type="none"
              className="bg-blue-500 hover:bg-blue-400 hover:text-white text-white px-4 py-1 rounded"
            >
              Crop & Save
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default CreateBanner