import React, { useEffect, useRef, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import imageCompression from "browser-image-compression";
import { Button, Dropdown, Input, Modal, message } from "antd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { UploadOutlined } from "@ant-design/icons";
import { MdOutlineDelete } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import ProductImagePreview from "../productImagePreview/ProductImagePreview";
import EmbedFiles from "./EmbedFiles";

// const datas = [
//   {
//     name: "Image1",
//     type: "image/webp",
//     status: "done",
//     url:
//       "https://zeeqr2.s3.ap-south-1.amazonaws.com/33eff693de75af2ed3ca1787019f59f6a8af537326b4ba394060606d39fcab2f",
//   },
//   {
//     name: "Image2",
//     type: "image/webp",
//     status: "done",
//     url:
//       "https://zeeqr2.s3.ap-south-1.amazonaws.com/4a617809978aa4fa703ab87c1ce5d4b2247d17b42bac0556edbb25d068706309",
//   },
//   {
//     name: "Image3",
//     type: "image/webp",
//     status: "done",
//     url:
//       "https://zeeqr2.s3.ap-south-1.amazonaws.com/33eff693de75af2ed3ca1787019f59f6a8af537326b4ba394060606d39fcab2f",
//   },
//   {
//     name: "Image4",
//     type: "image/webp",
//     status: "done",
//     url:
//       "https://zeeqr2.s3.ap-south-1.amazonaws.com/4a617809978aa4fa703ab87c1ce5d4b2247d17b42bac0556edbb25d068706309",
//   },
//   {
//     name: "Image5",
//     type: "image/webp",
//     status: "done",
//     url:
//       "https://zeeqr2.s3.ap-south-1.amazonaws.com/6c450cf3e240155c15fa5dceacfe2cde1c2ba798bf0efe8a1387d6df20c79163",
//   },
// ];


const MediaLink = ({
  index,
  name,
  type,
  mediaFile,
  details,
  moveLink,
  removeLink,
  editLink,
  productID
}) => {
  const [{ isDragging }, drag] = useDrag({
    type: "SOCIAL_MEDIA_LINK", // Define the type here
    item: { index },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: "SOCIAL_MEDIA_LINK",
    hover: (item) => {
      if (item.index !== index) {
        moveLink(item.index, index);
        item.index = index;
      }
    },
  });

  return (
    <li
      ref={(node) => drag(drop(node))}
      className={`p-2 border rounded-lg mb-3 shadow-xl cursor-move  bg-white ${isDragging ? "opacity-50" : "opacity-100"
        }`}
    >
      <div className="relative  flex">
        {/* <span className="font-semibold">{name}:</span> {type} */}

        {(details?.originFileObj?.type || details?.url) && (
          <div className="w-full">
            {type.startsWith("image") ? (
              <img
                className="w-[400px] h-[200px] object-contain"
                src={details?.originFileObj?.type ? URL.createObjectURL(details?.originFileObj) : details?.url}
                alt="Uploaded"
              />
            ) : type.startsWith("embed") ? (
              <div className="w-full h-[200px]">
                <iframe
                  width="100%"
                  height="100%"
                  src={details?.url}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            ) : (
              <video
                className="VideoInput_video"
                // width="400"
                height="200"
                controls
                // src={mediaFile?.type ? URL.createObjectURL(mediaFile) : mediaFile}
                src={details?.originFileObj?.type ? URL.createObjectURL(details?.originFileObj) : details?.url}
              />
            )}

          </div>
        )}


        <div className="mt-2">
          {/* {type.startsWith("image") ?
            <div className="absolute top-0 left-0 right-0 bottom-0 flex gap-5 justify-center items-center bg-black bg-opacity-50 opacity-0 transition-opacity duration-300 hover:opacity-100">
              <span className="text-white text-2xl cursor-pointer" >
                <FaRegEdit />
              </span>
              <span className="text-white text-2xl cursor-pointer" >
                <MdOutlineDelete />
              </span>
            </div>
            : */}
          {/* <div className="flex flex-col gap-2 pl-2 justify-center items-center"> */}
          {/* <button
                onClick={() => editLink(index, name, type, mediaFile)}
                className="ml-2 px-2 py-1 bg-yellow-500 text-white rounded"
              >
                Edit
              </button>
              <button
                onClick={() => removeLink(index)}
                className="ml-2 px-2 py-1 bg-red-500 text-white rounded"
              >
                Remove
              </button> */}
          {/* <span className="text-black text-2xl cursor-pointer" >
                <FaRegEdit />
              </span>
              <span className="text-black text-2xl cursor-pointer" >
                <MdOutlineDelete />
              </span>
            </div> */}
          {/* } */}
          <div className="absolute top-0 left-0 right-0 bottom-20 pt-2 pr-2 flex flex-col gap-5 justify-start items-end  bg-opacity-50 opacity-0 transition-opacity duration-300 hover:opacity-100  ">
            {!productID &&
              <span onClick={() => editLink(index, name, type, mediaFile)} className="text-white bg-sky-500 p-2  rounded-full text-2xl cursor-pointer " >
                <FaRegEdit />
              </span>
            }
            <span onClick={() => removeLink(index)} className="text-white bg-sky-500 p-2  rounded-full text-2xl text-2xl cursor-pointer" >
              <MdOutlineDelete />
            </span>
          </div>

        </div>
      </div>
    </li>
  );
};

const ImageVideoUploader = ({ images, setImages, productID, identifier, customImage }) => {
  // const [images, setImages] = useState([]);
  const [imageType, setImageType] = useState("");
  const [imageName, setImageName] = useState("");
  const [mediaFile, setMediaFile] = useState(null);

  const [editingIndex, setEditingIndex] = useState(null);

  const fileInputRef = useRef(null);
  const [messageApi, contextHolder] = message.useMessage();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [pending, setPending] = useState([]);
  const [pending1, setPending1] = useState([]);
  const [cropper, setCropper] = useState();
  const [loader, setLoader] = useState(false);
  const [change, setChange] = useState("");
  // console.log(images, "links");
  // console.log(mediaFile, "mediaFile");
  // useEffect(() => {
  //   setImages(datas);
  // }, []);

  const warning = () => {
    messageApi.open({
      type: 'warning',
      content: 'This option not supported for this field',
    });
  };

  const addSocialMediaLink = () => {
    if (imageType && imageName && mediaFile) {
      setImages([
        ...images,
        { name: imageName, type: imageType, status: 'done', originFileObj: mediaFile },
      ]);
      setImageType("");
      setImageName("");
      setMediaFile(null);
    }
  };

  const removeSocialMediaLink = (index) => {
    const updatedLinks = [...images];
    // updatedLinks.splice(index, 1);
    if (updatedLinks[index]?.existing) {
      updatedLinks.splice(index, 1);

    } else {

      updatedLinks[index].status = 'removed'
    }
    setImages(updatedLinks);
  };

  const editSocialMediaLink = (index, name, type, media) => {
    setEditingIndex(index);
    setImageName(name);
    setImageType(type);
    setMediaFile(media);
    // document.getElementById(`file-image-input`).click();
    document.getElementById(`file-image-input-${identifier}`).click();
  };

  const saveEditedLink = () => {
    console.log("edittttttttt");
    if (editingIndex !== null && imageType && imageName && mediaFile) {
      const updatedLinks = [...images];
      updatedLinks[editingIndex] = {
        name: imageName,
        type: imageType,
        status: 'done',
        originFileObj: mediaFile,
      };

      setImages(updatedLinks);
      setEditingIndex(null);
      setImageType("");
      setImageName("");
      setMediaFile(null);
    }
  };

  const moveSocialMediaLink = (fromIndex, toIndex) => {
    const updatedLinks = [...images];
    const [movedLink] = updatedLinks.splice(fromIndex, 1);
    updatedLinks.splice(toIndex, 0, movedLink);
    setImages(updatedLinks);
  };

  const getImage = (file) => {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onload = () => {
        resolve(fr.result);
      };
      fr.addEventListener("error", (e) => {
        reject(e);
      });
      fr.readAsDataURL(file);
    });
  };

  const handleChangeImage = async (event) => {
    // console.log(event.target.files, "event.target.files");
    // setImageName(event.target.files[0].name);
    // setImageType(event.target.files[0].type);
    // setMediaFile(event.target.files[0]);
    const files = event.target.files;
    if (files) {
      setImageName(files[0].name);
      setImageType(files[0].type);

      if (files[0].type.startsWith("image")) {
        if (files.length) {
          // console.log("number of files", files.length);
          setPending(
            await Promise.all(Array.from(files).map((file) => getImage(file)))
          );
          setPending1(files[0])
          setDialogOpen(true);
        }
      } else if (files[0].type.startsWith("video")) {
        setMediaFile(files[0]);
        setChange(Date.now());
      }
    }
  };

  const handleCancelUploadModal = () => {
    setDialogOpen(false);
    fileInputRef.current.value = null;
  };

  function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  const onComplete = async () => {
    setLoader(true);
    const blob = await new Promise((resolve, reject) => {
      cropper.getCroppedCanvas().toBlob(resolve);
    });
    // console.log(blob, 'kkkkk');
    const options = {
      maxSizeMB: 0.5,
      fileType: "image/webp",
      maxWidthOrHeight: 1920,
    };
    const image = await imageCompression(blob, options);
    // console.log(image, "pppp");
    setMediaFile(image);
    setChange(Date.now());
    // const base64Img = await blobToBase64(image);
    // console.log(base64Img, '77777');

    // onDataFromChild(image, base64Img);
    setLoader(false);
    const newPending = [...pending];
    newPending.shift();
    if (newPending.length === 0) {
      handleCancelUploadModal();
    }
    setPending(newPending);
  };

  const onWithoutCrop = async () => {
    // console.log(pending1, 'croppercroppercropper');
    // console.log(pending[0], 'pending[0]');
    setMediaFile(pending1);
    setChange(Date.now());
    handleCancelUploadModal();
  }

  useEffect(() => {
    if (editingIndex !== null) {
      saveEditedLink();
    } else {
      addSocialMediaLink();
    }
  }, [change]);
  // console.log(editingIndex, "editingIndex");

  const [isModelOpen, setIsModelOpen] = useState(false);
  const [embedIsModelOpen, setEmbedIsModelOpen] = useState(false);

  const handleCancelModal = () => {
    setIsModelOpen(false);
  };
  const handleGalleryModal = () => {
    setIsModelOpen(true);
  }
  const handleEmbededModal = () => {
    setEmbedIsModelOpen(true);
  }
  const handleEmbedCancelModal = () => {
    setEmbedIsModelOpen(false);
  };

  const onUploadButtonHandleClick = (identifierClick) => {
    document.getElementById(`file-image-input-${identifierClick}`).click();
  };


  const items = [
    {
      key: '1',
      label: (
        // <div className="cursor-pointer" onClick={() => fileInputRef.current.click()} ><UploadOutlined /> Upload from files</div>
        <div className="cursor-pointer" onClick={() => onUploadButtonHandleClick(identifier)} ><UploadOutlined /> Upload from files</div>
        // <Button icon={<UploadOutlined />} onClick={() => fileInputRef.current.click()} >Click to Upload</Button>
      ),
    },
    {
      key: '2',
      label: (
        <div onClick={handleGalleryModal} ><UploadOutlined /> Upload from Existing</div>
      ),
    },
    {
      key: '3',
      label: (
        <div onClick={customImage ?  warning : handleEmbededModal} ><UploadOutlined /> Upload Embed video</div>
      ),
    },

  ];

  const handleDataFromChild = (data) => {
    // Do something with the data in the parent component
    console.log('Data received from child:', data);
    // setDataFromChild(data);
    data.existing = true
    images.push(data)

    handleCancelModal()
  };

  const handleEmbedDataFromChild = (data) => {
    // Do something with the data in the parent component
    console.log('Data received from child:', data);

    const details = {
      name: data.from,
      type: "embed/" + data.from,
      url: data.url,
      status: "done",
    }
    // setDataFromChild(data);
    // data.existing = true
    images.push(details)

    handleEmbedCancelModal()
  };

  // console.log(images,'images');
  const statusExistsDone = (status) => images?.some(item => item?.status === status);
  const statusExistsRemove = (status) => images?.some(item => item?.status !== status);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="max-w-md mx-auto my-5  ">

        {/* Form to add new social media link */}
        <div className="mb-4 flex items-center">
          <input
            type="file"
            id={`file-image-input-${identifier}`}
            style={{ display: 'none' }}
            // value={mediaFile}
            ref={fileInputRef}
            onChange={handleChangeImage}
          />
          {/* <div
            className="cursor-pointer"
            onClick={() => fileInputRef.current.click()}
          >
            Upload from files
          </div> */}
          {customImage && (statusExistsDone('done') || (images[0]?.url && statusExistsRemove('removed'))) ?
            "" :
            <>
              {productID  ?
                <Dropdown
                  menu={{
                    items,
                  }}
                  placement="top"
                  arrow
                >
                  <Button icon={<UploadOutlined />}  >Click to Upload</Button>

                </Dropdown>
                :
                <Button icon={<UploadOutlined />} onClick={() => fileInputRef.current.click()} >Click to Upload</Button>
              }
            </>
          }
          {/* {productID ?
            <Dropdown
              menu={{
                items,
              }}
              placement="top"
              arrow
            >
              <Button icon={<UploadOutlined />}  >Click to Upload</Button>

            </Dropdown>
            :
            <Button icon={<UploadOutlined />} onClick={() => fileInputRef.current.click()} >Click to Upload</Button>
          } */}


          {/* <button
            onClick={editingIndex !== null ? saveEditedLink : addSocialMediaLink}
            className={`ml-4 px-4 py-2 bg-${
              editingIndex !== null ? "yellow" : "blue"
            }-500 text-white rounded`}
          >
            {editingIndex !== null ? "Save" : "Add Link"}
          </button> */}
        </div>

        {/* Display added social media links */}
        <ul>
          {images?.map((link, index) => (
            <>
              {link?.status === 'done' || (link?.url && link?.status !== 'removed') ?
                <MediaLink
                  key={index}
                  index={index}
                  name={link.name}
                  type={link.type}
                  mediaFile={link.media}
                  details={link}
                  moveLink={moveSocialMediaLink}
                  removeLink={removeSocialMediaLink}
                  editLink={editSocialMediaLink}
                  productID={productID}
                />
                : ''
              }
            </>
          ))}
        </ul>
        <Modal
          open={dialogOpen}
          onCancel={handleCancelUploadModal}
          closable={false}
          maskClosable={false}
          destroyOnClose={false}
          footer={false}
        >
          <div className="bg-white ">
            <h1 className="mb-4 font-semibold text-base capitalize">
              Crop Image
            </h1>

            <Cropper
              style={{ height: 400, width: "100%" }}
              src={pending[0]}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
            />
          </div>
          <div className="flex justify-end mt-4">
            <div className="flex gap-2">
              <Button onClick={handleCancelUploadModal}>Cancel</Button>
              <Button
                // loading={loader}
                onClick={onWithoutCrop}
                type="none"
                className="bg-gray-500 hover:bg-gray-400 hover:text-white text-white px-4 py-1 rounded"
              >
                Without Crop & Save
              </Button>
              <Button
                loading={loader}
                onClick={onComplete}
                type="none"
                className="bg-blue-500 hover:bg-blue-400 hover:text-white text-white px-4 py-2 rounded"
              >
                Crop & Save
              </Button>
            </div>
          </div>
        </Modal>

        <Modal open={isModelOpen} onCancel={handleCancelModal} maskClosable={false} destroyOnClose={false} footer={false} >
          <h1>select your Image</h1>
          <div className="h-[400px] overflow-auto scrollbar-hide" >
            <ProductImagePreview uploadPage={true} onDataFromChild={handleDataFromChild} productID={productID} />
          </div>
        </Modal>

        <Modal open={embedIsModelOpen} onCancel={handleEmbedCancelModal} maskClosable={false} destroyOnClose={false} footer={false} >
          <h1>Give the Embed link for iframe</h1>
          <div className="h-[300px] overflow-auto scrollbar-hide" >

            <EmbedFiles onDataFromChild={handleEmbedDataFromChild} />
          </div>
        </Modal>
        {contextHolder}
      </div>
    </DndProvider>
  );
};

export default ImageVideoUploader;
