import { Button, Col, Collapse, Divider, Form, Input, Row, Select, Space, Tooltip, Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CloseOutlined, MinusCircleOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { createNewProduct, updateProduct } from '../../../api/AdminRequest';
import { useLocation, useNavigate } from 'react-router-dom';
// import ImgCrop from "antd-img-crop";
// import HandleImageUpload from '../../helper/ImageCompress';
import ImageVideoUploader from './ImageVideoUploader';
import Variant from './Variant';
import { FaEdit } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';

const { Title } = Typography;
const { Panel } = Collapse;
const { TextArea } = Input;
// let index = 0;

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    // wrapperCol: {
    //   xs: {
    //     span: 24,
    //   },
    //   sm: {
    //     span: 16,
    //   },
    // },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

function ProductModifer({ edit }) {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { state } = useLocation();
    // console.log(state, '************************');

    // const imageFile = [
    //     {
    //         uid: "-1",
    //         name: "image.png",
    //         status: state?.imageUrl ? "done" : "",
    //         url: state?.imageUrl,
    //     },
    // ]

    const defaultItems = [
        {
            value: 'BusniessQR',
            label: 'Business QR',
        },
        {
            value: 'reviewQR',
            label: 'Review QR',
        },
        {
            value: 'digitalInterface',
            label: 'Digital Interface',
        },
        {
            value: 'tableTalker',
            label: 'Table Talker',
        }
    ]
    const defaultMaterialItems = [
        {
            value: 'PVC',
            label: 'PVC',
        },
        {
            value: 'Metal',
            label: 'Metal',
        },
        {
            value: 'Wooden',
            label: 'Wooden',
        },
        {
            value: 'Luxury',
            label: 'Luxury (Gold)',
        }
    ]
    const [name, setName] = useState('');
    const [items, setItems] = useState(defaultItems);
    const [materialItems, setMaterialItems] = useState(defaultMaterialItems);
    const [error, setError] = useState({});
    // const [fileList, setFileList] = useState(state ? imageFile : [])
    const [productType, setproductType] = useState(state ? state?.productType : null);
    const [materialType, setMaterialType] = useState(state ? state?.materialType : null);
    const [open, setOpen] = useState(false);

    const [highlightImages, setHighlightImages] = useState(state ? state?.highlights?.medias : []);
    // console.log(highlightImages, "imagesss create product");

    const [variants, setVariants] = useState([])
    const [details, setDetails] = useState(state ? state : '')
    const [variantEditIndex, setVariantEditIndex] = useState()
    const [productOffers, setProductOffers] = useState(state ? state?.productOffers?.offers : [""])

    const [loader, setLoader] = useState(false)
    const inputRef = useRef(null);



    useEffect(() => {
        if (state) {

            setHighlightImages(state ? state?.highlights?.medias : []);
            setProductOffers(state ? state?.productOffers?.offers : [""])
        }
    }, []);

    // console.log(highlightImages,'highlightImages');
    // console.log(productOffers,'productOffers');

    const onFinish = async (values) => {
        // console.log(values,'----------');
        values.productType = productType
        values.materialType = materialType
        values.variants = variants
        values.highlights.medias = highlightImages
        values.productOffers.offers = productOffers

        // console.log(values, 'valuesssss');
        let id = state?._id

        const errors = validateForm(values);
        setError(errors)
        if (Object.keys(errors).length === 0) {

            try {
                setLoader(true)
                let datas = new FormData();

                // for (const keys in formValues) {
                //     datas.append(`${keys}`, `${formValues[keys]}`);
                // }

                // console.log(variants?.images, ' variants?.imagess');

                datas.append('details', JSON.stringify(values))
                if (!edit) {
                    variants.forEach((obj, variantIndex) => (
                        obj?.images?.forEach((item, index) => {
                            // If originFileObj exists and it's not empty
                            if (item?.originFileObj && item?.originFileObj?.type && item?.status === 'done') {
                                // console.log(item.originFileObj, 'item.originFileObj');
                                datas.append(`variantImages${variantIndex + 1}`, item.originFileObj);
                            }
                        })
                    ))

                    variants.forEach((obj, variantIndex) => {
                        if (obj?.frontImage) {
                            if (obj?.frontImage[0]?.originFileObj && obj?.frontImage[0]?.originFileObj?.type && obj?.frontImage[0]?.status === 'done') {
                                // console.log(item.originFileObj, 'item.originFileObj');
                                datas.append(`frontsideImage${variantIndex + 1}`, obj?.frontImage[0].originFileObj);
                            }
                        }
                        if (obj?.backImage) {
                            if (obj?.backImage[0]?.originFileObj && obj?.backImage[0]?.originFileObj?.type && obj?.backImage[0]?.status === 'done') {
                                // console.log(item.originFileObj, 'item.originFileObj');
                                datas.append(`backsideImage${variantIndex + 1}`, obj?.backImage[0].originFileObj);
                            }
                        }
                    })
                }
                // {
                //     edit ? ''
                //         :
                //         variants.forEach((obj, variantIndex) => (
                //             obj?.images?.forEach((item, index) => {
                //                 // If originFileObj exists and it's not empty
                //                 if (item?.originFileObj && item?.originFileObj?.type && item?.status === 'done') {
                //                     // console.log(item.originFileObj, 'item.originFileObj');
                //                     datas.append(`variantImages${variantIndex + 1}`, item.originFileObj);
                //                 }
                //             })
                //         ))
                // }

                let highlightImagesArray = []
                highlightImages.forEach((item, index) => {
                    // removing thd removed images 
                    if (item?.status !== 'removed') {
                        highlightImagesArray.push(item)
                    }
                });
                highlightImagesArray.forEach((item, index) => {
                    // If originFileObj exists and it's not empty
                    if (item?.originFileObj && item?.originFileObj?.type && item?.status === 'done') {
                        // console.log(item.originFileObj, 'item.originFileObj');
                        datas.append(`highlightImages`, item.originFileObj, `highlightImages-${index}`);
                    }
                });



                const { data } = edit ? await updateProduct(id, datas) : await createNewProduct(datas)
                // console.log(data);
                if (data.success) {
                    toast.success('New Product Created', {
                        position: "top-right",
                        autoClose: 3000,
                        theme: "dark",
                    });
                    setLoader(false)
                } else if (data.update) {
                    toast.success('Updated Successfully', {
                        position: "top-right",
                        autoClose: 3000,
                        theme: "dark",
                    });
                    setLoader(false)

                }
                setTimeout(() => {
                    navigate('/view-products')
                }, 3000)
            } catch (error) {
                console.log(error);
                setLoader(false)
                toast(error.message)
            }
        } else {
            const firstValue = Object.values(errors)[0];
            // console.log(firstValue, '23456hggfgfg');
            toast.error(`Please fill required fields, ${firstValue}`, {
                autoClose: 3000,
                theme: "dark",
            });
        }


    }

    const validateForm = (data) => {
        const error = {};

        if (!data.productType) {
            error.productType = "Product Type required";
        }
        if (!data.materialType) {
            error.materialType = "Material Type required";
        }


        return error;
    };

    const handleAddVariant = () => {
        setOpen(true)
    }



    /* --------------------------- product ,materails --------------------------- */
    const handleChangeProductType = (value) => {
        // console.log(`selected ${value}`);

        setproductType(value)
    };
    const handleChangeMaterialType = (value) => {

        setMaterialType(value)
    };

    /* ---------------------------- select add, remove, view --------------------------- */
    const onNameChange = (event) => {
        setName(event.target.value);
    };

    const addItem = (e, data, setData) => {
        e.preventDefault();
        // Validation: Check if the item already exists or if the name is empty
        if (!name || data.some(item => item?.value === name)) {
            // Handle validation error (you can show a message, etc.)

            return;
        }
        setData([...data, { label: name, value: name }]);
        setName('');
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };

    const removeItem = (removedItem, data, setData) => {
        // Filter out the removed item
        const updatedItems = data.filter((item) => item.value !== removedItem.value);
        setData(updatedItems);
    };

    const getOptionLabel = (selectedItem, defaultData, data, setData) => {
        if (defaultData.some((item) => item.value === selectedItem.value)) {
            // For default items, exclude the delete button
            return <span>{selectedItem.label}</span>;
        }

        // For dynamically added items, include the delete button
        return (
            <div className='flex justify-between items-center'>
                <p>{selectedItem.label}</p>

                <CloseOutlined
                    onClick={() => removeItem(selectedItem, data, setData)}
                    style={{ cursor: 'pointer', marginLeft: '8px', color: 'rgba(0, 0, 0, 0.25)' }}
                />
            </div>
        );
    };

    const handleDataFromChild = (data) => {
        // Do something with the data in the parent component
        // console.log('Data received from child:', data);
        // console.log('base64Img received from child:', base64Img);
        console.log(data, 'dataadadadad');


        setVariants(prevValues => {
            let updatedValues = [...prevValues];

            console.log(updatedValues, '777777');
            console.log(variantEditIndex, '777777');

            // Reset certain values if materialcolor is being changed
            if (variantEditIndex) {

                updatedValues[variantEditIndex - 1] = data;
                setVariantEditIndex(null)

            } else {

                updatedValues = [...updatedValues, data]
            }



            return updatedValues;
        })



        // setVariants([...variants, data]);

        setOpen(false)
        // dispatch(reuxFunction(imgSelected));
        // dispatch(addImages(imgSelected, identifier));
        // reuxFunction(imgSelected)
    };

    // console.log(variants, "varaianatssssss");

    // console.log(stocks, 'ooooo');

    const handleEditVariant = (data, index) => {
        setOpen(true)
        setVariantEditIndex(index + 1)
        setDetails(data)
    }
    const removeVariant = (i) => {
        let newFormValues = [...variants];
        newFormValues.splice(i, 1);
        setVariants(newFormValues);
    }

    const handleChangeOffers = async (index, value) => {
        console.log(index, 'index');
        console.log(value, 'index');
        setProductOffers(prevValues => {
            let updatedValues = [...prevValues];

            // console.log(updatedValues, '777777');
            updatedValues[index] = value;

            return updatedValues;
        })
    };

    const addOffersFields = () => {
        setProductOffers(prevValues => {
            const updatedValues = [...prevValues];
            updatedValues.push("");
            return updatedValues;
        });
    };

    const removeOffersFields = (index) => {
        setProductOffers(prevValues => {
            const updatedValues = [...prevValues];
            updatedValues.splice(index, 1);
            return updatedValues;
        });
    };



    return (
        <div>
            <Row gutter={16}>
                <Col span={12}>
                    <Title level={2}>{edit ? 'Edit Product' : 'Create Product'}</Title>
                </Col>
            </Row>

            <div className='flex min-h-screen justify-center mt-14'>

                {/* <div> */}
                {/* <h1 className='text-center  text-xl font-bold'>Create Admin</h1> */}

                <Form
                    {...formItemLayout}
                    form={form}
                    name="register"
                    onFinish={onFinish}
                    initialValues={{
                        productName: state ? state?.productName : '',
                        subtittle: state ? state?.subtittle : '',
                        defaultVariant: state ? state?.defaultVariant : '',
                        description: state ? state?.description : '',
                        vendor: state ? state?.vendor : '',
                        productOffers: {
                            tittle: state?.productOffers?.tittle
                        },
                        highlights: {
                            tittle: state?.highlights?.tittle
                        }
                        // 'productOffers.tittle': state ? state?.productOffers?.tittle : '',
                        // 'highlights.tittle': state ? state?.highlights?.tittle : '',
                    }}
                    style={{
                        // maxWidth: 600,
                        width: 600
                    }}
                    scrollToFirstError
                >
                    <Collapse defaultActiveKey={['1']} >

                        <Panel header="Basic Details" key="1"  >
                            <div className='grid grid-cols-2 gap-2 '>
                                {/*  ---------------------------------- productName ----------------------------------  */}
                                <div>
                                    <label htmlFor="" className='text-xl font-semibold'>Product Name </label>
                                    <Form.Item
                                        name="productName"
                                        // label="Name "
                                        className='mb-2'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your Product Name!',
                                                whitespace: true,
                                            },
                                        ]}
                                    >

                                        <Input placeholder='Product Name' className='py-2' />
                                    </Form.Item>
                                </div>
                                {/*  ---------------------------------- Subtittle ----------------------------------  */}
                                <div>
                                    <label htmlFor="" className='text-xl font-semibold'>Product Subtittle </label>
                                    <Form.Item
                                        name="subtittle"
                                        // label="Name "
                                        className='mb-2'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your Product Subtittle!',
                                                whitespace: true,
                                            },
                                        ]}
                                    >

                                        <Input placeholder='Product Subtittle' className='py-2' />
                                    </Form.Item>
                                </div>
                                {/*  ---------------------------------- Product Type ----------------------------------  */}
                                <div>
                                    <label htmlFor="" className='text-xl font-semibold'>Product Type </label>
                                    <Select
                                        style={{
                                            width: '100%',
                                        }}
                                        value={productType}
                                        size='large'
                                        placeholder="Select Product Type"
                                        onChange={handleChangeProductType}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                <Divider
                                                    style={{
                                                        margin: '8px 0',
                                                    }}
                                                />
                                                <Space
                                                    style={{
                                                        padding: '0 8px 4px',
                                                    }}
                                                >
                                                    <Input
                                                        placeholder="Please enter item"
                                                        ref={inputRef}
                                                        value={name}
                                                        onChange={onNameChange}
                                                        onKeyDown={(e) => e.stopPropagation()}
                                                    />
                                                    {(!name || items.some(item => item.value === name)) ?
                                                        <Button type="text" disabled icon={<PlusOutlined />} > Add item </Button>
                                                        :
                                                        <Button type="text" icon={<PlusOutlined />} onClick={(e) => addItem(e, items, setItems)}>
                                                            Add item
                                                        </Button>
                                                    }
                                                </Space>
                                            </>
                                        )}
                                        options={items.map((item) => ({
                                            label: getOptionLabel(item, defaultItems, items, setItems),
                                            value: item.value,
                                        }))}
                                    />
                                    <p className='text-red-500'>{error.materialType}</p>
                                </div>
                                {/*  ---------------------------------- Material Type ----------------------------------  */}
                                <div>
                                    <label htmlFor="" className='text-xl font-semibold'>Material Type </label>
                                    <Select
                                        style={{
                                            width: '100%',
                                        }}
                                        value={materialType}
                                        size='large'
                                        placeholder="Select Material Type"
                                        onChange={handleChangeMaterialType}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                <Divider
                                                    style={{
                                                        margin: '8px 0',
                                                    }}
                                                />
                                                <Space
                                                    style={{
                                                        padding: '0 8px 4px',
                                                    }}
                                                >
                                                    <Input
                                                        placeholder="Please enter item"
                                                        ref={inputRef}
                                                        value={name}
                                                        onChange={onNameChange}
                                                        onKeyDown={(e) => e.stopPropagation()}
                                                    />
                                                    {(!name || materialItems.some(item => item.value === name)) ?
                                                        <Button type="text" disabled icon={<PlusOutlined />} > Add item </Button>
                                                        :
                                                        <Button type="text" icon={<PlusOutlined />} onClick={(e) => addItem(e, materialItems, setMaterialItems)}>
                                                            Add item
                                                        </Button>
                                                    }
                                                </Space>
                                            </>
                                        )}
                                        options={materialItems.map((item) => ({
                                            label: getOptionLabel(item, defaultMaterialItems, materialItems, setMaterialItems),
                                            value: item.value,
                                        }))}
                                    />
                                    <p className='text-red-500'>{error.productType}</p>
                                </div>
                                {/*  ---------------------------------- Vendor ----------------------------------  */}
                                <div>
                                    <label htmlFor="" className='text-xl font-semibold'>Vendor </label>
                                    <Form.Item
                                        name="vendor"
                                        // label="Name "
                                        className='mb-2'

                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your Vendor Name!',
                                                whitespace: true,
                                            },
                                        ]}
                                    >

                                        <Input defaultValue={'ZEEQR'} placeholder='Vendor' className='py-2' />
                                    </Form.Item>
                                </div>
                                {/*  ---------------------------------- Default Variant ----------------------------------  */}
                                <div>
                                    <label htmlFor="" className='text-xl font-semibold'>Default Variant </label>
                                    <Form.Item
                                        name="defaultVariant"
                                        // label="Name "
                                        className='mb-2'

                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your Default Variant!',
                                                whitespace: true,
                                            },
                                        ]}
                                    >

                                        <Input placeholder='Default Variant' className='py-2' />
                                    </Form.Item>
                                </div>

                            </div>
                            {/*  ---------------------------------- Product Description ----------------------------------  */}
                            <div>
                                <label htmlFor="" className='text-xl font-semibold'>Product Description </label>
                                <Form.Item
                                    name="description"
                                    // label="Name "
                                    className='mb-2'

                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Product Description!',
                                            whitespace: true,
                                        },
                                    ]}
                                >

                                    <TextArea rows={4} placeholder='Product Description' className='py-2' />
                                </Form.Item>
                            </div>
                        </Panel>

                    </Collapse>
                    {edit ? ''
                        :
                        <div className='mt-2'>
                            <Collapse defaultActiveKey={['0']} >

                                <Panel header="Product Variants" key="1"  >
                                    <Button onClick={handleAddVariant} disabled={variants.length >= 5 ? true : false} >
                                        Add Variant
                                    </Button>
                                    <Variant open={open} setOpen={setOpen} details={details} onDataFromChild={handleDataFromChild} />
                                    <div>
                                        {variants?.map((obj, index) => (
                                            <div className='flex gap-2 justify-between bg-gray-300 rounded-lg p-2 mt-2'>
                                                <div className='flex gap-2'>
                                                    <h1>{index + 1} )</h1>
                                                    <h1 className='font-bold'>{obj.name}</h1>
                                                </div>
                                                <div className='flex gap-2'>
                                                    <Tooltip title="edit"><FaEdit size={20} className='cursor-pointer' onClick={() => handleEditVariant(obj, index)} /></Tooltip>

                                                    <Tooltip title="delete"><MdDelete size={20} className='cursor-pointer' onClick={() => removeVariant(index)} /></Tooltip>

                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                </Panel>

                            </Collapse>

                        </div>
                    }

                    <div className='mt-2'>
                        <Collapse defaultActiveKey={edit ? ['1'] : ['0']} >

                            <Panel header="Product Offers" key="1"  >
                                <div>
                                    <label htmlFor="" className='text-xl font-semibold'>Tittle </label>
                                    <Form.Item
                                        name={['productOffers', 'tittle']}
                                        // label="Name "
                                        className='mb-2'
                                        // rules={[
                                        //     {
                                        //         required: true,
                                        //         message: 'Please input your  Offers Tittle!',
                                        //         whitespace: true,
                                        //     },
                                        // ]}
                                    >

                                        <Input placeholder=' Offers Tittle' className='py-2' />
                                    </Form.Item>
                                </div>
                                <div className='flex gap-2'>

                                    <label htmlFor="" className='text-xl font-semibold my-3' >Highlights</label>
                                    <div className='w-full'>

                                        {productOffers?.map((obj, index) => (
                                            <>
                                                <div className='flex w-full mt-2 justify-between items-center gap-3 mr-2'>

                                                    <div className='w-full'>
                                                        {/* <label htmlFor={`highlight-${index}-${highlightIndex}`} className='text-xl font-semibold'>highlight</label> */}

                                                        <Input className='py-2'
                                                            id={`offer-${index}`}
                                                            name={`offer-${index}`}
                                                            placeholder='offers'
                                                            value={obj} onChange={(e) => handleChangeOffers(index, e.target.value)} />
                                                    </div>

                                                    <div>
                                                        {index ? (
                                                            <button
                                                                type="button"

                                                                onClick={() => removeOffersFields(index)}
                                                            >
                                                                <Tooltip placement="top" title="Remove">
                                                                    <MinusCircleOutlined
                                                                        style={{ fontSize: "20px", color: "red" }}
                                                                    />
                                                                </Tooltip>
                                                            </button>
                                                        ) : (
                                                            <button
                                                                type="button"

                                                                onClick={() => addOffersFields()}
                                                            >
                                                                <Tooltip placement="top" title="Add More">
                                                                    <PlusCircleOutlined
                                                                        style={{ fontSize: "20px", color: "green" }}
                                                                    />
                                                                </Tooltip>
                                                            </button>
                                                        )}
                                                    </div>

                                                </div>


                                            </>
                                        ))}
                                    </div>
                                </div>
                            </Panel>

                        </Collapse>
                    </div>

                    <div className='mt-2'>
                        <Collapse defaultActiveKey={edit ? ['1'] : ['0']} >

                            <Panel header="Highlights" key="1"  >
                                <div>
                                    <label htmlFor="" className='text-xl font-semibold'>Tittle </label>
                                    <Form.Item
                                        name={['highlights', 'tittle']}
                                        // label="Name "
                                        className='mb-2'
                                        // rules={[
                                        //     {
                                        //         required: true,
                                        //         message: 'Please input your  Highlights Tittle!',
                                        //         whitespace: true,
                                        //     },
                                        // ]}
                                    >

                                        <Input placeholder=' Highlights Tittle' className='py-2' />
                                    </Form.Item>
                                </div>
                                <ImageVideoUploader images={highlightImages} setImages={setHighlightImages} productID={state?._id} />

                            </Panel>

                        </Collapse>
                    </div>


                    <Form.Item {...tailFormItemLayout} className='flex justify-center  mr-14'>
                        <Button loading={loader} className='bg-blue-800 w-32  my-5 ' type="primary" htmlType="submit">
                            {edit ? 'Save' : ' Register'}
                        </Button>
                    </Form.Item>
                </Form>
                {/* </div> */}
                <ToastContainer />
            </div>
        </div>
    )
}

export default ProductModifer