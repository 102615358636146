import { Button, Form, Input } from 'antd'
import React, { useState } from 'react'

function EmbedFiles({ onDataFromChild }) {

    const [form] = Form.useForm();
    const [loader, setLoader] = useState(false)

    const onFinish = async (values) => {

        console.log(values, 'final');

        // setLoader(true)
        onDataFromChild(values)

        // form.resetFields()
        // setSelectPriceValues(initialPrice)
        // setAttributeValues(initialAttribute)
        // setFeaturesValues(initialFeatures)
        // setImages([])
    }

    return (
        <div>
            <Form
                // {...formItemLayout}
                form={form}
                name="register"
                onFinish={onFinish}

                style={{
                    // maxWidth: 600,
                    width: "100%"
                }}
                scrollToFirstError
            >
                <div className="mt-5">
                    <label htmlFor="" className='text-xl font-semibold'>Linked from</label>
                    <Form.Item
                        name="from"
                        // label="Name "
                        className='mb-2'
                        rules={[
                            {
                                required: true,
                                message: 'Please input where it Linked from!',
                                whitespace: true,
                            },
                        ]}
                    >

                        <Input placeholder='from' className='py-2' />
                    </Form.Item>
                </div>
                <div className="mt-5">
                    <label htmlFor="" className='text-xl font-semibold'>Embed url</label>
                    <Form.Item
                        name="url"
                        // label="Name "
                        className='mb-2'
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Embed url!',
                                whitespace: true,
                            },
                        ]}
                    >

                        <Input placeholder='url' className='py-2' />
                    </Form.Item>
                </div>
                <div className="flex justify-center  items-center">

                    {/* <Button onClick={handleCancelUploadModal}>Cancel</Button> */}
                    <Form.Item
                        // {...tailFormItemLayout} 
                        className='pt-5' >
                        <Button className='bg-blue-800 w-20  ' loading={loader} type="primary" htmlType="submit">
                            Save
                        </Button>
                    </Form.Item>

                </div>

            </Form>
        </div>
    )
}

export default EmbedFiles